import React, { useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import '../../../../../assets/images/codestyle.css';
import ScrollToTop from '../../../../Shared/ScrollToTop';
import PosPrinter from '../../../../../assets/images/weerp/app-image/pos_printer.png'; 
import { ProjectTypeContext } from '../../../../../ContextHandle';
const Pos = ({ setActivePage, setDocumentation, setDefaultAppActiveMenu }) => {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(108);
        setDocumentation(2);
        document.title = "POS | " + projectName;
        setDefaultAppActiveMenu(6);
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);

    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className='right-content-box-width'>
                <div id="system-requirements pt-5" >
                    <h4 className="pt-5">POS</h4>
                    <h4 className="py-3">Pos Printer :</h4>
                    <p >Products can be sold through thermal printers using apps. Products can be filtered branch and category wise. </p>
                    <div>
                        <img src={PosPrinter} className="w-75 rounded" alt="Pos Printer" />
                    </div>
                    
                    <div className="d-flex justify-content-between border-top mt-5">
                        <div className="text-start mt-4">
                            <NavLink to={`/${projectType}/app/business/product`} className="text-decoration-none">
                                <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                                <h4 className='pagination-title'>Products</h4>
                            </NavLink>
                        </div>
                        <div className="text-end my-2">
                            <NavLink to={`/${projectType}/app/business/sales`} className="text-decoration-none">
                                <a href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                                <h4 className='pagination-title'>Sales</h4>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    );

};

export default Pos;
