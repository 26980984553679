import React, { useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import '../../../../assets/images/codestyle.css';
import ScrollToTop from '../../../Shared/ScrollToTop';
import Ddashboard from '../../../../assets/images/app-image/delivery/12.jpg';
import { ProjectTypeContext } from '../../../../ContextHandle';
const Dashboard = ({ setActivePage, setDocumentation, setDefaultAppActiveMenu }) => {
 /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;

    useEffect(() => {
        setActivePage(202);
        setDocumentation(2);
        document.title = 'Delivery boy Dashboard | ' + projectName;
        setDefaultAppActiveMenu(7);
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);


    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className='right-content-box-width'>
                <div id="system-requirements" >
                    <h4 className="pt-5">Delivery Boy Dashboard</h4>
                    <div className='pt-3'>
                        <img src={Ddashboard} className="w-75 rounded" alt="Delivery dashboard" />
                    </div>
                    <div className="d-flex justify-content-between border-top mt-5">
                        <div className="text-start mt-4">
                            <NavLink to={`/${projectType}/app/deliveryboy/login`} className="text-decoration-none">
                                <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                                <h4 className='pagination-title'>Login</h4>
                            </NavLink>
                        </div>
                        <div className="text-end my-2">
                            <NavLink to={`/${projectType}/app/deliveryboy/profile`} className="text-decoration-none">
                                <a href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                                <h4 className='pagination-title'>Delivery Boy Profile</h4>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );

};

export default Dashboard;
