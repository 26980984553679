import React, { useEffect, useContext ,useRef} from 'react';
import { NavLink } from 'react-router-dom';
import '../../../../../assets/images/codestyle.css';
import ScrollToTop from '../../../../Shared/ScrollToTop';
import Dashboard from '../../../../../assets/images/wemover/app-image/shipper/dashboard.png';
import Accounts from '../../../../../assets/images/wemover/app-image/shipper/accounts.png';
import Orders from '../../../../../assets/images/wemover/app-image/shipper/driver-accepted-order.png';
import Bookinghistory from '../../../../../assets/images/wemover/app-image/shipper/booking-history.png';
import Makeapayment from '../../../../../assets/images/wemover/app-image/shipper/make-a-payments.png';
import PaymentHistory from '../../../../../assets/images/wemover/app-image/shipper/payment-history.png';
import { ProjectTypeContext } from '../../../../../ContextHandle';
import useScrollSpy from 'react-use-scrollspy';
const ShipperApp = ({ setActivePage, setDocumentation, setDefaultAppActiveMenu }) => {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    const panelName = 'Shipper App';
    useEffect(() => {
        setActivePage(104);
        setDocumentation(2);
        document.title = "Shipper App | " + projectName;
        setDefaultAppActiveMenu(6);
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);


    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];
     
    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });

    return (
        <div className='d-flex justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className='right-content-box-width only-mobile-frame'>

                    <nav  >
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                            <li className="breadcrumb-item active" aria-current="page">How To Use</li>
                            <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                        </ol>
                    </nav>

                    <h4 className="font-weight-bold mt-5  ">Shipper App</h4>
                 
                 <div className='row border-top'>

                    <div className='col-md-6' id='dashboard' ref={pagesectionRefs[0]}>
                        <h5 className=" font-weight-bold mt-2 pt-3 text-primary"># Dashboard</h5>
                        <p  >Shipper can book order from their dashboard. just shipper need to fillup, pick location and drop location then need to select service and then select vehicle then place order.</p>
                        <div className='pt-3'>
                            <img src={Dashboard} className="w-75 rounded" alt="Merchant dashboard"  />
                        </div>
                    </div>  
                    <div className='col-md-6' id='accounts' ref={pagesectionRefs[1]}>
                        <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Accounts</h5>
                        <p  >Shipper can manage their multiple bank or cash account from their panel. </p>
                        <div className='pt-3'>
                            <img src={Accounts} className="w-75 rounded" alt="Merchant dashboard" />
                        </div>
                    </div>  
                    <div className='col-md-6' id='orders' ref={pagesectionRefs[2]}>
                        <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Orders</h5>
                        <p  >Easily shipper can manage order from their panel.</p>
                        <div className='pt-3'>
                            <img src={Orders} className="w-75 rounded" alt="Merchant dashboard" />
                        </div>
                    </div>  
                     
                    <div className='col-md-6' id='bookingHistory' ref={pagesectionRefs[4]}>
                        <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Booking history</h5>
                        <p  >Shipper can see their order history with status process.</p>
                        <div className='pt-3'>
                            <img src={Bookinghistory} className="w-75 rounded" alt="Merchant dashboard" />
                        </div>
                    </div>  

                    <div className='col-md-6' id='makeapayment' ref={pagesectionRefs[5]}>
                        <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Make a payment</h5>
                        <p  >After complete order then shipper will payment to driver.</p>
                        <div className='pt-3'>
                            <img src={Makeapayment} className="w-75 rounded" alt="Merchant dashboard" />
                        </div>
                    </div>  
                    <div className='col-md-6' id='paymentHistory' ref={pagesectionRefs[6]}>
                        <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Payment history</h5>
                        <p  >Shipper can see their all payment history.</p>
                        <div className='pt-3'>
                            <img src={PaymentHistory} className="w-75 rounded" alt="Merchant dashboard" />
                        </div>
                    </div>  
                  

                 </div>
                  
                    
                
                    <div className="d-flex justify-content-between border-top mt-5">
                        <div className="text-start mt-4">
                            <NavLink to={`/${projectType}/app/admin-panel-installation-process`} className="text-decoration-none">
                                <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                                <h4 className='pagination-title'>Installation Process</h4>
                            </NavLink>
                        </div>
                        <div className="text-end my-2">
                            <NavLink to={`/${projectType}/app/driver-app`} className="text-decoration-none">
                                <a href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                                <h4 className='pagination-title'>Driver Panel</h4>
                            </NavLink>
                        </div>
                    </div>
            </div>

            <div className='right-menu'>
                <ul >
                    <li><a href='#dashboard' className={activeSection === 0 || activeSection === 1 ? 'active-has' : ""}>Dashboard</a></li>
                    <li><a href='#accounts' className={activeSection === 1 ? 'active-has' : ""}>Accounts</a></li>
                    <li><a href='#orders' className={activeSection === 2 || activeSection === 4? 'active-has' : ""}>Orders</a></li> 
                    <li><a href='#bookingHistory' className={activeSection === 4 ? 'active-has' : ""}>Booking history</a></li>
                    <li><a href='#makeapayment' className={activeSection === 5 || activeSection === 6 ? 'active-has' : ""}>Make a payment  </a></li>
                    <li><a href='#paymentHistory' className={activeSection === 6 ? 'active-has' : ""}>Payment history  </a></li>
                 </ul>
            </div>



        </div>

    );

};

export default ShipperApp;
