import React, { useEffect, useContext } from 'react'
import ScrollToTop from '../../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import BusinessImg from './../../../../assets/images/weerp/web-image/super-admin/business.png';
import { ProjectTypeContext } from '../../../../ContextHandle';
export default function Business({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
 /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const panelName   = projectType.panelName;
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(2003);
        document.title = 'Business | ' + projectName + ' web';
        setDefaultWebActiveMenu(4);
    }, [projectName]);

    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                        <li className="breadcrumb-item active" aria-current="page">Business</li>
                    </ol>
                </nav>
                <div id="dashboards" ref={sectionRefs['4']}>
                    <h4 className="font-weight-bold mt-5  ">Business</h4>
                    <p className="pt-3"> Admin can manage business account from his panel. And can manage the Business wise branch. </p>
                    <div className='pt-3'>
                        <img src={BusinessImg} className="w-100 rounded" alt="Dashboard"></img>
                    </div>
 
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/admin/role-employee`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Role & Employees</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/admin/plan-subscription`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Plans & Subscription</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
