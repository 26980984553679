
import WecourierLogo from '../assets/item-logo/we-courier.png'
import WecourierSaasLogo from '../assets/item-logo/we-courier-saas.png'
import WeErpLogo from '../assets/item-logo/weerp.png'
import HomeIcon from '../assets/item-logo/house-solid.png'
import WemoverLogo from '../assets/item-logo/wemover.png'
 
function DocumentationListModal() {
  return (
    <>
      <div className="modal fade" id="documentation-list" data-bs-backdrop="static" data-bs-keyboard="false"   aria-labelledby="documentation-listLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content" style={{ borderRadius:'20px' }}>
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="documentation-listLabel">Documentation</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className='row'>


                <div className="col-4">
                  <a href='/'  className="card-link"  >
                    <div className="card p-0">
                      <div className="card-body p-2">
                        <div className="text-center">  
                            <img src={HomeIcon} alt='Wecourier-logo.png' width={'100%'} style={{ borderRadius: '10px' }}></img> 
                          <div> 
                              <small className='mt-3 text-dark text-center font-weight-bold' style={{ fontSize:"12px" }} >Home</small> 
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
 
                <div className="col-4">
                  <a href='/wecourier'  className="card-link"  >
                    <div className="card p-0">
                      <div className="card-body p-2">
                        <div className="text-center"> 
                            <img src={WecourierLogo} alt='Wecourier-logo.png' width={'100%'} style={{ borderRadius: '10px' }}></img> 
                          <div> 
                              <small className='mt-3 text-dark text-center font-weight-bold' style={{ fontSize:"12px" }} >We Courier</small> 
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="col-4">
                  <a href='/wecourier-saas'  className="card-link"  >
                    <div className="card p-0">
                      <div className="card-body p-2">
                        <div className="text-center"> 
                            <img src={WecourierSaasLogo} alt='Wecourier-saas-logo.png' width={'100%'} style={{ borderRadius: '10px' }}></img> 
                          <div> 
                              <small className='mt-3 text-dark text-center font-weight-bold'  style={{ fontSize:"12px" }}>We Courier SAAS</small> 
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div> 


                <div className="col-4 mt-3">
                  <a href='/weerp-normal'  className="card-link"  >
                    <div className="card p-0">
                      <div className="card-body p-2">
                        <div className="text-center"> 
                            <img src={WeErpLogo} alt='Wecourier-saas-logo.png' width={'100%'} style={{ borderRadius: '10px' }}></img> 
                          <div> 
                              <small className='mt-3 text-dark text-center font-weight-bold'  style={{ fontSize:"12px" }}>We ERP</small> 
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div> 

                <div className="col-4 mt-3">
                  <a href='/weerp'  className="card-link"  >
                    <div className="card p-0">
                      <div className="card-body p-2">
                        <div className="text-center"> 
                            <img src={WeErpLogo} alt='Wecourier-saas-logo.png' width={'100%'} style={{ borderRadius: '10px' }}></img> 
                          <div> 
                              <small className='mt-3 text-dark text-center font-weight-bold'  style={{ fontSize:"12px" }}>We ERP (SAAS)</small> 
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div> 

                <div className="col-4 mt-3">
                  <a href='/wemover'  className="card-link"  >
                    <div className="card p-0">
                      <div className="card-body p-2">
                        <div className="text-center"> 
                            <img src={WemoverLogo} alt='Wecourier-saas-logo.png' width={'100%'} style={{ borderRadius: '10px' }}></img> 
                          <div> 
                              <small className='mt-3 text-dark text-center font-weight-bold'  style={{ fontSize:"12px" }}>We Mover</small> 
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div> 
 
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DocumentationListModal;