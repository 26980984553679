import React, { useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import '../../../../../assets/images/codestyle.css';
import ScrollToTop from '../../../../Shared/ScrollToTop';
import SalesImg from '../../../../../assets/images/weerp/app-image/sales.png';
import { ProjectTypeContext } from '../../../../../ContextHandle';
const Sales = ({ setActivePage, setDocumentation, setDefaultAppActiveMenu }) => {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(109);
        setDocumentation(2);
        document.title = "Sales | " + projectName;
        setDefaultAppActiveMenu(6);
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);

    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className='right-content-box-width'>
                <div id="system-requirements" >
                    <h4 className="pt-5">Sales</h4>
                    <p>The sales list of all branches can be seen here And sales details can be seen.</p>
                    <div className='pt-3'>
                        <img src={SalesImg} className="w-75 rounded" alt="Sales"></img>
                    </div>

                    <div className="d-flex justify-content-between border-top mt-5">
                        <div className="text-start mt-4">
                            <NavLink to={`/${projectType}/app/business/pos`} className="text-decoration-none">
                                <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                                <h4 className='pagination-title'>POS</h4>
                            </NavLink>
                        </div>
                        <div className="text-end my-2">
                            <NavLink to={`/${projectType}/app/business/accounts`} className="text-decoration-none">
                                <a href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                                <h4 className='pagination-title'>Accounts</h4>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div> 
        </div> 
    ); 
};

export default Sales;
