import React, { useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import '../../../assets/images/codestyle.css';
import ScrollToTop from '../../Shared/ScrollToTop';
import { ProjectTypeContext } from '../../../ContextHandle'
const Recommendation = ({ setActivePage, sectionRefs, setDocumentation, setDefaultAppActiveMenu }) => {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(501);
        setDocumentation(2);
        document.title = 'Recommendation | ' + projectName;
        setDefaultAppActiveMenu(1);
    }, [setActivePage, setDocumentation, projectName]);


    return (
        <div className='d-flex   justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div id="system-requirements  " className='pt-5 right-content-box-width' >
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">App Basics</li>
                        <li className="breadcrumb-item active" aria-current="page">Recommendation</li>
                    </ol>
                </nav>

                <h3 className="pt-5 font-weight-bold">Recommendation</h3>
                <p className='pt-3'>We recommend using the latest  Android Studio And  Flutter SDK , which can be downloaded from here. </p>
                <p><a href='https://developer.android.com/studio?hl=en' target="_blank" className='text-primary font-weight-bold'>Android Studio</a>,<a href='https://developer.android.com/studio?hl=en' target="_blank" className='text-primary font-weight-bold'> Flutter SDK</a>, <a href='https://developer.android.com/studio?hl=en' target="_blank" className='text-primary font-weight-bold'>Cocoapods</a></p>
                <h4 className="pt-3">Recommended Version</h4>
                <ul>
                    <li>Android Studio 2021.2.1 or later</li>
                    <li>Flutter 3.3.10 or later {`(`}Expect beta and alpha version{`)`}.</li>
                    <li>Dart SDK 2.18.6 or later.</li>
                    <li>Latest Version (for iOS only).</li>
                </ul>
                <div className='pt-3'>

                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Getting Started</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/app/installation`} className="text-decoration-none">
                            <a href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Installation</h4>
                        </NavLink>
                    </div>
                </div>
            </div>



        </div>

    );

};

export default Recommendation;
