import React, { useEffect, useRef, useContext } from 'react'
import ScrollToTop from '../../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import SaleReports from '../../../../assets/images/weerp/web-image/business/reports/sale-reports.png';
import ProfitLossProfit from '../../../../assets/images/weerp/web-image/business/reports/report-profit-loss.png';
import PurchaseReports from '../../../../assets/images/weerp/web-image/business/reports/purchase-reports.png'; 

import useScrollSpy from 'react-use-scrollspy';
import { ProjectTypeContext } from '../../../../ContextHandle';
export default function Reports({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {

    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });

    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    const panelName   = 'Business panel';
    projectType = projectType.projectType;
   /* eslint-disable */
    useEffect(() => {
        setActivePage(1022);
        document.title = 'Reports | ' + projectName + ' web';
        setDefaultWebActiveMenu(2);
    }, [projectName]);
    /* eslint-enable */

  
    return (
        <div className='d-flex justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                        <li className="breadcrumb-item active" aria-current="page">Reports</li>
                    </ol>
                </nav>
                <div id="reports" >
                    <h4 className=" mt-5 font-weight-bold">Reports</h4>
                    <p>All types of reports can be viewed from the report module. Reports that can be viewed - 
                    Attendance, Profit loss, Product sale profit, Product pos profit, Expense report, Stock report, Customer sale report, Customer pos report, Purchase report, Purchase Return, Sale report, Pos report, Service sale report and Supplier report  can be viewed.</p>
                    <p><strong>Attendance reports: </strong> How many days were present and how many days were absent and how many days were holidays can be seen in the report.</p>
                    <p><strong>Customer Sales Report: </strong> The report will show how much the customer is buying the product and how much they are paying and how much is due.</p>
                  
                    <div id='parcel-status-report' ref={pagesectionRefs[0]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Sales reports</h5>
                        <p>Reports can be found on sales by filtering by date. How much money has been paid and how much money is due can also be seen.</p>
                        <div className='pt-3'>
                            <img src={SaleReports} className="w-100 rounded" alt="SalesReports.png"></img>
                        </div>
                     
                    </div>

                    <div id='parcel-wise-profit' ref={pagesectionRefs[1]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Profit Loss reports</h5>
                        <p className="pt-3">Total sales, Total POS sales, Total purchase, total purchase return can be seen also payments.  Branch wise Total Gross Profit, Net Profit can be seen </p>
                        <div className='pt-3'>
                            <img src={ProfitLossProfit} className="w-100 rounded" alt="parcelWiseProfit.png"></img>
                        </div>
                    </div>
                    <div id='salary-reports' ref={pagesectionRefs[2]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Purchase Reports</h5>
                        <p className="pt-3"> Reports can be found on purchase by filtering by date. How much money has been paid and how much money is due can also be seen.</p>
                        <div className='pt-3'>
                            <img src={PurchaseReports} className="w-100 rounded" alt="SalaryReports.png"></img>
                        </div>
                    </div>  
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/business/todo`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Todo Manage</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/business/settings`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Settings</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className='right-menu'>
                <ul>
                    <li><a href='#parcel-status-report' className={activeSection == 0 ? 'active-has' : ""}> Sales reports </a></li>
                    <li><a href='#parcel-wise-profit' className={activeSection == 1 ? 'active-has' : ""}> Profit Loss reports</a></li>
                    <li><a href='#salary-reports' className={activeSection == 2 ? 'active-has' : ""}> Purchase reports</a></li>
               </ul>
            </div>

        </div>
    )
}
