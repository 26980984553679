import React, { useEffect, useContext } from 'react'
import ScrollToTop from '../../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import SettingsImg from './../../../../assets/images/weerp/web-image/super-admin/general-settings.png';
import CurrenciesImg from './../../../../assets/images/weerp/web-image/super-admin/currencies.png';
import { ProjectTypeContext } from '../../../../ContextHandle';
export default function Settings({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
 /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const panelName   = projectType.panelName;
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(2005);
        document.title = 'Settings | ' + projectName + ' web';
        setDefaultWebActiveMenu(4);
    }, [projectName]);

    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                        <li className="breadcrumb-item active" aria-current="page">Settings</li>
                    </ol>
                </nav>
                <div id="dashboards" ref={sectionRefs['4']}>
                    <h4 className="font-weight-bold mt-5  ">Settings</h4>
                    <h5 className="border-top font-weight-bold mt-5 pt-2 text-primary"># General Settings</h5>
                    <p className="pt-3">Admin can manage his data from general settings. And can change the mail information. Can change payment gateways credentials. Three types of payment gateways include Stripe, PayPal, Skill.</p>
                    
                    <div className='pt-3'>
                        <img src={SettingsImg} className="w-100 rounded" alt="Settings"></img>
                    </div>
                    <h5 className="border-top font-weight-bold mt-5 pt-2 text-primary"># Currencies</h5>
                    <p className="pt-3">Admin can manage multiple currencies from his panel.</p>
                    <div className='pt-3'>
                        <img src={CurrenciesImg} className="w-100 rounded" alt="Currencies"></img>
                    </div>
 
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/admin/plan-subscription`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Plan & Subscriptions</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/business/dashboard`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Business Dashboard</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
