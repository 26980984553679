import React, { useEffect, useContext } from 'react'
import ScrollToTop from './../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import pickuprequestImg from './../../../assets/images/module/merchant-panel/pickup-request/pickup-request.png';
import regularImg from './../../../assets/images/module/merchant-panel/pickup-request/regular.png';
import expressImg from './../../../assets/images/module/merchant-panel/pickup-request/express.png';
import { ProjectTypeContext } from '../../../ContextHandle';
export default function PickupRequest({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1025);
        document.title = 'Pickup Request | ' + projectName + ' web';
        setDefaultWebActiveMenu(3);
    }, [projectName]);
    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">Merchant Panel</li>
                        <li className="breadcrumb-item active" aria-current="page">Pickup Request</li>
                    </ol>
                </nav>
                <div id="accounts" ref={sectionRefs['26']}>
                    <h4 className="font-weight-bold mt-5  ">Pickup Request</h4>
                    <p  > Merchant can request two types of parcel pickup. One is regular and express. For regular requests. Clicking on Pickup Request from the navbar <b>( + )</b> icon will display a modal. And there will be two buttons. Regular and Express. By clicking on regular, the following modal will be displayed with some information and click on submit button. Request for pickup will be done. And similarly for express request and pickup request a modal will be displayed. And clicking Express will display another modal. After entering all the information correctly and clicking on the submit button, the request will be submitted. </p>

                    <div className='pt-3'>
                        <img src={pickuprequestImg} className="w-100 rounded" alt="Pickuprequest"></img>
                    </div>
                    <h4 className='pt-3'>Regular Pickup request</h4>
                    <div className='pt-3'>
                        <img src={regularImg} className="w-100 rounded" alt="Regular.png"></img>
                    </div>
                    <h4 className='pt-3'>Express Pickup request</h4>
                    <div className='pt-3'>
                        <img src={expressImg} className="w-100 rounded" alt="Express.png"></img>
                    </div>
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/merchant/payment-information`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Payment Information</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/merchant/support`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Ticket</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
