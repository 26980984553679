import React, { useEffect, useRef, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import '../../../../assets/images/codestyle.css';
import useScrollSpy from 'react-use-scrollspy';
import ScrollToTop from '../../../Shared/ScrollToTop';
import { ProjectTypeContext } from '../../../../ContextHandle';
const TestFlight = ({ setActivePage, sectionRefs, setDocumentation, setDefaultAppActiveMenu }) => {
 /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;

    useEffect(() => {
        setActivePage(523);
        setDocumentation(2);
        setDefaultAppActiveMenu(4);
        document.title = 'Release your app on TestFlight | ' + projectName;
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);


    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });


    return (
        <div className='d-flex   justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div id="system-requirements  " className='pt-5 right-content-box-width' >
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">iOS Setup</li>
                        <li className="breadcrumb-item active" aria-current="page">Release your app on TestFlight</li>
                    </ol>
                </nav>

                <div >
                    <h3 className="pt-5 font-weight-bold" >Release your app on TestFlight</h3>
                    <p><a rel="noreferrer" href='https://developer.apple.com/testflight/' target="_blank">TestFlight</a> allows developers to push their apps to internal and external testers. This optional step covers releasing your build on TestFlight.</p>
                    <ol>
                        <li className='py-2'>Navigate to the TestFlight tab of your app's application details page on <a rel="noreferrer" target="_blank" href='https://appstoreconnect.apple.com/'> App Store Connect</a>.</li>
                        <li className='py-2'>Select <b>Internal Testing</b> in the sidebar.</li>
                        <li className='py-2'>Select the build to publish to testers, then click <b>Save</b>.</li>
                        <li className='py-2'>Add the email addresses of any internal testers. You can add additional internal <b>users in the Users</b> and Roles page of App Store Connect, available from the dropdown menu at the top of the page.</li>
                    </ol>
                </div>


                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/app/create-app-build`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Create an app bundle</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/app/release-your-app-store`} className="text-decoration-none">
                            <a href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Release your app to the App Store</h4>
                        </NavLink>
                    </div>
                </div>
            </div>


        </div>

    );

};

export default TestFlight;
