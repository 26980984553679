import React, { useEffect, useRef, useContext } from 'react'
import ScrollToTop from '../../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import EmployeeImg from '../../../../assets/images/weerp/web-image/business/employees.png';
import useScrollSpy from 'react-use-scrollspy';
import { ProjectTypeContext } from '../../../../ContextHandle';
export default function Employees({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {

    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });

    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    const panelName = 'Business panel';
    projectType = projectType.projectType;
    /* eslint-disable */
    useEffect(() => {
        setActivePage(1010);
        document.title = 'Employees | ' + projectName + ' web';
        setDefaultWebActiveMenu(2);
    }, [projectName]);
    /* eslint-enable */
    return (
        <div className='d-flex  justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                        <li className="breadcrumb-item active" aria-current="page">Employees</li>
                    </ol>
                </nav>
                <div id="usersroles" ref={sectionRefs['17']}>

                    <h4 className="font-weight-bold mt-5  ">Employees</h4>

                    <div id='roles' ref={pagesectionRefs[0]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Employees</h5>
                        <p className=" pt-3">Owner can manage branch wise emplyees. and can grant them module permissions.</p>
                        <h5 className="font-weight-bold mt-2"> Employees List</h5>
                        <div className='pt-3'>
                            <img src={EmployeeImg} className="w-100 rounded" alt="Employees.png"></img>
                        </div>

                    </div>

                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/business/profile`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Profile</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/business/services`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Services</h4>
                        </NavLink>
                    </div>
                </div>
            </div>

            <div className='right-menu'>
                <ul>
                    {/* <li><a href='#roles' className={activeSection == 0 ? 'active-has' : ""}>Roles </a></li>  */}
                </ul>
            </div>

        </div>
    )
}
