import React, { useEffect, useRef, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import '../../../../assets/images/codestyle.css';
import useScrollSpy from 'react-use-scrollspy';
import ScrollToTop from '../../../Shared/ScrollToTop';
import SignedImg from '../../../../assets/images/app-image/environment-setup/generate-signed-apk.png';
import { ProjectTypeContext } from '../../../../ContextHandle';
const Preliminaries = ({ setActivePage, sectionRefs, setDocumentation, setDefaultAppActiveMenu }) => {
 /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;

    useEffect(() => {
        setActivePage(513);
        setDocumentation(2);
        setDefaultAppActiveMenu(4);
        document.title = 'Preliminaries | ' + projectName;
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);


    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });

    return (
        <div className='d-flex   justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div id="system-requirements  " className='pt-5 right-content-box-width' >
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">iOS Setup</li>
                        <li className="breadcrumb-item active" aria-current="page">Preliminaries</li>
                    </ol>
                </nav>

                <div >
                    <h3 className="pt-5 font-weight-bold" >Preliminaries</h3>
                    <ul>
                        <li>Xcode is required to build and release your app. You must use a device running macOS to follow this guide.</li>
                        <li>Before beginning the process of releasing your app, ensure that it meets Apple's <a rel="noreferrer" href='https://developer.apple.com/app-store/review/' target="_blank">App Review Guidelines</a>.</li>
                        <li>In order to publish your app to the App Store, you must first enroll in the <a rel="noreferrer" href='https://developer.apple.com/programs/' target="_blank">Apple Developer Program</a>. You can read more about the various membership options in Apple’s <a rel="noreferrer" href='https://developer.apple.com/support/compare-memberships/' target="_blank">Choosing a Membership</a> guide.</li>
                    </ul>
                </div>


                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/app/generate-signed-apk`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Generate Signed Apk</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/app/register-your-app`} className="text-decoration-none">
                            <a href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Register your app on App Store Connect</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>

    );

};

export default Preliminaries;
