import React, { useEffect, useContext } from 'react'
import ScrollToTop from './../../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import ProfileImg from '../../../../assets/images/weerp/web-image/branch/profile.png';
import { ProjectTypeContext } from '../../../../ContextHandle';
export default function Profile({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    /* eslint-disable */
    useEffect(() => {
        setActivePage(1025);
        document.title = 'Profile | ' + projectName + ' web';
        setDefaultWebActiveMenu(3);
    }, [projectName]);
    /* eslint-enable */
    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">Branch Panel</li>
                        <li className="breadcrumb-item active" aria-current="page">Profile</li>
                    </ol>
                </nav>
                <div id="accounts" ref={sectionRefs['24']}>

                    <h4 className="font-weight-bold mt-5  ">Profile</h4>
                    <p>Profile information can be changed- name , account information, avater, password and etc.</p>
                    <div className='pt-3 mb-3'>
                        <img src={ProfileImg} className="w-100 rounded" alt="Profile.png"></img>
                    </div>

                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/branch/dashboard`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Dashboard</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/branch/sales`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Sales</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
