import React from 'react' 
import { Routes, Route } from "react-router-dom";
import Home from './../components/wecourier-saas/Home';
import GettingStarted from '../components/Pages/GettingStarted'; 
import DefaultPage from '../components/wecourier-saas/DefaultPage';
import Configuration from '../components/Pages/Configuration';
import UploadInstall from '../components/wecourier-saas/UploadInstall';

//authentication
import Login from '../components/wecourier-saas/Login';
import Merchantsignup from '../components/wecourier-saas/Merchantsignup';
import CompanySignup from '../components/wecourier-saas/CompanySignup';
import Forgotpassword from '../components/wecourier-saas/pages/Forgotpassword';

// admin panel
import SuperAdminDashboard from '../components/wecourier-saas/pages/adminpanel/Dashboard';
import Companies from '../components/wecourier-saas/pages/adminpanel/Companies';
import Plans from '../components/wecourier-saas/pages/adminpanel/Plans';
import Subscriptions from '../components/wecourier-saas/pages/adminpanel/Subscriptions';

//admin panel
//company panel all page
import Dashboard from '../components/wecourier-saas/pages/Dashboard';
import Profile from '../components/Pages/AdminPanel/Profile';
import Parcels from '../components/Pages/AdminPanel/Parcels';
import HubManage from '../components/Pages/AdminPanel/HubManage';
import TodoList from '../components/Pages/AdminPanel/TodoList';
import Support from '../components/Pages/AdminPanel/Support';
import Payout from '../components/Pages/AdminPanel/Payout';
import Accounts from '../components/Pages/AdminPanel/Accounts';
import UsersRoles from '../components/Pages/AdminPanel/UsersRoles';
import MerchantManage from '../components/Pages/AdminPanel/MerchantManage';
import Payroll from '../components/Pages/AdminPanel/Payroll';
import Reports from '../components/Pages/AdminPanel/Reports';
import DeliveryMan from '../components/Pages/AdminPanel/Deliveryman';
import PushNotification from '../components/Pages/AdminPanel/PushNotification';
import Settings from '../components/Pages/AdminPanel/Settings';
//end company panel all page

//merchant panel all pages
import MerchantDashboard from '../components/Pages/MerchantPanel/Dashboard';
import MerchantProfile from '../components/Pages/MerchantPanel/Profile';
import MerchantPickupRequest from '../components/Pages/MerchantPanel/PickupRequest';
import MerchantSupport from '../components/Pages/MerchantPanel/Support';
import MerchantAccounts from '../components/Pages/MerchantPanel/Accounts';
import MerchantShops from '../components/Pages/MerchantPanel/Shops';
import MerchantParcels from '../components/Pages/MerchantPanel/Parcels';
import MerchantReports from '../components/Pages/MerchantPanel/Reports';
import MerchantSettings from '../components/Pages/MerchantPanel/Settings';
import PaymentInformation from '../components/Pages/MerchantPanel/PaymentInformation';
//end merchant panel all pages


//app documentation 
import AppGettingStarted from '../components/wecourier-saas/AppDocs/GettingStarted';
//use merhcant app
import AppMerchantLogin from '../components/Pages/AppDocumentation/MerchantApp/LoginRegistration';
import AppMerchantDashboard from '../components/Pages/AppDocumentation/MerchantApp/Dashboard';
import AppMerchantProfile from '../components/Pages/AppDocumentation/MerchantApp/Profile';
import AppMerchantShops from '../components/Pages/AppDocumentation/MerchantApp/Shops';
import AppMerchantParcels from '../components/Pages/AppDocumentation/MerchantApp/Parcels';
import AppMerchantFraudCheck from '../components/Pages/AppDocumentation/MerchantApp/FraudCheck';
import AppMerchantAccounts from '../components/Pages/AppDocumentation/MerchantApp/Accounts';
import AppMerchantReports from '../components/Pages/AppDocumentation/MerchantApp/Reports';
import AppMerchantSettings from '../components/Pages/AppDocumentation/MerchantApp/Settings';
//end use merhcant app

// use merhcant  app
//end use delivery boy app
import AppDeliveryboyLogin from '../components/Pages/AppDocumentation/DeliveryBoyApp/LoginRegistration';
import AppDeliveryboyDashboard from '../components/Pages/AppDocumentation/DeliveryBoyApp/Dashboard';
import AppDeliveryboyProfile from '../components/Pages/AppDocumentation/DeliveryBoyApp/Profile';
import AppDeliveryboyPaymentLogs from '../components/Pages/AppDocumentation/DeliveryBoyApp/PaymentLogs';
import AppDeliveryboyParcelPaymentHistory from '../components/Pages/AppDocumentation/DeliveryBoyApp/ParcelPaymentHistory';
import FCMPushNotification from '../components/Pages/FCMPushNotification';
import Recommendation from '../components/Pages/AppDocumentation/Recommendation';
import Installation from '../components/Pages/AppDocumentation/Installation';
import PrepareFlutterEnvironment from '../components/Pages/AppDocumentation/PrepareFlutterEnvironment';
import GoogleFirebaseSetup from '../components/Pages/AppDocumentation/GoogleFirebaseSetup';
import PushNotificationSetup from '../components/Pages/AppDocumentation/PushNotificationSetup';
import ChangeApiServerDetails from '../components/Pages/AppDocumentation/ChangeApiServerDetails';
import ChangeAppLogo from '../components/Pages/AppDocumentation/ChangeAppLogo';
import ChangeAppName from '../components/Pages/AppDocumentation/AndroidSetup/ChangeAppName';
import ChangeAndroidLuncherIcon from '../components/Pages/AppDocumentation/AndroidSetup/ChangeAndroidLuncherIcon';
import ChangeAndroidPackageName from '../components/Pages/AppDocumentation/AndroidSetup/ChangeAndroidPackageName';
import GenerateDebugApk from '../components/Pages/AppDocumentation/AndroidSetup/GenerateDebugApk';
import GenerateSignedApk from '../components/Pages/AppDocumentation/AndroidSetup/GenerateSignedApk';
import Preliminaries from '../components/Pages/AppDocumentation/IOSsetup/Preliminaries';
import RegisterYourApp from '../components/Pages/AppDocumentation/IOSsetup/RegisterYourApp';
import RegisterBuildID from '../components/Pages/AppDocumentation/IOSsetup/RegisterBuildID';
import CreateApplicationRecord from '../components/Pages/AppDocumentation/IOSsetup/CreateApplicationRecord';
import ReviewXcodeProject from '../components/Pages/AppDocumentation/IOSsetup/ReviewXcodeProject';
import UpdateAppVersion from '../components/Pages/AppDocumentation/IOSsetup/UpdateAppsVersion';
import AppIcon from '../components/Pages/AppDocumentation/IOSsetup/AppIcon';
import BuildArchive from '../components/Pages/AppDocumentation/IOSsetup/BuildArchive';
import UpdateBuildVersionNumber from '../components/Pages/AppDocumentation/IOSsetup/UpdateBuildVersionNumber';
import CreateAppBuild from '../components/Pages/AppDocumentation/IOSsetup/CreateAppBuild';
import TestFlight from '../components/Pages/AppDocumentation/IOSsetup/TestFlight';
import ReleaseYourAppStore from '../components/Pages/AppDocumentation/IOSsetup/ReleaseYourAppStore';
import AdminPanelRequirement from '../components/Pages/AppDocumentation/Adminpanel/Requirement';
import InstallationProcess from '../components/wecourier-saas/AppDocs/AdminInstallactionProcess';
import Addons from '../components/Pages/AdminPanel/Addons';
import GoogleMapsSettings from '../components/Pages/AppDocumentation/GoogleMapsSettings';
//end app documentation 
import { ProjectList } from '../Enums/ProjectList';  
import PageNotFound from '../components/PageNotFound';
import RouteNoMatch from '../components/RouteNoMatch';
export default function WecourierRoutes({
    activePage, Documentation, defaultAppActiveMenu,
    defaultWebActiveMenu, setDefaultAppActiveMenu, setDocumentation,
    setDefaultWebActiveMenu, setActivePage, sectionRefs, activeSection
}) {


    return (

        <Routes> 
                <Route path={`/${ProjectList.WecourierSAAS}`} element={<Home
                    sectionRefs={sectionRefs}
                    activeSection={activeSection}
                    activePage={activePage}
                    documentation={Documentation}
                    setDocumentation={setDocumentation}
                    defaultAppActiveMenu={defaultAppActiveMenu}
                    defaultWebActiveMenu={defaultWebActiveMenu}
                ></Home>}>

                {/* Inside home navigation route  */}
                <Route path='introduction' element={<DefaultPage setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} activeSection={activeSection}></DefaultPage>} />
                <Route path='system-requirement' element={<GettingStarted setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} activeSection={activeSection} ></GettingStarted>}></Route>
                <Route path='configuration' element={<Configuration setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs}  ></Configuration>}></Route>
                <Route path='installation' element={<UploadInstall setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></UploadInstall>}></Route>
                <Route path='fcm-push-notification' element={<FCMPushNotification setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></FCMPushNotification>}></Route>

                {/* Authentication  */}
                <Route path='login' element={<Login setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} />}></Route>
                <Route path='merchant-signup' element={<Merchantsignup setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} />}> </Route>
                <Route path='company-signup'  element={<CompanySignup setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} />}> </Route>
                <Route path='forgot-password' element={<Forgotpassword setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} />}></Route>
                {/* Admin panel */}
                <Route path='admin/dashboard' element={<SuperAdminDashboard setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></SuperAdminDashboard>}></Route>
                <Route path='admin/companies' element={<Companies setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></Companies>}></Route>
                <Route path='admin/plans' element={<Plans setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></Plans>}></Route>
                <Route path='admin/subscriptions' element={<Subscriptions setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></Subscriptions>}></Route>
                 
                {/* End admin panel */}
                
                {/* Company panel */}
                <Route path='dashboard' element={<Dashboard setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></Dashboard>}></Route>
                <Route path='profile' element={<Profile setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></Profile>}></Route>
                <Route path='deliveryman' element={<DeliveryMan setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></DeliveryMan>}></Route>
                <Route path='hub-manage' element={<HubManage setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></HubManage>}></Route>
                <Route path='merchant-manage' element={<MerchantManage setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></MerchantManage>}></Route>
                <Route path='todo-list' element={<TodoList setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></TodoList>}></Route>
                <Route path='support' element={<Support setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></Support>}></Route>
                <Route path='parcels' element={<Parcels setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></Parcels>}></Route>
                <Route path='payout' element={<Payout setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></Payout>}></Route>
                <Route path='accounts' element={<Accounts setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></Accounts>}></Route>
                <Route path='users-roles' element={<UsersRoles setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></UsersRoles>}></Route>
                <Route path='payroll' element={<Payroll setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></Payroll>}></Route>
                <Route path='reports' element={<Reports setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></Reports>}></Route>
                <Route path='push-notification' element={<PushNotification setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></PushNotification>}></Route>
                <Route path='addons' element={<Addons setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></Addons>}></Route>
                <Route path='settings' element={<Settings setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></Settings>}></Route>
                {/* End Company panel */}

                {/* merchant panel */}
                <Route path='merchant/dashboard' element={<MerchantDashboard setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></MerchantDashboard>}></Route>
                <Route path='merchant/profile' element={<MerchantProfile setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></MerchantProfile>}></Route>
                <Route path='merchant/payment-information' element={<PaymentInformation setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></PaymentInformation>}></Route>
                <Route path='merchant/pickup-request' element={<MerchantPickupRequest setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></MerchantPickupRequest>}></Route>
                <Route path='merchant/support' element={<MerchantSupport setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></MerchantSupport>}></Route>
                <Route path='merchant/accounts' element={<MerchantAccounts setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></MerchantAccounts>}></Route>
                <Route path='merchant/shops' element={<MerchantShops setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></MerchantShops>}></Route>
                <Route path='merchant/parcels' element={<MerchantParcels setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></MerchantParcels>}></Route>
                <Route path='merchant/reports' element={<MerchantReports setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></MerchantReports>}></Route>
                <Route path='merchant/settings' element={<MerchantSettings setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></MerchantSettings>}></Route>
                {/* End merchant panel */}


                {/* app documentation   */}
                <Route index element={<AppGettingStarted setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></AppGettingStarted>}></Route>
                <Route path='app/recommendation' element={<Recommendation setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></Recommendation>}></Route>
                <Route path='app/installation' element={<Installation setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></Installation>}></Route>
                <Route path='app/prepare-flutter-environment' element={<PrepareFlutterEnvironment setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></PrepareFlutterEnvironment>}></Route>
                <Route path='app/google-firebase-setup' element={<GoogleFirebaseSetup setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}  ></GoogleFirebaseSetup>}></Route>
                <Route path='app/push-notification-setup' element={<PushNotificationSetup setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></PushNotificationSetup>}></Route>
                <Route path='app/change-api-server-details' element={<ChangeApiServerDetails setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></ChangeApiServerDetails>}></Route>
                <Route path='app/google-maps-settings' element={<GoogleMapsSettings setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></GoogleMapsSettings>}></Route>
                <Route path='app/change-app-logo' element={<ChangeAppLogo setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></ChangeAppLogo>}></Route>
                <Route path='app/change-app-name' element={<ChangeAppName setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></ChangeAppName>}  ></Route>
                <Route path='app/change-android-luncher-icon' element={<ChangeAndroidLuncherIcon setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></ChangeAndroidLuncherIcon>}></Route>
                <Route path='app/change-android-package-name' element={<ChangeAndroidPackageName setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}  ></ChangeAndroidPackageName>}></Route>
                <Route path='app/generate-debug-apk' element={<GenerateDebugApk setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></GenerateDebugApk>}  ></Route>
                <Route path='app/generate-signed-apk' element={<GenerateSignedApk setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></GenerateSignedApk>} ></Route>
                <Route path='app/preliminaries' element={<Preliminaries setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></Preliminaries>} ></Route>
                <Route path='app/register-your-app' element={<RegisterYourApp setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></RegisterYourApp>}  ></Route>
                <Route path='app/register-build-id' element={<RegisterBuildID setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></RegisterBuildID>}></Route>
                <Route path='app/create-application-record' element={<CreateApplicationRecord setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></CreateApplicationRecord>} ></Route>
                <Route path='app/review-xcode-project' element={<ReviewXcodeProject setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></ReviewXcodeProject>}  ></Route>
                <Route path='app/update-apps-version' element={<UpdateAppVersion setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></UpdateAppVersion>}  ></Route>
                <Route path='app/add-app-icon' element={<AppIcon setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></AppIcon>}  ></Route>
                <Route path='app/build-archive' element={<BuildArchive setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></BuildArchive>}  ></Route>
                <Route path='app/update-build-version-number' element={<UpdateBuildVersionNumber setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></UpdateBuildVersionNumber>}  ></Route>
                <Route path='app/create-app-build' element={<CreateAppBuild setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></CreateAppBuild>} ></Route>
                <Route path='app/testflight' element={<TestFlight setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></TestFlight>}  ></Route>
                <Route path='app/release-your-app-store' element={<ReleaseYourAppStore setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></ReleaseYourAppStore>} ></Route>
                <Route path='app/admin-panel-requirement' element={<AdminPanelRequirement setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></AdminPanelRequirement>}  ></Route>
                <Route path='app/admin-panel-installation-process' element={<InstallationProcess setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></InstallationProcess>}  ></Route>

                {/*use  merchant app */}
                <Route path='app/merchant/login' element={<AppMerchantLogin setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></AppMerchantLogin>}  ></Route>
                <Route path='app/merchant/dashboard' element={<AppMerchantDashboard setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></AppMerchantDashboard>}  ></Route>
                <Route path='app/merchant/profile' element={<AppMerchantProfile setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></AppMerchantProfile>} ></Route>
                <Route path='app/merchant/shops' element={<AppMerchantShops setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></AppMerchantShops>}  ></Route>
                <Route path='app/merchant/parcels' element={<AppMerchantParcels setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></AppMerchantParcels>}  ></Route>
                <Route path='app/merchant/fraud-check' element={<AppMerchantFraudCheck setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></AppMerchantFraudCheck>}  ></Route>
                <Route path='app/merchant/accounts' element={<AppMerchantAccounts setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></AppMerchantAccounts>}  ></Route>
                <Route path='app/merchant/reports' element={<AppMerchantReports setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></AppMerchantReports>}  ></Route>
                <Route path='app/merchant/settings' element={<AppMerchantSettings setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></AppMerchantSettings>}  ></Route>
                {/*end use  merchant app */}

                {/*use  delivery app */}
                <Route path='app/deliveryboy/login' element={<AppDeliveryboyLogin setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></AppDeliveryboyLogin>}  ></Route>
                <Route path='app/deliveryboy/dashboard' element={<AppDeliveryboyDashboard setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></AppDeliveryboyDashboard>}  ></Route>
                <Route path='app/deliveryboy/profile' element={<AppDeliveryboyProfile setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></AppDeliveryboyProfile>}  ></Route>
                <Route path='app/deliveryboy/payment-logs' element={<AppDeliveryboyPaymentLogs setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></AppDeliveryboyPaymentLogs>}  ></Route>
                <Route path='app/deliveryboy/parcel-payment-history' element={<AppDeliveryboyParcelPaymentHistory setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></AppDeliveryboyParcelPaymentHistory>}  ></Route>
                {/* End use  delivery app */}
                {/* end  app documentation  */} 
            </Route> 
            <Route path={`${ProjectList.WecourierSAAS}/*`} element={<PageNotFound />} />
        </Routes>

    );
}


