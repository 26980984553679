
import React, { useEffect, useRef, useContext } from 'react'
import ScrollToTop from '../../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import AccountsImg from '../../../../assets/images/weerp/web-image/business/accounts.png';  
import useScrollSpy from 'react-use-scrollspy';
import { ProjectTypeContext } from '../../../../ContextHandle';
export default function Accounts({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
 /* eslint-disable */

    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    const panelName   = 'Admin panel';
    projectType = projectType.projectType;

    useEffect(() => {
        setActivePage(1018);
        document.title = 'Accounts | ' + projectName + ' web';
        setDefaultWebActiveMenu('2');
    }, [projectName]);
  
    return (
        <div className='d-flex  justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                        <li className="breadcrumb-item active" aria-current="page">Accounts</li>
                    </ol>
                </nav>
                <div id="accounts" > 
                    <h4 className="mt-5">Accounts</h4> 
                    <div id='account-heads' ref={pagesectionRefs[0]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Accounts</h5>
                        <p className=" pt-3">Admin can manage his multiple accounts.You can set the default account. </p>
                        <div className='pt-3'>
                            <img src={AccountsImg} className="w-100 rounded" alt="account-heads.png"></img>
                        </div>
                    </div>
  

                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/admin/contacts`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Contacts</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/admin/bulk-import`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Bulk Import</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className='right-menu'>
                <ul>
                    {/* <li><a href='#account-heads' className={activeSection == 0 ? 'active-has' : ""}>Account Heads </a></li>  */}
                </ul>
            </div>
        </div>
    )
}

