import React from 'react' 
import { Routes, Route } from "react-router-dom"; 
import GettingStarted from '../components/wemover/Pages/AdminPanel/GettingStarted/GettingStarted';  
import Configuration from '../components/wemover/Pages/AdminPanel/GettingStarted/Configuration';
import UploadInstall from '../components/wemover/Pages/AdminPanel/GettingStarted/UploadInstall';

//authentication
import Login from '../components/wemover/Pages/AdminPanel/Auth/Login';
import Signup from '../components/wemover/Pages/AdminPanel/Auth/Signup'; 
import Forgotpassword from '../components/wemover/Pages/AdminPanel/Auth/Forgotpassword';

// admin panel
import AdminPanel from '../components/wemover/Pages/AdminPanel/AdminPanel'; 
//admin panel

//shipper panel all page
import ShipperPanel from '../components/wemover/Pages/AdminPanel/ShipperPanel';    
//end shipper panel all page
 
//driver panel all page 
import DriverPanel from '../components/wemover/Pages/AdminPanel/DriverPanel';  
//end driver panel all page
 
//app documentation  
 
import Recommendation from '../components/wemover/Pages/AppDocumentation/Recommendation';
import Installation from '../components/wemover/Pages/AppDocumentation/Installation';
import PrepareFlutterEnvironment from '../components/wemover/Pages/AppDocumentation/PrepareFlutterEnvironment';
 
import ChangeApiServerDetails from '../components/wemover/Pages/AppDocumentation/ChangeApiServerDetails';
import ChangeAppLogo from '../components/wemover/Pages/AppDocumentation/ChangeAppLogo';
import PushNotificationSetup from '../components/wemover/Pages/AppDocumentation/PushNotificationSetup';
import ChangeAppName from '../components/wemover/Pages/AppDocumentation/AndroidSetup/ChangeAppName';
import ChangeAndroidLuncherIcon from '../components/wemover/Pages/AppDocumentation/AndroidSetup/ChangeAndroidLuncherIcon';
import ChangeAndroidPackageName from '../components/wemover/Pages/AppDocumentation/AndroidSetup/ChangeAndroidPackageName';
import GenerateDebugApk from '../components/wemover/Pages/AppDocumentation/AndroidSetup/GenerateDebugApk';
import GenerateSignedApk from '../components/wemover/Pages/AppDocumentation/AndroidSetup/GenerateSignedApk';
import Preliminaries from '../components/wemover/Pages/AppDocumentation/IOSsetup/Preliminaries';
import RegisterYourApp from '../components/wemover/Pages/AppDocumentation/IOSsetup/RegisterYourApp';
import RegisterBuildID from '../components/wemover/Pages/AppDocumentation/IOSsetup/RegisterBuildID';
import CreateApplicationRecord from '../components/wemover/Pages/AppDocumentation/IOSsetup/CreateApplicationRecord';
import ReviewXcodeProject from '../components/wemover/Pages/AppDocumentation/IOSsetup/ReviewXcodeProject';
import UpdateAppVersion from '../components/wemover/Pages/AppDocumentation/IOSsetup/UpdateAppsVersion';
import AppIcon from '../components/wemover/Pages/AppDocumentation/IOSsetup/AppIcon';
import BuildArchive from '../components/wemover/Pages/AppDocumentation/IOSsetup/BuildArchive';
import UpdateBuildVersionNumber from '../components/wemover/Pages/AppDocumentation/IOSsetup/UpdateBuildVersionNumber';
import CreateAppBuild from '../components/wemover/Pages/AppDocumentation/IOSsetup/CreateAppBuild';
import TestFlight from '../components/wemover/Pages/AppDocumentation/IOSsetup/TestFlight';
import ReleaseYourAppStore from '../components/wemover/Pages/AppDocumentation/IOSsetup/ReleaseYourAppStore';
import AdminPanelRequirement from '../components/wemover/Pages/AppDocumentation/Adminpanel/Requirement';
import InstallationProcess from '../components/wemover/Pages/AppDocumentation/Adminpanel/InstallactionProcess';

//how to use app
import AppGettingStarted from '../components/wemover/Pages/AppDocumentation/GettingStarted';
import AppShipper from '../components/wemover/Pages/AppDocumentation/ShipperAndDriver/ShipperApp';
import AppDriver from '../components/wemover/Pages/AppDocumentation/ShipperAndDriver/DriverApp'; 
//end how to use app

//end app documentation  

import { ProjectList } from '../Enums/ProjectList';  
import PageNotFound from '../components/PageNotFound'; 
import Home from '../components/wemover/Home';
import DefaultPage from '../components/wemover/Pages/AdminPanel/GettingStarted/DefaultPage';

export default function WemoverRoutes({
    activePage, Documentation, defaultAppActiveMenu,
    defaultWebActiveMenu, setDefaultAppActiveMenu, setDocumentation,
    setDefaultWebActiveMenu, setActivePage, sectionRefs, activeSection
}) {


    return (

        <Routes> 
                <Route path={`/${ProjectList.Wemover}`} element={<Home
                    sectionRefs={sectionRefs}
                    activeSection={activeSection}
                    activePage={activePage}
                    documentation={Documentation}
                    setDocumentation={setDocumentation}
                    defaultAppActiveMenu={defaultAppActiveMenu}
                    defaultWebActiveMenu={defaultWebActiveMenu}
                ></Home>}>


            {/* ======================================================= Start web documentation =======================================================*/}

                {/* Getting started  */}
                    <Route path='introduction' element={<DefaultPage setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} activeSection={activeSection}></DefaultPage>} />
                    <Route path='system-requirement' element={<GettingStarted setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} activeSection={activeSection} ></GettingStarted>}></Route>
                    <Route path='configuration' element={<Configuration setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs}  ></Configuration>}></Route>
                    <Route path='installation' element={<UploadInstall setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></UploadInstall>}></Route>
                {/* end Getting started  */}

                {/* Authentication  */}
                    <Route path='login' element={<Login setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} />}></Route>
                    <Route path='signup' element={<Signup setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} />}> </Route>
                    <Route path='forgot-password' element={<Forgotpassword setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} />}></Route>
                {/* End Authentication */}
                
                {/* Admin panel */} 
                    <Route path='admin-panel' element={<AdminPanel setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></AdminPanel>}></Route>
                {/* End admin panel */}
                
                {/* shipper panel */}
                    <Route path='shipper-panel' element={<ShipperPanel setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></ShipperPanel>}></Route>
                {/* End shipper panel */}

                {/* driver panel */}
                    <Route path='driver-panel' element={<DriverPanel setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></DriverPanel>}></Route>
                {/* End driver panel */}

                {/* ======================================================= end web documentation =======================================================*/}

                {/* ======================================================= Start APP documentation =======================================================*/}
                {/* app documentation   */}
                <Route index element={<AppGettingStarted setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></AppGettingStarted>}></Route>
                <Route path='app/recommendation' element={<Recommendation setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></Recommendation>}></Route>
                <Route path='app/installation' element={<Installation setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></Installation>}></Route>
                <Route path='app/prepare-flutter-environment' element={<PrepareFlutterEnvironment setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></PrepareFlutterEnvironment>}></Route>
                <Route path='app/change-api-server-details' element={<ChangeApiServerDetails setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></ChangeApiServerDetails>}></Route>
             
                <Route path='app/change-app-logo' element={<ChangeAppLogo setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></ChangeAppLogo>}></Route>
                <Route path='app/change-app-name' element={<ChangeAppName setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></ChangeAppName>}  ></Route>
                <Route path='app/push-notification-setup' element={<PushNotificationSetup setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></PushNotificationSetup>}  ></Route>
                <Route path='app/change-android-luncher-icon' element={<ChangeAndroidLuncherIcon setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></ChangeAndroidLuncherIcon>}></Route>
                <Route path='app/change-android-package-name' element={<ChangeAndroidPackageName setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}  ></ChangeAndroidPackageName>}></Route>
                <Route path='app/generate-debug-apk' element={<GenerateDebugApk setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></GenerateDebugApk>}  ></Route>
                <Route path='app/generate-signed-apk' element={<GenerateSignedApk setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></GenerateSignedApk>} ></Route>
                <Route path='app/preliminaries' element={<Preliminaries setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></Preliminaries>} ></Route>
                <Route path='app/register-your-app' element={<RegisterYourApp setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></RegisterYourApp>}  ></Route>
                <Route path='app/register-build-id' element={<RegisterBuildID setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></RegisterBuildID>}></Route>
                <Route path='app/create-application-record' element={<CreateApplicationRecord setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></CreateApplicationRecord>} ></Route>
                <Route path='app/review-xcode-project' element={<ReviewXcodeProject setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></ReviewXcodeProject>}  ></Route>
                <Route path='app/update-apps-version' element={<UpdateAppVersion setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></UpdateAppVersion>}  ></Route>
                <Route path='app/add-app-icon' element={<AppIcon setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></AppIcon>}  ></Route>
                <Route path='app/build-archive' element={<BuildArchive setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></BuildArchive>}  ></Route>
                <Route path='app/update-build-version-number' element={<UpdateBuildVersionNumber setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></UpdateBuildVersionNumber>}  ></Route>
                <Route path='app/create-app-build' element={<CreateAppBuild setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></CreateAppBuild>} ></Route>
                <Route path='app/testflight' element={<TestFlight setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></TestFlight>}  ></Route>
                <Route path='app/release-your-app-store' element={<ReleaseYourAppStore setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></ReleaseYourAppStore>} ></Route>
                <Route path='app/admin-panel-requirement' element={<AdminPanelRequirement setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></AdminPanelRequirement>}  ></Route>
                <Route path='app/admin-panel-installation-process' element={<InstallationProcess setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></InstallationProcess>}  ></Route>

                {/*use  how to use app */} 
                <Route path='app/shipper-app' element={<AppShipper setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></AppShipper>}  ></Route>
                <Route path='app/driver-app' element={<AppDriver setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></AppDriver>}  ></Route>
                {/*end use  business app */}
                {/* ======================================================= end APP documentation =======================================================*/}
            </Route> 
            <Route path={`${ProjectList.Wemover}/*`} element={<PageNotFound />} />
        </Routes>

    );
}


