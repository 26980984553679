import React, { useEffect, useRef, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import '../../../../assets/images/codestyle.css';
import useScrollSpy from 'react-use-scrollspy';
import ScrollToTop from '../../../Shared/ScrollToTop'; 
import ApiServer from '../../../../assets/images/weerp/app-image/environment-setup/api-urls.png';
import ApiKey from '../../../../assets/images/weerp/app-image/environment-setup/base_client_apikey_change.png';
import { ProjectTypeContext } from '../../../../ContextHandle'
const ChangeApiServerDetails = ({ setActivePage, sectionRefs, setDocumentation, setDefaultAppActiveMenu }) => {
 /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(506);
        setDocumentation(2);
        setDefaultAppActiveMenu(2);
        document.title = 'Change api server details | ' + projectName;
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);


    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });


    return (
        <div className='d-flex   justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div id="system-requirements  " className='pt-5 right-content-box-width' >
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">Common Setup</li>
                        <li className="breadcrumb-item active" aria-current="page">Change api server details</li>
                    </ol>
                </nav>

                <h3 className="pt-5 font-weight-bold" >Change API Server</h3>
                <p>
                    <b>Select Project {`>`} go to lib folder{` >`} services folder {`>`} urls.dart {`>`} </b> then change the root url with your url now if your Sever is <b>http://www.abc.com</b>  so your service url like that <b> http://www.abc.com </b> 
                </p>
                <div ref={pagesectionRefs[1]}>
                    <div className='pt-3'>
                        <img src={ApiServer} className="w-100 rounded" alt="Api Server.png "></img>
                    </div>
                </div>

               
                <p className='mt-3'>
                    <b>Select Project {`>`} go to lib folder{` >`} services folder {`>`} base_client.dart {`>`} </b> then change the  API key. 
                </p>

                <div ref={pagesectionRefs[1]}>
                    <div className='pt-3'>
                        <img src={ApiKey} className="w-100 rounded" alt="Api key.png "></img>
                    </div>
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/app/prepare-flutter-environment`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Prepare flutter environment</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/app/change-app-logo`} className="text-decoration-none">
                            <a href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Change app logo</h4>
                        </NavLink>
                    </div>
                </div>
            </div>

        </div>

    );

};

export default ChangeApiServerDetails;
