import React, { useEffect, useRef, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import '../../../../../assets/images/codestyle.css';
import useScrollSpy from 'react-use-scrollspy';
import ScrollToTop from '../../../../Shared/ScrollToTop';
import { ProjectTypeContext } from '../../../../../ContextHandle';
const RegisterBuildID = ({ setActivePage, sectionRefs, setDocumentation, setDefaultAppActiveMenu }) => {
 /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;

    useEffect(() => {
        setActivePage(515);
        setDocumentation(2);
        setDefaultAppActiveMenu(4);
        document.title = 'Register a Bundle ID | ' + projectName;
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);


    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });

    return (
        <div className='d-flex   justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div id="system-requirements  " className='pt-5 right-content-box-width' >
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`} ><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">iOS Setup</li>
                        <li className="breadcrumb-item active" aria-current="page">Register a Bundle ID</li>
                    </ol>
                </nav>

                <div >
                    <h3 className="pt-5 font-weight-bold" >Register a Bundle ID</h3>
                    <p><b>Every iOS application is associated with a Bundle ID, a unique identifier registered with Apple. To register a Bundle ID for your app, follow these steps:</b></p>
                    <ol>
                        <li className='py-2'>Open the <a rel="noreferrer" href='https://developer.apple.com/account/ios/identifier/bundle' target="_blank">App IDs</a> page of your developer account.</li>
                        <li className='py-2'>Click + to create a new Bundle ID.</li>
                        <li className='py-2'>Enter an app name, select <b>Explicit App ID</b>, and enter an ID.</li>
                        <li className='py-2'>Select the services your app uses, then click <b>Continue</b>.</li>
                        <li className='py-2'>On the next page, confirm the details and click <b>Register</b> to register your Bundle ID.</li>
                    </ol>
                </div>


                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/app/register-your-app`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Register Your App</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/app/create-application-record`} className="text-decoration-none">
                            <a href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Create an application record on App Store Connect</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>

    );

};

export default RegisterBuildID;
