import React, { useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import '../../../../../assets/images/codestyle.css';
import ScrollToTop from '../../../../Shared/ScrollToTop'; 
import ShopsCreateImg from '../../../../../assets/images/weerp/app-image/profile.png';
import { ProjectTypeContext } from '../../../../../ContextHandle';
const Product = ({ setActivePage, setDocumentation, setDefaultAppActiveMenu }) => {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(107);
        setDocumentation(2);
        document.title = "Product | " + projectName;
        setDefaultAppActiveMenu(6);
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);

    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className='right-content-box-width'>
                <div id="system-requirements" >
                    <h4 className="pt-5 mt-5">Product Manage</h4> 
                    <p>All products will be listed. Product details can be seen. Multiple variation wise products can be added. The price of multiple variation wise products can be seen. also Variatio,  warranty, unit can be added category and sub-category wise product.</p>
                    <div >
                        <img src={ShopsCreateImg} className="w-75 rounded" alt="Shops create" />
                    </div>

                    <div className="d-flex justify-content-between border-top mt-5">
                        <div className="text-start mt-4">
                            <NavLink to={`/${projectType}/app/business/profile`} className="text-decoration-none">
                                <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                                <h4 className='pagination-title'>Profile</h4>
                            </NavLink>
                        </div>
                        <div className="text-end my-2">
                            <NavLink to={`/${projectType}/app/business/pos`} className="text-decoration-none">
                                <a href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                                <h4 className='pagination-title'>POS</h4>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    );

};

export default Product;
