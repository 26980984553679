import React, { useEffect, useRef, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import '../../../../assets/images/codestyle.css';
import useScrollSpy from 'react-use-scrollspy';
import ScrollToTop from '../../../Shared/ScrollToTop';
import { ProjectTypeContext } from '../../../../ContextHandle';
const CreateAppBuild = ({ setActivePage, sectionRefs, setDocumentation, setDefaultAppActiveMenu }) => {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(522);
        setDocumentation(2);
        setDefaultAppActiveMenu(4);
        document.title = 'Update the app’s build and version numbers | ' + projectName;
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);


    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });


    return (
        <div className='d-flex   justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div id="system-requirements  " className='pt-5 right-content-box-width' >
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">iOS Setup</li>
                        <li className="breadcrumb-item active" aria-current="page">Create an app bundle</li>
                    </ol>
                </nav>

                <div >
                    <h3 className="pt-5 font-weight-bold" >Create an app bundle</h3>
                    <p>Run flutter build ipa to produce an Xcode build archive  <mark className='text-danger'>.xcarchive</mark> file in your project  <mark className='text-danger'>build/ios/archive/directory</mark> and an App Store app bundle  <mark className='text-danger'>(.ipa file) in build/ios/ipa</mark>.</p>

                    <p>Consider adding the  <mark className='text-danger'>--obfuscate </mark>and  <mark className='text-danger'>--split-debug-info</mark> flags to<a target="_blank" href='https://docs.flutter.dev/deployment/obfuscate'> obfuscate your Dart code </a>to make it more difficult to reverse engineer.</p>

                    <p>If you are not distributing to the App Store, you can optionally choose a different <a target="_blank" href='https://help.apple.com/xcode/mac/current/#/dev31de635e5'>export method</a>  <mark className='text-danger'>--export-method ad-hoc, --export-method development or --export-method enterprise.</mark></p>
                </div>


                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/app/update-build-version-number`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Update the app’s build and version numbers</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/app/testflight`} className="text-decoration-none">
                            <a href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Release your app on TestFlight</h4>
                        </NavLink>
                    </div>
                </div>
            </div>


        </div>

    );

};

export default CreateAppBuild;
