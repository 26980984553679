import React, { useEffect ,useContext} from 'react' 
import { ProjectTypeContext } from '../ContextHandle';
export default function FaviconDynamic() { 
    const projectContext = useContext(ProjectTypeContext);
    useEffect(()=>{ 
        let link = document.querySelector("link[rel~='icon']"); 
        var favicon = `/brand-favicon.png`; 
        link.setAttribute('href', favicon);  
    },[projectContext]);
  return '';
}
