import React, { useEffect, useContext } from 'react'
import ScrollToTop from '../../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import SalesList from '../../../../assets/images/weerp/web-image/business/sales.png'; 
import { ProjectTypeContext } from '../../../../ContextHandle';
export default function Sales({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {

    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    const panelName   = 'Business panel';
    projectType = projectType.projectType;
    /* eslint-disable */
    useEffect(() => {
        setActivePage(1014);
        document.title = 'Sales | ' + projectName + ' web';
        setDefaultWebActiveMenu(2);
    });
       /* eslint-enable */
    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                        <li className="breadcrumb-item active" aria-current="page">Sales</li>
                    </ol>
                </nav>
                <div id="daliveryman" ref={sectionRefs['21']}>
                    <h3 className="mt-3  font-weight-bold">Sales List</h3>
                    <p>Sales can be manage and sales payments can be made. invoices can be viewed and invoices can be printed. </p>
                    <h4 className=" pt-3">Sales list</h4>
                    <div className='pt-3'>
                        <img src={SalesList} className="w-100 rounded" alt="sales.png"></img>
                    </div> 
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/business/purchase`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Purchase & Return</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/business/pos`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>POS</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
