import React, { useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import '../../../../assets/images/codestyle.css';
import ScrollToTop from '../../../Shared/ScrollToTop';
import CodCharges from '../../../../assets/images/app-image/merchant/cod-charges.png';
import Deliverycharge from '../../../../assets/images/app-image/merchant/delivery-charge.png';
import { ProjectTypeContext } from '../../../../ContextHandle';
const Settings = ({ setActivePage, setDocumentation, setDefaultAppActiveMenu }) => {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(112);
        setDocumentation(2);
        document.title = "Settings | " + projectName;
        setDefaultAppActiveMenu(6);
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);

    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className='right-content-box-width'>
                <div id="system-requirements" >
                    <h4 className="pt-5">Settings</h4>

                    <a href='#cod-charges' className='text-primary font-weight-bold'># COD Charge</a><br />
                    <a href='#delivery-charge' className='text-primary font-weight-bold'># Delivery Charges</a><br />
                    <div id='cod-charges'>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># COD Charge</h5>
                        <div className='pt-3'>
                            <img src={CodCharges} className="w-100 rounded" alt="Cod charges"></img>
                        </div>
                    </div>
                    <div id='delivery-charge'>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Delivery Charge </h5>
                        <div className='pt-3'>
                            <img src={Deliverycharge} className="w-100 rounded" alt="Deliverycharges"></img>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between border-top mt-5">
                        <div className="text-start mt-4">
                            <NavLink to={`/${projectType}/app/merchant/fraud-check`} className="text-decoration-none">
                                <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                                <h4 className='pagination-title'>Fraud Check</h4>
                            </NavLink>
                        </div>
                        <div className="text-end my-2">
                            <NavLink to={`/${projectType}/app/deliveryboy/login`} className="text-decoration-none">
                                <a href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                                <h4 className='pagination-title'>Use Delivery Boy App</h4>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    );

};

export default Settings;
