import React, { useEffect, useContext } from 'react'
import ScrollToTop from './../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import deliverymanList from './../../../assets/images/module/admin-panel/deliveryman/deliveryman-list.png';
import addDeliveryman from './../../../assets/images/module/admin-panel/deliveryman/deliveryman-create.png';
import { ProjectTypeContext } from '../../../ContextHandle';
export default function Deliveryman({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
 /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    const panelName   = projectType.panelName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1010);
        document.title = 'Deliveryman | ' + projectName + ' web';
        setDefaultWebActiveMenu(2);
    });
    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                        <li className="breadcrumb-item active" aria-current="page">Delivery man</li>
                    </ol>
                </nav>
                <div id="daliveryman" ref={sectionRefs['21']}>
                    <h3 className="mt-3  font-weight-bold">Delivery man</h3>

                    <h4 className=" pt-3">Deliveryman list</h4>
                    <div className='pt-3'>
                        <img src={deliverymanList} className="w-100 rounded" alt="deliverymanList.png"></img>
                    </div>
                    <h4 className=" pt-3">Add Deliveryman</h4>
                    <div className='pt-3'>
                        <img src={addDeliveryman} className="w-100 rounded" alt="addDeliveryman.png"></img>
                    </div>
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/profile`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Profile</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/hub-manage`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Branch Manage</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
