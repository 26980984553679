import React, { useEffect, useRef, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import '../../../assets/images/codestyle.css';
import useScrollSpy from 'react-use-scrollspy';
import ScrollToTop from '../../Shared/ScrollToTop';
import DeliveryAppGoogleMapsKey from '../../../assets/images/delivery-app-google-maps.png';
import MerchantAppGoogleMapsKey from '../../../assets/images/merchant-app-google-maps.png';
import { ProjectTypeContext } from '../../../ContextHandle'
const GoogleMapsSettings = ({ setActivePage, sectionRefs, setDocumentation, setDefaultAppActiveMenu }) => {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(527);
        setDocumentation(2);
        setDefaultAppActiveMenu(2);
        document.title = 'Google maps setting | ' + projectName;
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);


    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });



    return (
        <div className='d-flex   justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div id="system-requirements  " className='pt-5 right-content-box-width' >
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`} ><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">Common Setup</li>
                        <li className="breadcrumb-item active" aria-current="page">Google map setting</li>
                    </ol>
                </nav>

                <div >
                    <h3 className="pt-5 font-weight-bold" >Google map setting</h3>
                    <ul>
                        <li>   Go to the <b>Google Maps Platform {`>`} Credentials</b> page. On the Credentials page, click <b>Create credentials {`>`} API key</b>. The API key created dialog displays your newly created API key. <a href='https://developers.google.com/maps/documentation/embed/get-api-key#:~:text=Go%20to%20the%20Google%20Maps%20Platform%20%3E%20Credentials%20page.&text=On%20the%20Credentials%20page%2C%20click,Click%20Close.' target='_blank'>Click More</a>  </li>
                        <li>Go to the <b>delivery-app {`>`} api-list.dart</b> change <b>mapGoogleApiKey</b> put your google map api key.</li>
                    </ul>
                    <b>Delivery App api-list.dart file:</b>
                    <div className='pt-3'>
                        <img src={DeliveryAppGoogleMapsKey} className="w-100 rounded" alt="Deliveryapp.png "></img>
                    </div>

                    <div className='mt-5'><b >Merchant app api-list.dart file:</b></div>
                    <p className='mt-3'>Go to the <b>merchant-app {`>`} api-list.dart</b> change <b>mapGoogleApiKey</b> put your google map api key.</p>
                    <div className='pt-3'>
                        <img src={MerchantAppGoogleMapsKey} className="w-100 rounded" alt="Merchantapp.png "></img>
                    </div>

                </div>


                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/app/change-api-server-details`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Change Api Server Details</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/app/change-app-logo`} className="text-decoration-none">
                            <a href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Change app logo</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default GoogleMapsSettings;
