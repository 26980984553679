import React, { useEffect, useRef, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import '../../../../assets/images/codestyle.css';
import useScrollSpy from 'react-use-scrollspy';
import ScrollToTop from '../../../Shared/ScrollToTop';
import LuncherGenerator from '../../../../assets/images/app-image/environment-setup/luncher-generator.png';
import LuncherIcon from '../../../../assets/images/app-image/environment-setup/android-luncher-icon.png';
import { ProjectTypeContext } from '../../../../ContextHandle';
const ChangeAndroidLuncherIcon = ({ setActivePage, sectionRefs, setDocumentation, setDefaultAppActiveMenu }) => {
 /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;

    useEffect(() => {
        setActivePage(509);
        setDocumentation(2);
        setDefaultAppActiveMenu(3);
        document.title = 'Change android luncher icon | ' + projectName;
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);


    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });


    return (
        <div className='d-flex   justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div id="system-requirements  " className='pt-5 right-content-box-width' >
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">Android Setup</li>
                        <li className="breadcrumb-item active" aria-current="page">Change android luncher icon</li>
                    </ol>
                </nav>

                <div >
                    <h3 className="pt-5 font-weight-bold" >Change android luncher icon</h3>
                    <ul>
                        <li>Open this <a target="_blank" href='https://romannurik.github.io/AndroidAssetStudio/icons-launcher.html#foreground.type=clipart&foreground.clipart=android&foreground.space.trim=1&foreground.space.pad=0.25&foreColor=rgba(96%2C%20125%2C%20139%2C%200)&backColor=rgb(68%2C%20138%2C%20255)&crop=0&backgroundShape=circle&effects=none&name=ic_launcher'>link</a> from your browser.</li>
                        <li className='py-2'>Create your desired launcher icon and download.
                            <div className='pt-3'>
                                <img src={LuncherGenerator} className="w-100 rounded" alt="lunchergenerator.png "></img>
                            </div>
                        </li>
                        <li className='py-2'>
                            Extract the downloaded zip file. Go to <b>Project » android » app » src » main »</b> res and replace existing files with your extracted files.
                            <div className='pt-3'>
                                <img src={LuncherIcon} className="w-100 rounded" alt="lunchericon.png "></img>
                            </div>
                        </li>
                    </ul>

                </div>


                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/app/change-app-name`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Change App Name</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/app/change-android-package-name`} className="text-decoration-none">
                            <a href="#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Change Android Package Name</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>

    );

};

export default ChangeAndroidLuncherIcon;
