import React, { useEffect, useContext } from 'react'
import ScrollToTop from './../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import ProfileImg from './../../../assets/images/module/merchant-panel/profile/profile.png';
import passwordChange from './../../../assets/images/module/merchant-panel/profile/change-password.png';
import { ProjectTypeContext } from '../../../ContextHandle';
export default function Profile({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1023);
        document.title = 'Profile | ' + projectName + ' web';
        setDefaultWebActiveMenu(3);
    }, [projectName]);
    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">Merchant Panel</li>
                        <li className="breadcrumb-item active" aria-current="page">Profile</li>
                    </ol>
                </nav>
                <div id="accounts" ref={sectionRefs['24']}>

                    <h4 className="font-weight-bold mt-5  ">Profile</h4>
                    <p  >Merchant can change his profile. After clicking on the profile, all the information of the profile will be shown. If any profile information needs to be changed. Then on clicking on the edit button, a form will be displayed, if you submit the required information in that form, the profile information will be changed. </p>
                    <div className='pt-3 mb-3'>
                        <img src={ProfileImg} className="w-100 rounded" alt="Profile.png"></img>
                    </div>
                    <p  > <b>Password Change : </b> Merchant can also change his password for that he has to click on change password and submit with new password the password will be changed.</p>
                    <div className='pt-3'>
                        <img src={passwordChange} className="w-100 rounded" alt="passwordChange.png"></img>
                    </div>
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/merchant/dashboard`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Dashboard</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/merchant/payment-information`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Payment Information</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
