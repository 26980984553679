import React, { useEffect, useRef, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import '../../../../assets/images/codestyle.css';
import useScrollSpy from 'react-use-scrollspy';
import ScrollToTop from '../../../Shared/ScrollToTop';
import { ProjectTypeContext } from '../../../../ContextHandle';
const ReleaseYourAppStore = ({ setActivePage, sectionRefs, setDocumentation, setDefaultAppActiveMenu }) => {
 /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;


    useEffect(() => {
        setActivePage(524);
        setDocumentation(2);
        setDefaultAppActiveMenu(4);
        document.title = 'Release your app to the App Store | ' + projectName;
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);


    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });


    return (
        <div className='d-flex   justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div id="system-requirements  " className='pt-5 right-content-box-width' >
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">iOS Setup</li>
                        <li className="breadcrumb-item active" aria-current="page">Release your app to the App Store</li>
                    </ol>
                </nav>

                <div >
                    <h3 className="pt-5 font-weight-bold" >Release your app to the App Store</h3>
                    <b>When you’re ready to release your app to the world, follow these steps to submit your app for review and release to the App Store.</b>
                    <ul>
                        <li>Select Pricing and Availability from the sidebar of your app’s application details page on App Store Connect and complete the required information.</li>
                        <li>Select the status from the sidebar. If this is the first release of this app, its status is Save</li>
                        <li>Add the email addresses of any internal testers. You can add additional internal users in the 1.0 Prepare for Submission Complete all required fields.</li>
                        <li>Click Submit for Review.</li>
                    </ul>
                </div>


                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/app/testflight`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Release your app on TestFlight</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/app/admin-panel-requirement`} className="text-decoration-none">
                            <a href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Requirement</h4>
                        </NavLink>
                    </div>
                </div>
            </div>


        </div>

    );

};

export default ReleaseYourAppStore;
