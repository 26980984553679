import React, { useEffect,useContext } from 'react'
import ScrollToTop from '../../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import PosImg from './../../../../assets/images/weerp/web-image/branch/pos.png';  
import { ProjectTypeContext } from '../../../../ContextHandle';
export default function POS({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1027);
        document.title = 'POS | ' + projectName + ' web';
        setDefaultWebActiveMenu(3);
    }, [projectName]);
    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">Branch Panel</li>
                        <li className="breadcrumb-item active" aria-current="page">POS</li>
                    </ol>
                </nav>
                <div id="accounts" ref={sectionRefs['27']}> 
                    <h4 className="font-weight-bold mt-5  ">POS</h4> 
                    <p>Instant sales and payments can be made through POS. POS sales Invoices can be viewed and printed. branch can Instant sale his branch products. </p>
                    <h4 className='pt-3'>Deception Check list</h4>
                    <div className='pt-3'>
                        <img src={PosImg} className="w-100 rounded" alt="Fraudchecklist.png"></img>
                    </div> 
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/branch/sales`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Sales</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/branch/need-help`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Need help</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
