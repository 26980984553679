import React from 'react' 
import { Routes, Route } from "react-router-dom"; 
import GettingStarted from '../components/weerp-normal/Pages/AdminPanel/GettingStarted/GettingStarted';  
import Configuration from '../components/weerp-normal/Pages/AdminPanel/GettingStarted/Configuration';
import UploadInstall from '../components/weerp-normal/Pages/AdminPanel/GettingStarted/UploadInstall';

//authentication
import Login from '../components/weerp-normal/Pages/AdminPanel/Auth/Login';
import BusinessSignup from '../components/weerp-normal/Pages/AdminPanel/Auth/BusinessSignup'; 
import Forgotpassword from '../components/weerp-normal/Pages/AdminPanel/Auth/Forgotpassword';

// admin panel
import SuperAdminDashboard from '../components/weerp-normal/Pages/AdminPanel/Dashboard';
import AdminRoleEmployee from '../components/weerp-normal/Pages/AdminPanel/RoleEmployee';
import AdminBusiness from '../components/weerp-normal/Pages/AdminPanel/Business';
import AdminPlanSubscription from '../components/weerp-normal/Pages/AdminPanel/PlanSubscription';
import AdminSettings from '../components/weerp-normal/Pages/AdminPanel/Settings';
//admin panel

//business panel all page
import Dashboard from '../components/weerp-normal/Pages/BusinessPanel/Dashboard';  
import BusinessProfile from  '../components/weerp-normal/Pages/BusinessPanel/Profile'; 


import BusinessEmployees from '../components/weerp-normal/Pages/BusinessPanel/Employees';  
import BusinessServices from '../components/weerp-normal/Pages/BusinessPanel/Services';  
import BusinessProducts from '../components/weerp-normal/Pages/BusinessPanel/Products'; 
import BusinessPurchaseReturn from '../components/weerp-normal/Pages/BusinessPanel/PurchaseReturn'; 
import BusinessSales from '../components/weerp-normal/Pages/BusinessPanel/Sales'; 
import BusinessPOS from '../components/weerp-normal/Pages/BusinessPanel/POS'; 
import BusinessStockTransfer from '../components/weerp-normal/Pages/BusinessPanel/StockTransfer'; 
import BusinessContacts from '../components/weerp-normal/Pages/BusinessPanel/Contacts'; 
import BusinessAccounts from '../components/weerp-normal/Pages/BusinessPanel/Accounts'; 
import BusinessBulkImport from '../components/weerp-normal/Pages/BusinessPanel/BulkImport'; 
import BusinessHRMangement from '../components/weerp-normal/Pages/BusinessPanel/HRMangement'; 
import BusinessTodoManage from '../components/weerp-normal/Pages/BusinessPanel/TodoManage'; 
import BusinessReports from '../components/weerp-normal/Pages/BusinessPanel/Reports'; 
import BusinessSettings from '../components/weerp-normal/Pages/BusinessPanel/Settings'; 

//end business panel all page
 
//branch panel all page 
import BranchDashboard from '../components/weerp-normal/Pages/BranchPanel/Dashboard'; 
import BranchProfile from '../components/weerp-normal/Pages/BranchPanel/Profile'; 
import BranchSales from '../components/weerp-normal/Pages/BranchPanel/Sales'; 
import BranchPOS from '../components/weerp-normal/Pages/BranchPanel/POS'; 
import BranchNeedHelp from '../components/weerp-normal/Pages/BranchPanel/NeedHelp'; 
import BranchAccounts from '../components/weerp-normal/Pages/BranchPanel/Accounts'; 
import BranchHRM from '../components/weerp-normal/Pages/BranchPanel/Hrm';  
import BranchReports from '../components/weerp-normal/Pages/BranchPanel/Reports'; 
//end branch panel all page
 
//app documentation  
 
import Recommendation from '../components/weerp-normal/Pages/AppDocumentation/Recommendation';
import Installation from '../components/weerp-normal/Pages/AppDocumentation/Installation';
import PrepareFlutterEnvironment from '../components/weerp-normal/Pages/AppDocumentation/PrepareFlutterEnvironment';
 
import ChangeApiServerDetails from '../components/weerp-normal/Pages/AppDocumentation/ChangeApiServerDetails';
import ChangeAppLogo from '../components/weerp-normal/Pages/AppDocumentation/ChangeAppLogo';
import ChangeAppName from '../components/weerp-normal/Pages/AppDocumentation/AndroidSetup/ChangeAppName';
import ChangeAndroidLuncherIcon from '../components/weerp-normal/Pages/AppDocumentation/AndroidSetup/ChangeAndroidLuncherIcon';
import ChangeAndroidPackageName from '../components/weerp-normal/Pages/AppDocumentation/AndroidSetup/ChangeAndroidPackageName';
import GenerateDebugApk from '../components/weerp-normal/Pages/AppDocumentation/AndroidSetup/GenerateDebugApk';
import GenerateSignedApk from '../components/weerp-normal/Pages/AppDocumentation/AndroidSetup/GenerateSignedApk';
import Preliminaries from '../components/weerp-normal/Pages/AppDocumentation/IOSsetup/Preliminaries';
import RegisterYourApp from '../components/weerp-normal/Pages/AppDocumentation/IOSsetup/RegisterYourApp';
import RegisterBuildID from '../components/weerp-normal/Pages/AppDocumentation/IOSsetup/RegisterBuildID';
import CreateApplicationRecord from '../components/weerp-normal/Pages/AppDocumentation/IOSsetup/CreateApplicationRecord';
import ReviewXcodeProject from '../components/weerp-normal/Pages/AppDocumentation/IOSsetup/ReviewXcodeProject';
import UpdateAppVersion from '../components/weerp-normal/Pages/AppDocumentation/IOSsetup/UpdateAppsVersion';
import AppIcon from '../components/weerp-normal/Pages/AppDocumentation/IOSsetup/AppIcon';
import BuildArchive from '../components/weerp-normal/Pages/AppDocumentation/IOSsetup/BuildArchive';
import UpdateBuildVersionNumber from '../components/weerp-normal/Pages/AppDocumentation/IOSsetup/UpdateBuildVersionNumber';
import CreateAppBuild from '../components/weerp-normal/Pages/AppDocumentation/IOSsetup/CreateAppBuild';
import TestFlight from '../components/weerp-normal/Pages/AppDocumentation/IOSsetup/TestFlight';
import ReleaseYourAppStore from '../components/weerp-normal/Pages/AppDocumentation/IOSsetup/ReleaseYourAppStore';
import AdminPanelRequirement from '../components/weerp-normal/Pages/AppDocumentation/Adminpanel/Requirement';
import InstallationProcess from '../components/weerp-normal/Pages/AppDocumentation/Adminpanel/InstallactionProcess';

//use business app
import AppGettingStarted from '../components/weerp-normal/Pages/AppDocumentation/GettingStarted';
import AppBusinessLogin from '../components/weerp-normal/Pages/AppDocumentation/BusinessApp/LoginRegistration';
import AppBusinessDashboard from '../components/weerp-normal/Pages/AppDocumentation/BusinessApp/Dashboard';
import AppBusinessProfile from '../components/weerp-normal/Pages/AppDocumentation/BusinessApp/Profile';
import AppBusinessProduct from '../components/weerp-normal/Pages/AppDocumentation/BusinessApp/Product';
import AppBusinessPos from '../components/weerp-normal/Pages/AppDocumentation/BusinessApp/Pos';
import AppBusinessSales from '../components/weerp-normal/Pages/AppDocumentation/BusinessApp/Sales';
import AppBusinessAccounts from '../components/weerp-normal/Pages/AppDocumentation/BusinessApp/Accounts';
import AppBusinessReports from '../components/weerp-normal/Pages/AppDocumentation/BusinessApp/Reports';
//end use business app

//end app documentation  

import { ProjectList } from '../Enums/ProjectList';  
import PageNotFound from '../components/PageNotFound'; 
import Home from '../components/weerp-normal/Home';
import DefaultPage from '../components/weerp-normal/Pages/AdminPanel/GettingStarted/DefaultPage';

export default function WeErpNormalRoutes({
    activePage, Documentation, defaultAppActiveMenu,
    defaultWebActiveMenu, setDefaultAppActiveMenu, setDocumentation,
    setDefaultWebActiveMenu, setActivePage, sectionRefs, activeSection
}) {


    return (

        <Routes> 
                <Route path={`/${ProjectList.WeerpNormal}`} element={<Home
                    sectionRefs={sectionRefs}
                    activeSection={activeSection}
                    activePage={activePage}
                    documentation={Documentation}
                    setDocumentation={setDocumentation}
                    defaultAppActiveMenu={defaultAppActiveMenu}
                    defaultWebActiveMenu={defaultWebActiveMenu}
                ></Home>}>


            {/* ======================================================= Start web documentation =======================================================*/}

                {/* Getting started  */}
                <Route path='introduction' element={<DefaultPage setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} activeSection={activeSection}></DefaultPage>} />
                <Route path='system-requirement' element={<GettingStarted setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} activeSection={activeSection} ></GettingStarted>}></Route>
                <Route path='configuration' element={<Configuration setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs}  ></Configuration>}></Route>
                <Route path='installation' element={<UploadInstall setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></UploadInstall>}></Route>
                {/* end Getting started  */}

                {/* Authentication  */}
                <Route path='login' element={<Login setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} />}></Route>
                {/* <Route path='business-signup' element={<BusinessSignup setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} />}> </Route> */}
                 <Route path='forgot-password' element={<Forgotpassword setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} />}></Route>
               {/* End Authentication */}
                
                {/* Admin panel */} 
                <Route path='admin/role-employee' element={<AdminRoleEmployee setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></AdminRoleEmployee>}></Route>
                <Route path='admin/general-settings' element={<AdminSettings setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></AdminSettings>}></Route>
            
                <Route path='admin/dashboard' element={<Dashboard setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></Dashboard>}></Route>
                <Route path='admin/profile' element={<BusinessProfile setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></BusinessProfile>}></Route>
                
                <Route path='admin/employees' element={<BusinessEmployees setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></BusinessEmployees>}></Route>
                <Route path='admin/services' element={<BusinessServices setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></BusinessServices>}></Route>
                <Route path='admin/products' element={<BusinessProducts setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></BusinessProducts>}></Route>
                <Route path='admin/purchase' element={<BusinessPurchaseReturn setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></BusinessPurchaseReturn>}></Route>
                <Route path='admin/sales' element={<BusinessSales setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></BusinessSales>}></Route>
                <Route path='admin/pos' element={<BusinessPOS setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></BusinessPOS>}></Route>
                <Route path='admin/stock-transfer' element={<BusinessStockTransfer setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></BusinessStockTransfer>}></Route>
                <Route path='admin/contacts' element={<BusinessContacts setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></BusinessContacts>}></Route>
                <Route path='admin/accounts' element={<BusinessAccounts setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></BusinessAccounts>}></Route>
                <Route path='admin/bulk-import' element={<BusinessBulkImport setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></BusinessBulkImport>}></Route>
                <Route path='admin/hr-management' element={<BusinessHRMangement setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></BusinessHRMangement>}></Route>
                <Route path='admin/todo' element={<BusinessTodoManage setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></BusinessTodoManage>}></Route>
                <Route path='admin/reports' element={<BusinessReports setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></BusinessReports>}></Route>
                <Route path='admin/settings' element={<BusinessSettings setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></BusinessSettings>}></Route>
                {/* End admin panel */}

                {/* branch panel */}
                    <Route path='branch/dashboard' element={<BranchDashboard setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></BranchDashboard>}></Route>
                    <Route path='branch/profile' element={<BranchProfile setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></BranchProfile>}></Route>
                    <Route path='branch/sales' element={<BranchSales setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></BranchSales>}></Route>
                    <Route path='branch/pos' element={<BranchPOS setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></BranchPOS>}></Route>
                    <Route path='branch/need-help' element={<BranchNeedHelp setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></BranchNeedHelp>}></Route>
                    <Route path='branch/accounts' element={<BranchAccounts setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></BranchAccounts>}></Route>
                    <Route path='branch/hr-management' element={<BranchHRM setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></BranchHRM>}></Route>
                    <Route path='branch/reports' element={<BranchReports setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></BranchReports>}></Route>
                
                {/* End branch panel */}

                {/* ======================================================= end web documentation =======================================================*/}

                {/* ======================================================= Start APP documentation =======================================================*/}
                {/* app documentation   */}
                <Route index element={<AppGettingStarted setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></AppGettingStarted>}></Route>
                <Route path='app/recommendation' element={<Recommendation setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></Recommendation>}></Route>
                <Route path='app/installation' element={<Installation setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></Installation>}></Route>
                <Route path='app/prepare-flutter-environment' element={<PrepareFlutterEnvironment setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></PrepareFlutterEnvironment>}></Route>
                <Route path='app/change-api-server-details' element={<ChangeApiServerDetails setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></ChangeApiServerDetails>}></Route>
             
                <Route path='app/change-app-logo' element={<ChangeAppLogo setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></ChangeAppLogo>}></Route>
                <Route path='app/change-app-name' element={<ChangeAppName setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></ChangeAppName>}  ></Route>
                <Route path='app/change-android-luncher-icon' element={<ChangeAndroidLuncherIcon setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></ChangeAndroidLuncherIcon>}></Route>
                <Route path='app/change-android-package-name' element={<ChangeAndroidPackageName setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}  ></ChangeAndroidPackageName>}></Route>
                <Route path='app/generate-debug-apk' element={<GenerateDebugApk setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></GenerateDebugApk>}  ></Route>
                <Route path='app/generate-signed-apk' element={<GenerateSignedApk setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></GenerateSignedApk>} ></Route>
                <Route path='app/preliminaries' element={<Preliminaries setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></Preliminaries>} ></Route>
                <Route path='app/register-your-app' element={<RegisterYourApp setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></RegisterYourApp>}  ></Route>
                <Route path='app/register-build-id' element={<RegisterBuildID setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></RegisterBuildID>}></Route>
                <Route path='app/create-application-record' element={<CreateApplicationRecord setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></CreateApplicationRecord>} ></Route>
                <Route path='app/review-xcode-project' element={<ReviewXcodeProject setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></ReviewXcodeProject>}  ></Route>
                <Route path='app/update-apps-version' element={<UpdateAppVersion setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></UpdateAppVersion>}  ></Route>
                <Route path='app/add-app-icon' element={<AppIcon setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></AppIcon>}  ></Route>
                <Route path='app/build-archive' element={<BuildArchive setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></BuildArchive>}  ></Route>
                <Route path='app/update-build-version-number' element={<UpdateBuildVersionNumber setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></UpdateBuildVersionNumber>}  ></Route>
                <Route path='app/create-app-build' element={<CreateAppBuild setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></CreateAppBuild>} ></Route>
                <Route path='app/testflight' element={<TestFlight setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></TestFlight>}  ></Route>
                <Route path='app/release-your-app-store' element={<ReleaseYourAppStore setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></ReleaseYourAppStore>} ></Route>
                <Route path='app/admin-panel-requirement' element={<AdminPanelRequirement setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></AdminPanelRequirement>}  ></Route>
                <Route path='app/admin-panel-installation-process' element={<InstallationProcess setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></InstallationProcess>}  ></Route>

                {/*use  business app */} 
                <Route path='app/business/login' element={<AppBusinessLogin setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></AppBusinessLogin>}  ></Route>
                <Route path='app/business/dashboard' element={<AppBusinessDashboard setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></AppBusinessDashboard>}  ></Route>
                <Route path='app/business/profile' element={<AppBusinessProfile setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></AppBusinessProfile>} ></Route>
                <Route path='app/business/product' element={<AppBusinessProduct setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></AppBusinessProduct>}  ></Route>
                <Route path='app/business/pos' element={<AppBusinessPos setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></AppBusinessPos>}  ></Route>
                <Route path='app/business/sales' element={<AppBusinessSales setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></AppBusinessSales>}  ></Route>
                <Route path='app/business/accounts' element={<AppBusinessAccounts setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></AppBusinessAccounts>}  ></Route>
                <Route path='app/business/reports' element={<AppBusinessReports setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></AppBusinessReports>}  ></Route>
               
                {/*end use  business app */}
                {/* ======================================================= end APP documentation =======================================================*/}
            </Route> 
            <Route path={`${ProjectList.WeerpNormal}/*`} element={<PageNotFound />} />
        </Routes>

    );
}


