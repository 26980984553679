import React, { useEffect, useContext } from 'react'
import ScrollToTop from './../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import ForgotPasswordImg from './../../../assets/images/module/auth/password-reset.png';
import { ProjectTypeContext } from '../../../ContextHandle';
export default function Forgotpassword({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1007);
        document.title = 'Forgot password | ' + projectName + ' web';
        setDefaultWebActiveMenu('1');
    }, [setActivePage, setDefaultWebActiveMenu, projectName]);


    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">Authentication</li>
                        <li className="breadcrumb-item active" aria-current="page">Forgot password</li>
                    </ol>
                </nav>
                <div id="accounts" ref={sectionRefs['52']}>
                    <h4 className=" font-weight-bold mt-5  ">Forgot password</h4>
                    <p  >  If the login password of the account is forgotten, the merchant can change the password again.Therefore the merchant must know his email address.To reset the password, click on Forgot Password from the login page. In the form that will be displayed, enter the registered email of the merchant and click on the reset link. Now a link will go to that email. If you click on that link, you will get a form to add a new password. If the merchant submits his new password in that form, his password will be reset.Later merchant can login.</p>
                    <div className='pt-3'>
                        <img src={ForgotPasswordImg} className="w-100 rounded" alt="newsoffers.png"></img>
                    </div>
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/merchant-signup`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Merchant Signup</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/admin/dashboard`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Dashboard</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
