import React, { useEffect, useContext } from 'react'
import ScrollToTop from '../../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import POSImg from '../../../../assets/images/weerp/web-image/business/pos.png';
import { ProjectTypeContext } from '../../../../ContextHandle';
export default function POS({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    const panelName = 'Business panel';
    projectType = projectType.projectType;
    /* eslint-disable */
    useEffect(() => {
        setActivePage(1015);
        document.title = 'POS | ' + projectName + ' web';
        setDefaultWebActiveMenu('2');
    }, [projectName]);
    /* eslint-enable */
    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                        <li className="breadcrumb-item active" aria-current="page">POS</li>
                    </ol>
                </nav>
                <div id="fraudcheck" ref={sectionRefs['9']}>
                    <h4 className="mt-5 font-weight-bold">POS</h4>
                    <p>Instant sales and payments can be made through POS. POS sales Invoices can be viewed and printed.</p>
                    <h4 className=" pt-3">POS page:</h4>
                    <div className='pt-3'>
                        <img src={POSImg} className="w-100 rounded" alt="pos.png"></img>
                    </div>

                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/business/sales`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Sales</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/business/stock-transfer`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Stock Transfer</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
