import React, { useEffect, useRef, useContext } from 'react'
import ScrollToTop from '../../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import SalesImg from '../../../../assets/images/weerp/web-image/branch/sales.png'; 
import useScrollSpy from 'react-use-scrollspy';
import { ProjectTypeContext } from '../../../../ContextHandle';
export default function Sales({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1026);
        document.title = 'Sales | ' + projectName + ' web';
        setDefaultWebActiveMenu(3);
    }, [projectName]);

    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });

    return (
        <div className='d-flex justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">Branch Panel</li>
                        <li className="breadcrumb-item active" aria-current="page">Sales</li>
                    </ol>
                </nav>
                <div id="accounts"  >
                    <h4 className="  mt-5 font-weight-bold" id='parcel-list' ref={pagesectionRefs[0]}> Parcels</h4>
                    <p>Sales can be manage and sales payments can be made. invoices can be viewed and invoices can be printed. branch can sales only his branch products. </p>
                    <h4 className='pt-3'   >Sales List</h4>
                    <div className='pt-3'>
                        <img src={SalesImg} className="w-100 rounded" alt="parcels.png"></img>
                    </div>
                  
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/branch/profile`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Profile</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/branch/pos`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>POS</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className='right-menu'>
                <ul>
               
                </ul>
            </div>
        </div>
    )
}
