import React, { useEffect, useContext } from 'react'
import { NavLink } from 'react-router-dom';
import ScrollToTop from '../../../Shared/ScrollToTop';
import PurchaseImg from '../../../../assets/images/weerp/web-image/business/purchase/purchase-list.png';
import PurchaseViewImg from '../../../../assets/images/weerp/web-image/business/purchase/purchase-view.png';
import PaymentViewImg from '../../../../assets/images/weerp/web-image/business/purchase/purchase-payment.png';
import InvoiceViewImg from '../../../../assets/images/weerp/web-image/business/purchase/purchase-invoice.png';
import { ProjectTypeContext } from '../../../../ContextHandle';
export default function PurchaseReturn({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
 /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    const panelName   = 'Admin panel';
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1013);
        document.title = 'Purchase & Return | ' + projectName + ' web';
        setDefaultWebActiveMenu(2);
    }, [projectName]);
    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                        <li className="breadcrumb-item active" aria-current="page">Purchase & Return</li>
                    </ol>
                </nav>
                <div id="parcels" ref={sectionRefs['38']}>
                    <h4 className=" mt-5 font-weight-bold ">Purchase & Return</h4> 

                    <p  >Products can be purchased branch wise. you can receive the products after purchase. Payments of purchase can be made and viewed with payments. purchase invoices can be viewed and invoices can be printed. </p> 
                    <h5 className='mt-3'>Purchase list:</h5> 
                    <div className='pt-3'>
                        <img src={PurchaseImg} className="w-100 rounded" alt="Purchase Lists"></img>
                    </div>
   
                    <h5 className='mt-3'>Purchase view:</h5> 
                    <div className='pt-3'>
                        <img src={PurchaseViewImg} className="w-100 rounded" alt="Purchase view"></img>
                    </div>

                    <h5 className='mt-3'>Purchase payment:</h5> 
                    <div className='pt-3'>
                        <img src={PaymentViewImg} className="w-100 rounded" alt="Purchase payment"></img>
                    </div>
   
                    <h5 className='mt-3'>Purchase Invoice:</h5> 
                    <div className='pt-3'>
                        <img src={InvoiceViewImg} className="w-100 rounded" alt="Purchase invoice"></img>
                    </div>
   
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/admin/products`} className="text-decoration-none">
                            <p className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></p>
                            <h4 className='pagination-title'>Products</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/admin/sales`} className="text-decoration-none">
                            <p href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></p>
                            <h4 className='pagination-title'>Sales</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
