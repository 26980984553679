import React, { useEffect, useRef, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import '../../../../../assets/images/codestyle.css';
import useScrollSpy from 'react-use-scrollspy';
import ScrollToTop from '../../../../Shared/ScrollToTop';
import { ProjectTypeContext } from '../../../../../ContextHandle';
const RegisterYourApp = ({ setActivePage, sectionRefs, setDocumentation, setDefaultAppActiveMenu }) => {
 /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;

    useEffect(() => {
        setActivePage(514);
        setDocumentation(2);
        setDefaultAppActiveMenu(4);
        document.title = 'Register your app on App Store Connect | ' + projectName;
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);


    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });

    return (
        <div className='d-flex   justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div id="system-requirements  " className='pt-5 right-content-box-width' >
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">iOS Setup</li>
                        <li className="breadcrumb-item active" aria-current="page">Register your app on App Store Connect</li>
                    </ol>
                </nav>

                <div >
                    <h3 className="pt-5 font-weight-bold" >Register your app on App Store Connect</h3>
                    <ul>
                        <li>Manage your app's life cycle on <a rel="noreferrer" target="_blank" href='https://developer.apple.com/support/app-store-connect/'>App Store Connect</a> {`(`}<b>formerly iTunes Connect</b>{`)`}. You define your app name and description, add screenshots, set pricing, and manage releases to the App Store and TestFlight.</li>
                        <li>Registering your app involves two steps: registering a unique Bundle ID, and creating an application record on App Store Connect.</li>
                        <li>For a detailed overview of <a rel="noreferrer" target="_blank" href='https://developer.apple.com/support/app-store-connect/'>App Store Connect</a>, see the App Store Connect guide.</li>
                    </ul>
                </div>


                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/app/preliminaries`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Preliminaries</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/app/register-build-id`} className="text-decoration-none">
                            <a href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Register a Bundle ID</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>

    );

};

export default RegisterYourApp;
