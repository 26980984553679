import React, { useEffect, useContext } from 'react'
import ScrollToTop from './../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import shopsImg from './../../../assets/images/module/merchant-panel/settings/shops.png';
import AddShopsImg from './../../../assets/images/module/merchant-panel/settings/shop-create.png';
import { ProjectTypeContext } from '../../../ContextHandle';
export default function Shops({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1028);
        document.title = 'Pickup points | ' + projectName + ' web';
        setDefaultWebActiveMenu(3);
    }, [projectName]);
    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">Merchant Panel</li>
                        <li className="breadcrumb-item active" aria-current="page">Pickup Points</li>
                    </ol>
                </nav>
                <div id="accounts" ref={sectionRefs['30']}>

                    <h4 className="  mt-5 font-weight-bold">Pickup Points</h4>
                    <p >A merchant can have many Pickup points. And all stores can be added from the store module. A form will be displayed by clicking on the add icon from the list of shop modules to add a shop. And after filling this form and clicking submit button the store will be added. Can be edited, and deleted from store list.</p>
                    <h4 className='pt-3'>Pickup points list</h4>
                    <div className='pt-3'>
                        <img src={shopsImg} className="w-100 rounded" alt="shops.png"></img>
                    </div>
                    <h4 className='pt-3'>Add Pickup point</h4>
                    <div className='pt-3'>
                        <img src={AddShopsImg} className="w-100 rounded" alt="shops.png"></img>
                    </div>
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/merchant/accounts`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Accounts</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/merchant/parcels`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Parcels</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
