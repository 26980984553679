import React, { useEffect, useContext } from 'react'
import ScrollToTop from './../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import todoListImg from './../../../assets/images/module/admin-panel/todo-list/todo-list.png';
import AddtodoImg from './../../../assets/images/module/admin-panel/todo-list/todo-list-create.png';
import { ProjectTypeContext } from '../../../ContextHandle';
export default function TodoList({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    const panelName   = projectType.panelName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1013);
        document.title = 'Todo list | ' + projectName + ' web';
        setDefaultWebActiveMenu(2);
    }, [projectName]);
    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`} ><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                        <li className="breadcrumb-item active" aria-current="page">Todo List</li>
                    </ol>
                </nav>
                <div id="todolist" ref={sectionRefs['13']}>

                    <h4 className="font-weight-bold mt-5 ">Todo List</h4>
                    <p  >List, edit, delete and change status of todo through Todo module. Clicking on Todo from the navigation bar will display a modal for who created the todo. The modal contains inputs for title, assign user, date and description. Once these are filled and submitted, the todo will be created.</p>
                    <h4 className=" pt-3">Todo List</h4>
                    <div className='pt-3'>
                        <img src={todoListImg} className="w-100 rounded" alt="TodoList.png"></img>
                    </div>
                    <h4 className=" pt-3">Add Todo</h4>
                    <div className='pt-3'>
                        <img src={AddtodoImg} className="w-100 rounded" alt="addTodo.png"></img>
                    </div>
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/merchant-manage`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Merchant Manage</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/support`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Ticket</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
