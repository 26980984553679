import React, { useEffect, useRef, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import '../../../../../assets/images/codestyle.css';
import useScrollSpy from 'react-use-scrollspy';
import ScrollToTop from '../../../../Shared/ScrollToTop';
import XcodeSettings from '../../../../../assets/images/app-image/environment-setup/xcode_settings.png';
import { ProjectTypeContext } from '../../../../../ContextHandle';
const ReviewXcodeProject = ({ setActivePage, sectionRefs, setDocumentation, setDefaultAppActiveMenu }) => {
 /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;

    useEffect(() => {
        setActivePage(517);
        setDocumentation(2);
        setDefaultAppActiveMenu(4);
        document.title = 'Review Xcode project settings | ' + projectName;;
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);


    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });

    return (
        <div className='d-flex   justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div id="system-requirements  " className='pt-5 right-content-box-width' >
                <div id='xcode' ref={pagesectionRefs[1]}>
                    <nav  >
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                            <li className="breadcrumb-item" aria-current="page">iOS Setup</li>
                            <li className="breadcrumb-item active" aria-current="page">Review Xcode project settings</li>
                        </ol>
                    </nav>

                    <h3 className="pt-5 font-weight-bold" >Review Xcode project settings</h3>
                    <p>This step covers reviewing the most important settings in the Xcode workspace. For detailed procedures and descriptions, see <a rel="noreferrer" href='https://help.apple.com/xcode/mac/current/#/dev91fe7130a' target="_blank">Prepare for app distribution</a>.</p>
                    <h4 className='font-weight-bold'>Navigate to your target's settings in Xcode:</h4>
                    <ol>
                        <li className='py-2'>Open the default Xcode workspace in your project by running this command in a terminal window from your Flutter project directory.
                            <div className='mt-2'>
                                <code>
                                    <pre>
                                        open ios/Runner.xcworkspace
                                    </pre>
                                </code>
                            </div>
                        </li>
                        <li className='py-2'>To view your app’s settings, select the <b>Runner target</b> in the Xcode navigator.</li>
                    </ol>
                </div>

                <div id='verify' ref={pagesectionRefs[2]}>
                    <h4 className='font-weight-bold'>Verify the most important settings.</h4>
                    <b>In the Identity section:</b>

                    <li className=' px-2'><b>Display Name </b>
                        <ol>
                            <li>The display name of your app.</li>
                        </ol>
                    </li>
                    <li>
                        <b>Bundle Identifier </b>
                        <ol>
                            <li>The App ID you registered on App Store Connect.</li>
                        </ol>

                    </li>
                    <b>In the Signing section:</b>
                    <li className='mt-2'> <b>Automatically manage signing </b>
                        <ol>
                            <li> Whether Xcode should automatically manage app signing and provisioning. This is set true by default, which should be sufficient for most apps. For more complex scenarios, see the <a rel="noreferrer" href='https://developer.apple.com/library/content/documentation/Security/Conceptual/CodeSigningGuide/Introduction/Introduction.html' target="_blank">Code Signing Guide</a>.</li>
                        </ol>

                    </li>

                    <p>
                        <b>Team </b>
                        <ol>
                            <li>Select the team associated with your registered Apple Developer account. If required, select Add Account…, then update this setting.</li>
                        </ol>
                    </p>
                    <p> <b>In the Deployment section of the Build Settings tab</b>  </p>

                    <li> <b>iOS Deployment Target:</b>
                        <ol>
                            <li>  The minimum iOS version that your app supports. Flutter supports iOS 8.0 and later. If your app includes Objective-C or Swift code that makes use of APIs that were unavailable in iOS 8, update this setting appropriately.</li>
                        </ol>
                    </li>
                    <p>The <b>General</b> tab of your project settings should resemble the following:</p>

                    <div className='py-3'>
                        <img src={XcodeSettings} className="w-100 rounded" alt="XcodeSttings.png "></img>
                    </div>
                </div>



                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/app/create-application-record`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Create an application record on App Store Connect</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/app/update-apps-version`} className="text-decoration-none">
                            <a href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Updating the app’s deployment version</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className='right-menu'>
                <ul >
                    <li><a href='#step1' className={activeSection == 1 ? 'active-has' : ""}>Navigate to your target’s settings in Xcode</a></li>
                    <li><a href='#step2' className={activeSection == 2 ? 'active-has' : ""}>Verify the most important settings.</a></li>
                </ul>
            </div>

        </div>

    );

};

export default ReviewXcodeProject;
