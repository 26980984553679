import React, { useContext } from 'react'
import ScrollToTop from './../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import salaryGenerate from './../../../assets/images/module/admin-panel/payroll/salary-generate-list.png';
import Generate from './../../../assets/images/module/admin-panel/payroll/salary-generate-create.png';
import Salary from './../../../assets/images/module/admin-panel/payroll/salary-list.png';
import addSalary from './../../../assets/images/module/admin-panel/payroll/salary-create.png';
import { ProjectTypeContext } from '../../../ContextHandle';
export default function Payroll({ setActivePage, sectionRefs }) {
     /* eslint-disable */
    setActivePage('1');
    var projectType = useContext(ProjectTypeContext);
    const panelName   = projectType.panelName;
    projectType = projectType.projectType;

    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                        <li className="breadcrumb-item active" aria-current="page">Payroll</li>
                    </ol>
                </nav>
                <div id="payroll" ref={sectionRefs['19']}>
                    <h4 className="  mt-5  font-weight-bold">Payroll</h4>
                    <a href='#salary-generate' className='text-primary font-weight-bold'># Salary Generate</a><br />
                    <a href='#salary' className='text-primary font-weight-bold'># Salary</a><br />
                    <p>Salaries can be generated and paid to employees through the Payroll module.</p>
                    <div id='salary-generate'>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Salary Generate</h5>
                        <p className="pt-2">In order to pay the salary to the employees, first the salary of the month in which the salary is to be paid must be generated for all the employees. To generate, click on generate salary module and you can add salary for an employee if you want. And so click on add button and select the worker and click on submit button.And if you want to generate salary for all employees. Then click on the Auto Generate button and select the month and click on the submit button, the salary will be generated for all the employees.</p>
                        <h4 className=" pt-3">Salary generate list</h4>
                        <div className='pt-3'>
                            <img src={salaryGenerate} className="w-100 rounded" alt="salarygenerate.png"></img>
                        </div>
                        <h4 className=" pt-3">Create Salary Generate </h4>
                        <div className='pt-3'>
                            <img src={Generate} className="w-100 rounded" alt="CreateSalaryGenerate.png"></img>
                        </div>
                    </div>
                    <div id='salary'>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Salary</h5>
                        <p className="pt-2">After generating salary when salary is to be paid to employees then click on salary module and click on add button to pay salary then salary payment form will be displayed. And if you select the month, you will see the amount generated in that month. And click submit button in the amount of money.</p>
                        <h4 className=" pt-3">Salary List</h4>
                        <div className='pt-3'>
                            <img src={Salary} className="w-100 rounded" alt="Salary.png"></img>
                        </div>
                        <h4 className=" pt-3">Add Salary</h4>
                        <div className='pt-3'>
                            <img src={addSalary} className="w-100 rounded" alt="addSalary.png"></img>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/system-requirement`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Requirement</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/reports`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Reports</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
