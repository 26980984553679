import React, { useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import '../../../../assets/images/codestyle.css';
import ScrollToTop from '../../../Shared/ScrollToTop';
import ParcelList from '../../../../assets/images/app-image/merchant/parcel-list.png';
import ParcelCreate from '../../../../assets/images/app-image/merchant/parcel-create.png';
import { ProjectTypeContext } from '../../../../ContextHandle';
const Shops = ({ setActivePage, setDocumentation, setDefaultAppActiveMenu }) => {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(108);
        setDocumentation(2);
        document.title = "Parcels | " + projectName;
        setDefaultAppActiveMenu(6);
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);

    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className='right-content-box-width'>
                <div id="system-requirements pt-5" >
                    <h4 className="pt-5">Parcels</h4>
                    <h4 className="py-3">Parcel List :</h4>
                    <div>
                        <img src={ParcelList} className="w-75 rounded" alt="Parcel list" />
                    </div>
                    <h4 className="py-3">Parcel Create :</h4>
                    <div>
                        <img src={ParcelCreate} className="w-75 rounded" alt="Parcel create" />
                    </div>
                    <div className="d-flex justify-content-between border-top mt-5">
                        <div className="text-start mt-4">
                            <NavLink to={`/${projectType}/app/merchant/shops`} className="text-decoration-none">
                                <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                                <h4 className='pagination-title'>Shops</h4>
                            </NavLink>
                        </div>
                        <div className="text-end my-2">
                            <NavLink to={`/${projectType}/app/merchant/fraud-check`} className="text-decoration-none">
                                <a href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                                <h4 className='pagination-title'>Fraud Check</h4>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    );

};

export default Shops;
