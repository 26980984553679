import React, { useContext } from 'react';
import { Nav } from 'react-bootstrap';
import '../../Shared/Navbar.css'
import { ProjectTypeContext } from '../../../ContextHandle';
const Navbars = ({ activePage, defaultWebActiveMenu }) => {
    var projectType = useContext(ProjectTypeContext);
    projectType = projectType.projectType;
    return (

        <nav id="sidebarMenu" className="navbar  d-lg-block sidebar  bg-white p-3 pb-0">

            <div className="text-dark">
                {/* Getting started  */}
                <ul className='p-0 m-0'>
                    <li className='menu-item  mb-0 mt-2'>
                        <button type='button' data-bs-toggle="collapse" data-bs-target="#started" aria-controls="started" aria-expanded={defaultWebActiveMenu == 0 ? 'true' : 'false'} aria-label="Toggle navigation"><h5 className="text-dark font-weight-bold "> Getting Started</h5></button>
                        <div className={`collapse navbar-collapse ${defaultWebActiveMenu == 0 ? `show` : ''}`} id="started">

                            <ul className="sub-menu" >
                               
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/introduction`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1001 ? "active-hash" : ''}`}
                                    >
                                        Introduction
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/system-requirement`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1002 ? "active-hash" : ''}`}
                                    >
                                        Requirement
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/installation`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1003 ? "active-hash" : ''}`}
                                    >
                                        Installation
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/fcm-push-notification`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1004 ? "active-hash" : ''}`}
                                    >
                                        FCM Push Notification
                                    </Nav.Link>
                                </li>
                            </ul>
                        </div>
                    </li>


                    <li className='menu-item  mb-0 mt-2'>
                        <button type='button' data-bs-toggle="collapse" data-bs-target="#auth" aria-controls="auth" aria-expanded={defaultWebActiveMenu == 1 ? 'true' : 'false'} aria-label="Toggle navigation"><h5 className="text-dark font-weight-bold ">Authentication</h5> </button>
                        <div className={`collapse navbar-collapse ${defaultWebActiveMenu == 1 ? `show` : ''}`} id="auth">
                            <ul className="sub-menu">
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/login`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1005 ? "active-hash" : ''}`}
                                    >
                                        login
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/company-signup`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1106 ? "active-hash" : ''}`}
                                    >
                                        Company Signup
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/merchant-signup`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1006 ? "active-hash" : ''}`}
                                    >
                                        Merchant Signup
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/forgot-password`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1007 ? "active-hash" : ''}`}
                                    >
                                        Forgot password
                                    </Nav.Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li className='menu-item  mb-0 mt-2'>
                        <button type='button' data-bs-toggle="collapse" data-bs-target="#adminpanel" aria-controls="adminpanel" aria-expanded={defaultWebActiveMenu == 4 ? 'true' : 'false'} aria-label="Toggle navigation"><h5 className="text-dark font-weight-bold ">Admin Panel</h5> </button>
                        <div className={`collapse navbar-collapse ${defaultWebActiveMenu == 4 ? `show` : ''}`} id="adminpanel">
                            <ul className="sub-menu">
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/admin/dashboard`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 2001 ? "active-hash" : ''}`}
                                    >
                                        Dashboard
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/admin/companies`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 2002 ? "active-hash" : ''}`}
                                    >
                                        Companies
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/admin/plans`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 2003 ? "active-hash" : ''}`}
                                    >
                                        Plans
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/admin/subscriptions`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 2004 ? "active-hash" : ''}`}
                                    >
                                        Subscriptions
                                    </Nav.Link>
                                </li>

                            </ul>
                        </div>
                    </li>
                    <li className='menu-item  mb-0 mt-2'>
                        <button type='button' data-bs-toggle="collapse" data-bs-target="#companypanel" aria-controls="companypanel" aria-expanded={defaultWebActiveMenu == 2 ? 'true' : 'false'} aria-label="Toggle navigation"><h5 className="text-dark font-weight-bold ">Company Panel</h5> </button>
                        <div className={`collapse navbar-collapse ${defaultWebActiveMenu == 2 ? `show` : ''}`} id="companypanel">
                            <ul className="sub-menu">
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/dashboard`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1008 ? "active-hash" : ''}`}
                                    >
                                        Dashboard
                                    </Nav.Link>
                                </li>


                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/profile`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1009 ? "active-hash" : ''}`}
                                    >
                                        Profile
                                    </Nav.Link>
                                </li>

                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/deliveryman`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1010 ? "active-hash" : ''}`}
                                    >
                                        Delivery Man
                                    </Nav.Link>
                                </li>

                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/hub-manage`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1011 ? "active-hash" : ''}`}
                                    >
                                        Branch Manage
                                    </Nav.Link>
                                </li>

                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/merchant-manage`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1012 ? "active-hash" : ''}`}
                                    >
                                        Merchant Manage
                                    </Nav.Link>
                                </li>

                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/todo-list`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1013 ? "active-hash" : ''}`}
                                    >
                                        Todo List
                                    </Nav.Link>
                                </li>

                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/support`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1014 ? "active-hash" : ''}`}
                                    >
                                        Ticket
                                    </Nav.Link>
                                </li>


                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/parcels`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1015 ? "active-hash" : ''}`}
                                    >
                                        Parcels
                                    </Nav.Link>
                                </li>


                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/payout`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1016 ? "active-hash" : ''}`}
                                    >
                                        Payout
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/accounts`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1017 ? "active-hash" : ''}`}
                                    >
                                        Accounts
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/users-roles`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1018 ? "active-hash" : ''}`}
                                    >
                                        Users & Roles
                                    </Nav.Link>
                                </li>

                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/reports`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1019 ? "active-hash" : ''}`}
                                    >
                                        Reports
                                    </Nav.Link>
                                </li>


                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/push-notification`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1020 ? "active-hash" : ''}`}
                                    >
                                        Push Notification
                                    </Nav.Link>
                                </li>

                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/addons`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 10200 ? "active-hash" : ''}`}
                                    >
                                        Addons
                                    </Nav.Link>
                                </li>


                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/settings`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1021 ? "active-hash" : ''}`}
                                    >
                                        Settings
                                    </Nav.Link>
                                </li>
                            </ul>
                        </div>
                    </li>


                    <li className='menu-item  mb-0 mt-2'>
                        <button type='button' data-bs-toggle="collapse" data-bs-target="#merchantpanel" aria-controls="merchantpanel" aria-expanded={defaultWebActiveMenu == 3 ? 'true' : 'false'} aria-label="Toggle navigation"><h5 className="text-dark font-weight-bold ">Merchant Panel</h5> </button>
                        <div className={`collapse navbar-collapse ${defaultWebActiveMenu == 3 ? `show` : ''}`} id="merchantpanel">
                            <ul className="sub-menu">
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/merchant/dashboard`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1022 ? "active-hash" : ''}`}
                                    >
                                        Dashboard
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/merchant/profile`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1023 ? "active-hash" : ''}`}
                                    >
                                        Profile
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/merchant/payment-information`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1024 ? "active-hash" : ''}`}
                                    >
                                        Payment Information
                                    </Nav.Link>
                                </li>

                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/merchant/pickup-request`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1025 ? "active-hash" : ''}`}
                                    >
                                        Pickup Request
                                    </Nav.Link>
                                </li>

                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/merchant/support`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1026 ? "active-hash" : ''}`}
                                    >
                                        Ticket
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/merchant/accounts`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1027 ? "active-hash" : ''}`}
                                    >
                                        Accounts
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/merchant/shops`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1028 ? "active-hash" : ''}`}
                                    >
                                        Pickup Points
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/merchant/parcels`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1029 ? "active-hash" : ''}`}
                                    >
                                        Parcels
                                    </Nav.Link>
                                </li>

                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/merchant/reports`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1030 ? "active-hash" : ''}`}
                                    >
                                        Reports
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/merchant/settings`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1031 ? "active-hash" : ''}`}
                                    >
                                        Settings
                                    </Nav.Link>
                                </li>
                            </ul>
                        </div>
                    </li>


                </ul>

            </div>
        </nav>
    );

};

export default Navbars;

