import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { Nav } from 'react-bootstrap';
import './assets/css/Style.css';
import WecourierLogo from './assets/item-logo/wecourier/wecourier-thumbnail.png'
import WecourierInlineImage from './assets/item-logo/wecourier/wecourier-inline-preview-image.png'
import WecourierSaasLogo from './assets/item-logo/wecourier-saas/wecourier-saas-thumbnail.png'
import WecourierSaasImage from './assets/item-logo/wecourier-saas/wecourier-saas-inline-preview-image.png'
import WeerpInlinePreviewImage from './assets/item-logo/weerp/weerp-inline-preview-image.png';
import WeerpNormalInlinePreviewImage from './assets/item-logo/weerp-normal/weerp-inline-preview-image.png';
import WeerpFavicon from './assets/item-logo/weerp/weerp-thumbnail.png';
import WeerpNormalFavicon from './assets/item-logo/weerp-normal/weerp-thumbnail.png';
import FaviconDynamic from './components/FaviconDynamic';
import WemoverFavicon from './assets/item-logo/wemover/wemover-thumbnail.png';
import WemoverInlinePreviewImage from './assets/item-logo/wemover/wemover-inline-preview.png';
const WelcomeIndex = () => {
   /* eslint-disable */
  useEffect(() => {
    document.title = 'Documentation | WemaxDevs'; 
  }, []);
  
  return (
    <div className='doc-home-page'>
      <FaviconDynamic/>
      <section className="container-fluid  p-0">
        <div className="alert-inner bg-primary text-center text-white font-weight-600">
          <div className="contaner">Buy item and then learn documentation</div>
        </div>
      </section>
      {/* navigation   */}
      <section className="container-fluid section-bg bg-dots">
        <nav className="navbar navbar-expand-lg center-nav transparent navbar-light p-3 pt-0">
          <div className="container flex-lg-row flex-nowrap align-items-center">
            <div className="navbar-brand w-100">
              <Nav.Link href="/">
                <img src={`./brand-logo.png`} width="200" alt="Logo"></img>
              </Nav.Link>
            </div>
            <div className="navbar-collapse offcanvas offcanvas-nav offcanvas-start text-bg-dark " 
              id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
              <div className="offcanvas-header w-90 ">
                <h3 className="text-white fs-30 mb-0 brand-title"> WemaxDevs</h3>
                <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas"
                  aria-label="Close"></button>
              </div>
              <div className="offcanvas-body ms-lg-auto d-flex flex-column h-100 w-90">
                <ul className="navbar-nav">
                  <li className="nav-item"><Nav.Link  href="">Home</Nav.Link></li>
                  <li className="nav-item"><Nav.Link   target="_blank"
                    href="https://demo.wemaxdevs.com">Demo</Nav.Link></li>
                  <li className="nav-item"><Nav.Link  target="_blank"
                    href="https://tawk.to/wemaxDevs">Support</Nav.Link></li>
                </ul>
                <div className="offcanvas-footer d-lg-none mt-3">
                  <div>
                     wedevs002@gmail.com 
                    <br /> +880 1912938002 <br />
                    <nav className="nav social social-white mt-4">
                      <Nav.Link href=""><i className="fa-brands fa-twitter text-white"></i></Nav.Link>
                      <Nav.Link href=""><i className="fa-brands fa-facebook text-white"></i></Nav.Link>
                      <Nav.Link href=""><i className="fa-brands fa-youtube text-white"></i></Nav.Link>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="navbar-other w-100 d-flex ms-auto">
              <ul className="navbar-nav flex-row align-items-center ms-auto">
                <li className="nav-item d-none d-md-block">
                  <Nav.Link href="https://codecanyon.net/user/wemaxdevs/portfolio"
                    className="text-white btn btn-primary rounded-pill " target="_blank"  >Buy Now</Nav.Link>
                </li>
                <li className="nav-item d-lg-none">
                  <button className="offcanvas-nav-btn" type="button" data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar"><span
                      className="navbar-toggler-icon"></span></button>
                </li>
              </ul>
            </div>

          </div>
        </nav>
      </section>
      {/* // navigation   */}
      <section className="container-fluid p-0 pb-5 section-bg bg-dots">
        <div className="container pt-5 pb-5 ">
          <div className="row">
            <div className="col-lg-6">
              <h1 className="display-1">Buy now hassle free complete web, Android and IOS application<br />
                <span className="text-primary typer"></span>
              </h1>
              <p className="fs-4 mt-4">
                WemaxDevs provides software development services worldwide. We develop high quality and secured applications in the area of web and mobile for individual and corporate business.
              </p>
            </div>
            <div className="col-lg-6">
              <img src={'./banner.png'} style={{ width: '100%' }}></img>
            </div>

          </div>
        </div>
      </section>

      {/* <!-- key features --> */}
      <section className="container-fluid bg-light py-5 pt-5 pb-0  ">
        <div className="container pt-5">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="fs-15 text-uppercase text-primary text-center mb-3">Documentation</h2>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid bg-light  py-3   key-features  ">
        <div className="container  mb-0">

          <div className="row ">
            <div className="col-xl-4  col-lg-6 col-md-6 mt-4">
              <NavLink to={'wecourier'} href="" className="card-link">
                <div className="card   p-2">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="mr-15px">
                        <span className="key-profile bg-danger">
                          <img src={WecourierLogo} alt='Wecourier-saas-logo.png' width={'100%'} style={{ borderRadius: '10px' }}></img>
                        </span>
                      </div>
                      <div>
                        <div className="mb-2"><span className="badge badge-outline-danger">Normal</span></div>
                        <h4>We Courier</h4>
                        <p>Documentation.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
            <div className="col-xl-4  col-lg-6 col-md-6 mt-4">
              <NavLink to={'wecourier-saas'} className="card-link">
                <div className="card   p-2">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="mr-15px">
                        <span className="key-profile bg-danger">
                          <img src={WecourierSaasLogo} alt='Wecourier-saas-logo.png' width={'100%'} style={{ borderRadius: '10px' }}></img>
                        </span>
                      </div>
                      <div>
                        <div className="mb-2"><span className="badge badge-outline-danger">SAAS</span></div>
                        <h4>We Courier SAAS</h4>
                        <p>Documentation.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>


            <div className="col-xl-4  col-lg-6 col-md-6 mt-4">
              <NavLink to={'weerp'} className="card-link">
                <div className="card   p-2">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="mr-15px">
                        <span className="key-profile bg-danger">
                          <img src={WeerpFavicon} alt='Wecourier-saas-logo.png' width={'100%'} style={{ borderRadius: '10px' }}></img>
                        </span>
                      </div>
                      <div>
                        <div className="mb-2"><span className="badge badge-outline-danger">SAAS</span></div>
                        <h4>We ERP {`(SAAS)`}</h4>
                        <p>Documentation.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>

            <div className="col-xl-4  col-lg-6 col-md-6 mt-4">
              <NavLink to={'weerp-normal'} className="card-link">
                <div className="card   p-2">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="mr-15px">
                        <span className="key-profile bg-white"  >
                          <img src={WeerpNormalFavicon} alt='Wecourier-saas-logo.png' width={'100%'} style={{ borderRadius: '10px' }}></img>
                        </span>
                      </div>
                      <div>
                        <div className="mb-2"><span className="badge badge-outline-danger">Normal</span></div>
                        <h4>We ERP</h4>
                        <p>Documentation.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>

            <div className="col-xl-4  col-lg-6 col-md-6 mt-4">
              <NavLink to={'wemover'} className="card-link">
                <div className="card   p-2">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="mr-15px">
                        <span className="key-profile bg-white"  >
                          <img src={WemoverFavicon} alt='Wecourier-saas-logo.png' width={'100%'} style={{ borderRadius: '10px' }}></img>
                        </span>
                      </div>
                      <div>
                        <div className="mb-2"><span className="badge badge-outline-danger">Normal</span></div>
                        <h4>We Mover</h4>
                        <p>Documentation.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>

            <div className="col-xl-4  col-lg-6 col-md-6 mt-4">
              <NavLink  to={'#'}  className="card-link">
                <div className="card   p-2">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="mr-15px">
                        <span className="key-profile bg-info">cs</span>
                      </div>
                      <div>
                        <div className="mb-2"><span className="badge badge-outline-info">Item</span></div>
                        <h4>Coming soon</h4>
                        <p>Many item will be coming early.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>

          </div>
        </div>
      </section>


      {/* <!-- key features --> */}
      <section className="container-fluid bg-light  pt-0 pb-0  ">
        <div className="container pt-5">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="fs-15 text-uppercase text-primary text-center mb-3">Our Items</h2>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid bg-light  py-3   key-features  ">
        <div className="container pb-5 mb-5">

          <div className="row ">

            <div className="col-lg-12 mt-4">
              <Nav.Link href='https://codecanyon.net/item/we-courier-courier-and-logistics-management-cms-with-merchantdelivery-app/42712610' className="card-link">
                <div className="card   p-2">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-6">
                        <img src={WecourierInlineImage} alt='Wecourier-saas-logo.png' style={{ borderRadius: '10px', width: '100%' }}></img>
                      </div>
                      <div className='col-lg-6'>
                        <h2 className='font-weight-bold mt-3 mt-lg-0'>We Courier - Courier and logistics management CMS with Merchant,Delivery app</h2>
                        <p>Wecourier is a complete parcel delivery solution for the most common courier platforms. It has a lot of build-in futures which are needed for every courier management site. It is designed for those who want to start their courier business or parcel delivery website. There are many courier scripts, many of them are very expensive, and have very complex interfaces for admin and user. Wecourier is designed with user friendly interface and simple interface. Any non-technical person can use this software.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Nav.Link>
            </div>

            <div className="col-lg-12 mt-4">
              <Nav.Link href='https://codecanyon.net/item/we-courier-saas-multitenancy-courier-and-logistics-management-merchant-delivery-app-with-admin/51166784' className="card-link">
                <div className="card   p-2">
                  <div className="card-body">
                    <div className="row align-items-start">
                      <div className="col-lg-6">
                        <img src={WecourierSaasImage} alt='Wecourier-saas-logo.png' style={{ borderRadius: '10px', width: '100%' }}></img>
                      </div>
                      <div className='col-lg-6'>
                        <h2 className='font-weight-bold mt-3 mt-lg-0'>We Courier SAAS - Multi-Tenancy courier and logistics management - merchant, delivery app with admin panel</h2>
                        <p>WeCourier (SAAS) is a complete multi-tenancy (sub-domain) wise parcel delivery solution for the most common courier platforms. It has a lot of build-in futures which are needed for every courier management site. It is designed for those who want to start their courier business or parcel delivery website. There are many courier scripts, many of them are very expensive, and have very complex interfaces for admin and user. Wecourier (SAAS) is designed with user friendly interface and simple interface. Any non-technical person can use this software.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Nav.Link>
            </div>


            <div className="col-lg-12 mt-4">
              <Nav.Link href='https://codecanyon.net/item/weerp-business-or-company-management-solution-with-pos-saas-including-web-panel/52691652' className="card-link">
                <div className="card   p-2">
                  <div className="card-body">
                    <div className="row align-items-start">
                      <div className="col-lg-6">
                        <img src={WeerpInlinePreviewImage} alt='Wecourier-saas-logo.png' style={{ borderRadius: '10px', width: '100%' }}></img>
                      </div>
                      <div className='col-lg-6'>
                        <h2 className='font-weight-bold mt-3 mt-lg-0'>WeERP - Business or company management solution with POS (SaaS)</h2>
                        <p>WeERP is a complete business management solution for the most common business platforms. It has a lot of build-in futures which are needed for every business management site. It is designed for those who want to start their business manage or product sale website. There are many ERP scripts, many of them are very expensive, and have very complex interfaces for admin and user. WeERP is designed with user friendly interface and simple interface. Any non-technical person can use this software.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Nav.Link>
            </div>


            <div className="col-lg-12 mt-4">
              <Nav.Link href='https://codecanyon.net/item/weerp-business-or-company-management-solution-with-pos/54375976' className="card-link">
                <div className="card   p-2">
                  <div className="card-body">
                    <div className="row align-items-start">
                      <div className="col-lg-6">
                        <img src={WeerpNormalInlinePreviewImage} alt='Wecourier-saas-logo.png' style={{ borderRadius: '10px', width: '100%' }}></img>
                      </div>
                      <div className='col-lg-6'>
                        <h2 className='font-weight-bold mt-3 mt-lg-0'>WeERP - Business or company management solution with POS (Normal)</h2>
                        <p>WeERP is a complete business management solution for the most common business platforms. It has a lot of build-in futures which are needed for every business management site. It is designed for those who want to start their business manage or product sale website. There are many ERP scripts, many of them are very expensive, and have very complex interfaces for admin and user. WeERP is designed with user friendly interface and simple interface. Any non-technical person can use this software.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Nav.Link>
            </div>


            <div className="col-lg-12 mt-4">
              <Nav.Link href='https://codecanyon.net/user/wemaxdevs/portfolio' className="card-link">
                <div className="card   p-2">
                  <div className="card-body">
                    <div className="row align-items-start">
                      <div className="col-lg-6">
                        <img src={WemoverInlinePreviewImage} alt='Wecourier-saas-logo.png' style={{ borderRadius: '10px', width: '100%' }}></img>
                      </div>
                      <div className='col-lg-6'>
                        <h2 className='font-weight-bold mt-3 mt-lg-0'>WeMover-On demand vehicles rent | logistics | shifting hassle free goods delivery system with web</h2>
                        <p>WeMover is a complete goods delivery management solution for the most common business platforms. It has a lot of build-in futures which are needed for every goods moving management site. It is designed for those who want to start their goods delivery website. There are many delivery scripts, many of them are very expensive, and have very complex interfaces for admin and user. WeMover is designed with user friendly interface and simple interface. Any non-technical person can use this software.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Nav.Link>
            </div>


            <div className="col-12 mt-4">
              <Nav.Link className="card-link">
                <div className="card   p-2">
                  <div className="card-body">

                    <div>
                      <h4>Coming soon...</h4>
                      <p>Many item will be coming early.</p>
                    </div>

                  </div>
                </div>
              </Nav.Link>
            </div>

          </div>
        </div>
      </section>

      <footer className="container-fluid bg-dark">
        <div className="container text-center">
          <div className="py-5   ">
            <div className="row">
              <div className="col-lg-7 m-auto">
                <div className="display-6 title text-white mt-5" style={{ color: 'white!important' }}>Manage simply, Grow faster business. Bring
                  your business online with WemaxDevs.</div>
                <div className="text-white fs-1-5 mt-3">Fastest platform with all software service features. Help you
                  start, run and grow your business service.</div>
                <Nav.Link href="https://codecanyon.net/user/wemaxdevs/portfolio" target="_blank" className="btn btn-primary rounded-pill buy mt-5" style={{ display:'inline-block',color:'#ffff!important' }}>Buy Now</Nav.Link>
              </div>
            </div>
          </div>
          <img className="img-fluid" src={`./logo-white.png`} style={{ width: "250px" }} ></img>
        </div>
      </footer>
      <footer className="container-fluid bg-dark">
        <div className="container text-center">
          <div className="py-2   ">
            <p className="text-white py-3 mb-0">Copyright © All rights reserved. Development by <a className='text-white' href='https://wemaxdevs.com'>WemaxDevs</a></p>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default WelcomeIndex;