import React, { useContext } from 'react';
import { Nav } from 'react-bootstrap';
import '../Shared/Navbar.css'
import { ProjectTypeContext } from '../../../ContextHandle';
const Navbars = ({ activePage, defaultWebActiveMenu }) => {
    var projectType = useContext(ProjectTypeContext);
    projectType = projectType.projectType;
 
    return (

        <nav id="sidebarMenu" className="navbar  d-lg-block sidebar  bg-white p-3 pb-0">

            <div className="text-dark">
                {/* Getting started  */}
                <ul className='p-0 m-0'>
                    <li className='menu-item mb-0 mt-2'>
                        <button type='button' data-bs-toggle="collapse" data-bs-target="#started" aria-controls="started" aria-expanded={defaultWebActiveMenu == 0 ? 'true' : 'false'} aria-label="Toggle navigation"><h5 className="text-dark font-weight-bold "> Getting Started</h5></button>
                        <div className={`collapse navbar-collapse ${defaultWebActiveMenu == 0 ? `show` : ''}`} id="started">

                            <ul className="sub-menu" >
                               
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/introduction`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1001 ? "active-hash" : ''}`}
                                    >
                                        Introduction
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/system-requirement`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1002 ? "active-hash" : ''}`}
                                    >
                                        Requirement
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/installation`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1003 ? "active-hash" : ''}`}
                                    >
                                        Installation
                                    </Nav.Link>
                                </li>
                               
                            </ul>
                        </div>
                    </li>
 
                    <li className='menu-item  mb-0 mt-2'>
                        <button type='button' data-bs-toggle="collapse" data-bs-target="#auth" aria-controls="auth" aria-expanded={defaultWebActiveMenu == 1 ? 'true' : 'false'} aria-label="Toggle navigation"><h5 className="text-dark font-weight-bold ">Authentication</h5> </button>
                        <div className={`collapse navbar-collapse ${defaultWebActiveMenu == 1 ? `show` : ''}`} id="auth">
                            <ul className="sub-menu">
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/login`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1005 ? "active-hash" : ''}`}
                                    >
                                        login
                                    </Nav.Link>
                                </li> 
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/business-signup`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1006 ? "active-hash" : ''}`}
                                    >
                                        Business Signup
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/forgot-password`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1007 ? "active-hash" : ''}`}
                                    >
                                        Forgot password
                                    </Nav.Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li className='menu-item  mb-0 mt-2'>
                        <button type='button' data-bs-toggle="collapse" data-bs-target="#adminpanel" aria-controls="adminpanel" aria-expanded={defaultWebActiveMenu == 4 ? 'true' : 'false'} aria-label="Toggle navigation"><h5 className="text-dark font-weight-bold ">Admin Panel</h5> </button>
                        <div className={`collapse navbar-collapse ${defaultWebActiveMenu == 4 ? `show` : ''}`} id="adminpanel">
                            <ul className="sub-menu">
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/admin/dashboard`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 2001 ? "active-hash" : ''}`}
                                    >
                                        Dashboard
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/admin/role-employee`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 2002 ? "active-hash" : ''}`}
                                    >
                                        Role & Empoloyees
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/admin/business`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 2003 ? "active-hash" : ''}`}
                                    >
                                        Business
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/admin/plan-subscription`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 2004 ? "active-hash" : ''}`}
                                    >
                                        Plans & Subscriptions
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/admin/settings`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 2005 ? "active-hash" : ''}`}
                                    >
                                        Settings
                                    </Nav.Link>
                                </li>

                            </ul>
                        </div>
                    </li>
                    <li className='menu-item  mb-0 mt-2'>
                        <button type='button' data-bs-toggle="collapse" data-bs-target="#companypanel" aria-controls="companypanel" aria-expanded={defaultWebActiveMenu == 2 ? 'true' : 'false'} aria-label="Toggle navigation"><h5 className="text-dark font-weight-bold ">Business Panel</h5> </button>
                        <div className={`collapse navbar-collapse ${defaultWebActiveMenu == 2 ? `show` : ''}`} id="companypanel">
                            <ul className="sub-menu">
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/business/dashboard`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1008 ? "active-hash" : ''}`}
                                    >
                                        Dashboard
                                    </Nav.Link>
                                </li>


                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/business/profile`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1009 ? "active-hash" : ''}`}
                                    >
                                        Profile
                                    </Nav.Link>
                                </li>

                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/business/employees`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1010 ? "active-hash" : ''}`}
                                    >
                                        Employees
                                    </Nav.Link>
                                </li>

                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/business/services`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1011 ? "active-hash" : ''}`}
                                    >
                                        Services
                                    </Nav.Link>
                                </li>

                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/business/products`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1012 ? "active-hash" : ''}`}
                                    >
                                        Products
                                    </Nav.Link>
                                </li>

                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/business/purchase`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1013 ? "active-hash" : ''}`}
                                    >
                                        Purchase & Return
                                    </Nav.Link>
                                </li>

                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/business/sales`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1014 ? "active-hash" : ''}`}
                                    >
                                        Sales
                                    </Nav.Link>
                                </li>


                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/business/pos`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1015 ? "active-hash" : ''}`}
                                    >
                                        POS
                                    </Nav.Link>
                                </li>


                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/business/stock-transfer`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1016 ? "active-hash" : ''}`}
                                    >
                                        Stock Transfer
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/business/contacts`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1017 ? "active-hash" : ''}`}
                                    >
                                        Contacts
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/business/accounts`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1018 ? "active-hash" : ''}`}
                                    >
                                        Accounts
                                    </Nav.Link>
                                </li>


                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/business/bulk-import`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1019 ? "active-hash" : ''}`}
                                    >
                                        Bulk Import
                                    </Nav.Link>
                                </li>

                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/business/hr-management`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1020 ? "active-hash" : ''}`}
                                    >
                                        HR Mangement
                                    </Nav.Link>
                                </li>


                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/business/todo`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1021 ? "active-hash" : ''}`}
                                    >
                                        Todo Manage
                                    </Nav.Link>
                                </li>
 
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/business/reports`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1022 ? "active-hash" : ''}`}
                                    >
                                        Reports
                                    </Nav.Link>
                                </li>
 

                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/business/settings`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1023 ? "active-hash" : ''}`}
                                    >
                                        Settings
                                    </Nav.Link>
                                </li>
                            </ul>
                        </div>
                    </li>


                    <li className='menu-item  mb-0 mt-2'>
                        <button type='button' data-bs-toggle="collapse" data-bs-target="#merchantpanel" aria-controls="merchantpanel" aria-expanded={defaultWebActiveMenu == 3 ? 'true' : 'false'} aria-label="Toggle navigation"><h5 className="text-dark font-weight-bold ">Branch Panel</h5> </button>
                        <div className={`collapse navbar-collapse ${defaultWebActiveMenu == 3 ? `show` : ''}`} id="merchantpanel">
                            <ul className="sub-menu">
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/branch/dashboard`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1024 ? "active-hash" : ''}`}
                                    >
                                        Dashboard
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/branch/profile`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1025 ? "active-hash" : ''}`}
                                    >
                                        Profile
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/branch/sales`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1026 ? "active-hash" : ''}`}
                                    >
                                        Sales
                                    </Nav.Link>
                                </li>

                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/branch/pos`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1027 ? "active-hash" : ''}`}
                                    >
                                        POS
                                    </Nav.Link>
                                </li>

                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/branch/need-help`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1028 ? "active-hash" : ''}`}
                                    >
                                        Need Help
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/branch/accounts`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1029 ? "active-hash" : ''}`}
                                    >
                                        Accounts
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/branch/hr-management`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1030 ? "active-hash" : ''}`}
                                    >
                                        HRM
                                    </Nav.Link>
                                </li>
                             
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/branch/reports`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1031 ? "active-hash" : ''}`}
                                    >
                                        Reports
                                    </Nav.Link>
                                </li>
                               
                            </ul>
                        </div>
                    </li>


                </ul>

            </div>
        </nav>
    );

};

export default Navbars;

