import React, { useEffect, useRef, useContext } from 'react'
import ScrollToTop from '../../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import StockTransferImg from '../../../../assets/images/weerp/web-image/business/stock_transfer.png'
import StockTransferViewImg from '../../../../assets/images/module/admin-panel/hubmanage/hub-view.png' 
import useScrollSpy from 'react-use-scrollspy';
import { ProjectTypeContext } from '../../../../ContextHandle';
export default function StockTransfer({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {

    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    const panelName   = 'Business panel';
    projectType = projectType.projectType;
    /* eslint-disable */ 
    useEffect(() => {
        setActivePage(1016);
        document.title = 'Stock Transfer | ' + projectName + '  web';
        setDefaultWebActiveMenu(2);
    }, [projectName]);
    /* eslint-enable */
    return (
        <div className='d-flex  justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                        <li className="breadcrumb-item active" aria-current="page">Stock Transfer</li>
                    </ol>
                </nav>
                <div id="hubmanage" >

                    <h4 className=" mt-5  font-weight-bold">Stock Transfer</h4>
                    <p className='pt-3'>Products can be transferred from one branch to another branch.</p>
                    <div id='hubs' ref={pagesectionRefs[0]}> 
                        <div className='pt-3'>
                            <img src={StockTransferImg} className="w-100 rounded" alt="Branch "></img>
                        </div> 
                    </div>
 
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/business/pos`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>POS</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/business/contacts`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Contacts</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className='right-menu'>
                
            </div>
        </div>
    )
}
