import React, { useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import '../../../../assets/images/codestyle.css';
import ScrollToTop from '../../../Shared/ScrollToTop';
import DeliveryProfile from '../../../../assets/images/app-image/delivery/14.jpg';
import { ProjectTypeContext } from '../../../../ContextHandle';
const DeliveryboyProfile = ({ setActivePage, setDocumentation, setDefaultAppActiveMenu }) => {
 /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;

    useEffect(() => {
        setActivePage(203);
        setDocumentation(2);
        document.title = "Delivery boy Profile | " + projectName;
        setDefaultAppActiveMenu(7);
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);

    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className='right-content-box-width'>
                <div id="system-requirements" >
                    <h4 className="pt-5">Delivery Boy Profile</h4>

                    <div className='pt-3'>
                        <img src={DeliveryProfile} className="w-75 rounded" alt="Delivery profile"></img>
                    </div>

                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/app/deliveryboy/dashboard`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Dashboard</h4>
                        </NavLink>
                    </div>

                </div>
            </div>



        </div>

    );

};

export default DeliveryboyProfile;
