import React, { useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import '../../../../../assets/images/codestyle.css';
import ScrollToTop from '../../../../Shared/ScrollToTop';
import { ProjectTypeContext } from '../../../../../ContextHandle';
import ReportImg from '../../../../../assets/images/weerp/app-image/reports.png';
const Reports = ({ setActivePage, setDocumentation, setDefaultAppActiveMenu }) => {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(111);
        setDocumentation(2);
        document.title = "Reports | " + projectName;
        setDefaultAppActiveMenu(6);
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);

    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className='right-content-box-width'>
                <div id="system-requirements" >
                    <h4 className="pt-5">Reports</h4>
                    <p>All types of reports can be viewed from the report module. Reports that can be viewed - Attendance, Report,  Expense, Stock reports,  Customer Sales Report and  Sales Reports can be viewed.</p>
                    <p><strong>Attendance reports: </strong> How many days were present and how many days were absent and how many days were holidays can be seen in the report.</p>
                    <p><strong>Customer Sales Report: </strong> The report will show how much the customer is buying the product and how much they are paying and how much is due.</p>
                    <div className='pt-3'>
                        <img src={ReportImg} className="w-75 rounded" alt="Profile" />
                    </div> 
                     
                    <div className="d-flex justify-content-between border-top mt-5">
                        <div className="text-start mt-4">
                            <NavLink to={`/${projectType}/app/business/accounts`} className="text-decoration-none">
                                <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                                <h4 className='pagination-title'>Accounts</h4>
                            </NavLink>
                        </div>
                         
                    </div>
                </div>
            </div>


        </div>

    );

};

export default Reports;
