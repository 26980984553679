import React, { useEffect, useRef, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import '../../../../../assets/images/codestyle.css';
import useScrollSpy from 'react-use-scrollspy';
import ScrollToTop from '../../../../Shared/ScrollToTop';
import { ProjectTypeContext } from '../../../../../ContextHandle';
const AppIcon = ({ setActivePage, sectionRefs, setDocumentation, setDefaultAppActiveMenu }) => {
 /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;

    useEffect(() => {
        setActivePage(519);
        setDocumentation(2);
        setDefaultAppActiveMenu(4);
        document.title = 'Add an app icon | ' + projectName;
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);


    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });


    return (
        <div className='d-flex   justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div id="system-requirements  " className='pt-5 right-content-box-width' >
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">iOS Setup</li>
                        <li className="breadcrumb-item active" aria-current="page">Add an app icon</li>
                    </ol>
                </nav>

                <div >
                    <h3 className="pt-5 font-weight-bold" >Add an app icon</h3>
                    <b>When a new Flutter app is created, a placeholder icon set is created. This step covers replacing these placeholder icons with your app’s icons:</b>
                    <ol className='mt-3'>
                        <li>Review the <a rel="noreferrer" href='https://developer.apple.com/design/human-interface-guidelines/foundations/app-icons#ios-ipados/' target="_blank">iOS App Icon</a> guidelines.</li>
                        <li>In the Xcode project navigator, select <mark className='text-danger px-2'>Assets.xcassets</mark> in the <mark className='text-danger px-2'>Runner</mark> folder. Update the placeholder icons with your own app icons.</li>
                        <li>Verify the icon has been replaced by running your app using <mark className='text-danger px-2'>flutter run</mark>.</li>
                    </ol>
                </div>


                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/app/update-apps-version`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Updating the app’s deployment version</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/app/build-archive`} className="text-decoration-none">
                            <a href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Create a build archive and upload to App Store Connect</h4>
                        </NavLink>
                    </div>
                </div>
            </div>

        </div>

    );

};

export default AppIcon;
