import React, { useEffect, useRef, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import '../../../../assets/images/codestyle.css';
import useScrollSpy from 'react-use-scrollspy';
import ScrollToTop from '../../../Shared/ScrollToTop';
import { ProjectTypeContext } from '../../../../ContextHandle';
const CreateApplicationRecord = ({ setActivePage, sectionRefs, setDocumentation, setDefaultAppActiveMenu }) => {
 /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;

    useEffect(() => {
        setActivePage(516);
        setDocumentation(2);
        setDefaultAppActiveMenu(4);
        document.title = 'Create an application record on App Store Connect | ' + projectName;
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);


    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });


    return (
        <div className='d-flex   justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div id="system-requirements  " className='pt-5 right-content-box-width' >
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">iOS Setup</li>
                        <li className="breadcrumb-item active" aria-current="page">Create an application record on App Store Connect</li>
                    </ol>
                </nav>

                <div >
                    <h3 className="pt-5 font-weight-bold" >Create an application record on App Store Connect</h3>
                    <p><b>Register your app on App Store Connect:</b></p>
                    <ol>
                        <li className='py-2'>Open <a rel="noreferrer" href='https://appstoreconnect.apple.com/login' target="_blank">App Store Connect</a> in your browser.</li>
                        <li className='py-2'>On the App Store Connect landing page, click <b>My Apps</b> .</li>
                        <li className='py-2'>Click + in the top-left corner of the My Apps page, then select New App.</li>
                        <li className='py-2'>Fill in your app details in the form that appears. In the Platforms section, ensure that iOS is checked. Since Flutter does not currently support tvOS, leave that checkbox unchecked. Click <b>Create</b>.</li>
                        <li className='py-2'>Navigate to the application details for your app and select <b>App Information</b> from the sidebar.</li>
                        <li className='py-2'>In the General Information section, select the Bundle ID you registered in the preceding step.</li>
                        <li className='py-2'> For a detailed overview, see <a rel="noreferrer" href='https://help.apple.com/app-store-connect/#/dev2cd126805' target="_blank">Add an app to your account</a>.</li>
                    </ol>
                </div>


                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/app/register-build-id`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Register a Bundle ID</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/app/review-xcode-project`} className="text-decoration-none">
                            <a href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Review Xcode project settings</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>

    );

};

export default CreateApplicationRecord;
