import React, { useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import '../../../assets/images/codestyle.css';
import ScrollToTop from './../../Shared/ScrollToTop';
import Thumbnail from '../../../assets/images/wecourier-inline-preview-image.png';
import { ProjectTypeContext } from '../../../ContextHandle'
const GettingStarted = ({ setActivePage, sectionRefs, setDocumentation, setDefaultAppActiveMenu }) => {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(101);
        setDocumentation(2);
        setDefaultAppActiveMenu('0');
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu]);

    useEffect(() => {
        document.title = 'Getting Started | ' + projectName + ' app';
    }, [projectName]);

    return (
        <div className='d-flex   justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>

            <div id="system-requirements  " className='pt-5 right-content-box-width' >
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item active" aria-current="page">Getting Started</li>
                    </ol>
                </nav>
                <img src={Thumbnail} alt='Thumbnail' className='mt-5 project-thumbnail' />
                <h3 className="pt-5">App Documentation</h3>
                <h4 className="pt-3">Wecourier - Parcel delivery courier management Logistics support CMS</h4>
                <p className='pt-3'>Thank you so much for purchasing our item from codecanyon.</p>
                <p>Wecourier is a complete parcel delivery solution for the most common courier platforms. It has a lot of build-in futures which are needed for every courier management site. It is designed for those who want to start their courier business or parcel delivery website. There are many courier scripts, many of them are very expensive, and have very complex interfaces for admin and user. Wecourier is designed with user friendly interface and simple interface. Any non-technical person can use this software.</p>
                <hr />
                <p><b>Version:</b> 1.3</p>
                <p><b>Author:</b> WemaxDevs</p>
                <p><b>Created:</b> Jan 2023</p>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <p className="text-secondary"><i className="bi bi-caret-left" /> </p>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/app/recommendation`} className="text-decoration-none">
                            <p href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></p>
                            <h4 className='pagination-title'>Recommendation</h4>
                        </NavLink>
                    </div>
                </div>
            </div>

        </div>

    );

};

export default GettingStarted;
