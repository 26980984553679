import React, { useEffect, useRef, useContext } from 'react'
import ScrollToTop from './../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import ParcelsImg from './../../../assets/images/module/admin-panel/parcels/parcel-list.png';
import ParcelAddImg from './../../../assets/images/module/admin-panel/parcels/parcel-create.png';
import ImportParcelsImg from './../../../assets/images/module/admin-panel/parcels/parcel-import.png';
import ParcelDetailsImg from './../../../assets/images/module/admin-panel/parcels/parcel-view.png';
import ParcelLogsImg from './../../../assets/images/module/admin-panel/parcels/parcel-logs.png';
import ParcelPrintImg from './../../../assets/images/module/admin-panel/parcels/parcel-print.png';
import ParcelLabelPrintImg from './../../../assets/images/module/admin-panel/parcels/parcel-label-print.png';
import useScrollSpy from 'react-use-scrollspy';
import { ProjectTypeContext } from '../../../ContextHandle';
export default function Parcels({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
 /* eslint-disable */
    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });

    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    const panelName   = projectType.panelName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1015);
        document.title = 'Parcels | ' + projectName + '  web';
        setDefaultWebActiveMenu(2);
    }, [projectName]);
    return (
        <div className='d-flex  justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                        <li className="breadcrumb-item active" aria-current="page">Parcels</li>
                    </ol>
                </nav>
                <div id="parcels" >

                    <h4 className="  mt-5 font-weight-bold " id='parcel-list' ref={pagesectionRefs[0]}>Parcels</h4>

                    <p className="">There is a list of all the parcels in Parcel, from there you can create, edit and delete parcels. And to see the details of the parcel, click on Details from the dropdown. And click on Parcel Log to see the parcel log. If you need to create the same parcel again, click on Clone to change something, you can create a changed parcel.Click on print to print the invoice of the parcel. And click on print label to print the label of the parcel. All parcels will be delivered and partially delivered. Invoices can be generated for all those parcels. And whether the invoice is paid or not can be seen with the parcel.</p>

                    <h4 className=" pt-3" >Parcel List </h4>
                    <div className='pt-3'>
                        <img src={ParcelsImg} className="w-100 rounded" alt="Parcel Lists"></img>
                    </div>
                    <h4 className=" pt-3">Add Parcel  </h4>
                    <div className='pt-3'>
                        <img src={ParcelAddImg} className="w-100 rounded" alt="Parcel add"></img>
                    </div>
                    <h4 className=" pt-3" id='import-parcel' ref={pagesectionRefs[1]}>Import Parcels  </h4>
                    <div className='pt-3'>
                        <img src={ImportParcelsImg} className="w-100 rounded" alt="Parcel Import"></img>
                    </div>
                    <h4 className=" pt-3" id='parcel-view' ref={pagesectionRefs[2]}>Parcel View  </h4>
                    <div className='pt-3'>
                        <img src={ParcelDetailsImg} className="w-100 rounded" alt="Parcel Details"></img>
                    </div>
                    <h4 className=" pt-3" id='parcel-logs' ref={pagesectionRefs[3]}>Parcel Logs  </h4>
                    <div className='pt-3'>
                        <img src={ParcelLogsImg} className="w-100 rounded" alt="Parcel Logs"></img>
                    </div>
                    <h4 className=" pt-3" id='parcel-print' ref={pagesectionRefs[4]}>Parcel Print  </h4>
                    <div className='pt-3'>
                        <img src={ParcelPrintImg} className="w-100 rounded" alt="Parcel Print"></img>
                    </div>
                    <h4 className=" pt-3">Parcel Label Print  </h4>
                    <div className='pt-3'>
                        <img src={ParcelLabelPrintImg} className="w-100 rounded" alt="Parcel Label Print"></img>
                    </div>
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/support`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Ticket</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/payout`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Payout</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className='right-menu'>
                <ul >
                    <li><a href='#parcel-list' className={activeSection == 0 ? 'active-has' : ""}>Parcel List</a></li>
                    <li><a href='#import-parcel' className={activeSection == 1 ? 'active-has' : ""}>Import Parcel</a></li>
                    <li><a href='#parcel-view' className={activeSection == 2 ? 'active-has' : ""}>Parcel view</a></li>
                    <li><a href='#parcel-logs' className={activeSection == 3 ? 'active-has' : ""}>Parcel Logs</a></li>
                    <li><a href='#parcel-print' className={activeSection == 4 ? 'active-has' : ""}>Print Parcel</a></li>
                </ul>
            </div>
        </div>
    )
}
