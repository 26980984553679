import React, { useEffect, useRef, useContext } from 'react';
import useScrollSpy from 'react-use-scrollspy';
import { NavLink } from 'react-router-dom';
import './../../assets/images/codestyle.css';
import Fcm1 from '../../assets/images/module/fcm-push-notification/fcm1.png';
import Fcm2 from '../../assets/images/module/fcm-push-notification/fcm2.png';
import Fcm3 from '../../assets/images/module/fcm-push-notification/fcm3.png';
import Fcm4 from '../../assets/images/module/fcm-push-notification/fcm4.png';
import Fcm5 from '../../assets/images/module/fcm-push-notification/fcm5.png';
import Firbase6 from '../../assets/images/module/fcm-push-notification/firebase6.png';
import Firbase7 from '../../assets/images/module/fcm-push-notification/fcm-server-key7.png';
import ScrollToTop from '../Shared/ScrollToTop';
import { ProjectTypeContext } from '../../ContextHandle';
const FCMPushNotification = ({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) => {
 /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1004);
        document.title = 'FCM Push notification | ' + projectName + ' web';
        setDefaultWebActiveMenu('0');
    }, [projectName]);

    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });
    return (
        <div className='d-flex   justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item " aria-current="page">Getting Started</li>
                        <li className="breadcrumb-item active" aria-current="page">FCM Push Notification</li>
                    </ol>
                </nav>
                <div id="fcmpushnotification" ref={sectionRefs['65']}>
                    <h4 className=" mt-5  font-weight-bold">FCM Push Notification</h4>

                    <h5 className='font-weight-bold mt-2' id='create-firebase' ref={pagesectionRefs[0]}>Step-1:Create Firebase Project</h5>
                    <p>In this step, We have to create a project on Firebase Console and then we have to create a web app on this project as you can see in these screenshots:</p>
                    <div className='pt-3'>
                        <img src={Fcm1} className="w-100 rounded" alt="Fcm"></img>
                    </div>
                    <h5 className='font-weight-bold mt-5' id='createproject' ref={pagesectionRefs[1]}>Step 2: Create a Web App in created Project</h5>
                    <div className='pt-3'>
                        <img src={Fcm2} className="w-100 rounded" alt="fcm2"></img>
                    </div>
                    <h5 className='font-weight-bold mt-5' id='config' ref={pagesectionRefs[2]}>Step 3: Web App Configuration Detail</h5>
                    <div className='pt-3'>
                        <img src={Fcm3} className="w-100 rounded" alt="fcm3"></img>
                    </div>
                    <h5 className='font-weight-bold mt-5' id='code' ref={pagesectionRefs[3]}>Step 4: Add Firebase Code</h5>
                    <p>resources/views/backend/partials/footer.blade.php</p>
                    <div className='pt-3'>
                        <img src={Fcm4} className="w-100 rounded" alt="fcm4"></img>
                    </div>
                    <h5 className='font-weight-bold mt-5' id='publicfolder' ref={pagesectionRefs[4]}>Step 5: Create public/firebase-messaging-sw.js in a public folder</h5>
                    <div className='pt-3'>
                        <img src={Fcm5} className="w-100 rounded" alt="fcm5"></img>
                    </div>
                    <h5 className='font-weight-bold mt-5' id='server' ref={pagesectionRefs[5]}>Step 6: To get server key click on Cloud Messaging option</h5>
                    <div className='pt-3'>
                        <img src={Firbase6} className="w-100 rounded" alt="Firbase6"></img>
                    </div>
                    <h5 className='font-weight-bold mt-5' id='env' ref={pagesectionRefs[6]}>Step 7: Turn on the switch and put the server key in your web .env file</h5>
                    <div className='pt-3'>
                        <img src={Firbase7} className="w-100 rounded" alt="Firbase7"></img>
                    </div>

                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/installation`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Installation</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/login`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Login</h4>
                        </NavLink>
                    </div>
                </div>

            </div>
            <div className='right-menu'>
                <ul >
                    <li><a href='#create-firebase' className={activeSection == 0 ? 'active-has' : ""}>Create Firebase Project</a></li>
                    <li><a href='#create-project' className={activeSection == 1 ? 'active-has' : ""}>Create a Web App in created Project</a></li>
                    <li><a href='#config' className={activeSection == 2 ? 'active-has' : ""}>Web App Configuration Detail</a></li>
                    <li><a href='#code' className={activeSection == 3 ? 'active-has' : ""}>Add Firebase Code</a></li>
                    <li><a href='#publicfolder' className={activeSection == 4 ? 'active-has' : ""}>Create public/firebase-messaging-sw.js in a public folder</a></li>
                    <li><a href='#server' className={activeSection == 5 ? 'active-has' : ""}>Get server key click  </a></li>
                    <li><a href='#env' className={activeSection == 6 ? 'active-has' : ""}>Put the server key in your web .env file </a></li>
                </ul>
            </div>
        </div>

    );

};

export default FCMPushNotification;
