import './App.css';
import { Routes, Route } from "react-router-dom";

import React, { useRef, useState } from 'react';
import useScrollSpy from 'react-use-scrollspy';

import Welcome from './WelcomeIndex';
import WeCourierRoutes from './RoutesFile/WecourierRoutes';
import WecourierSAASRoutes from './RoutesFile/WecourierSAASRoutes';
import { ProjectTypeContext } from './ContextHandle'
import { ProjectList } from './Enums/ProjectList';
import DocumentationListModal from './components/DocumentationListModal'  
import FaviconDynamic from './components/FaviconDynamic';
import WeErpRoutes from './RoutesFile/WeErpRoutes';
import WeErpNormalRoutes from './RoutesFile/WeErpNormalRoutes';
import WemoverRoutes from './RoutesFile/WemoverRoutes';
function App() {
    const sectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];
    const activeSection = useScrollSpy({
        sectionElementRefs: sectionRefs,
        offsetPx: -80,
    });
    const [activePage, setActivePage] = useState('');
    const [Documentation, setDocumentation] = useState(1);
    const [defaultAppActiveMenu, setDefaultAppActiveMenu] = useState('0');
    const [defaultWebActiveMenu, setDefaultWebActiveMenu] = useState('0');

 
    return (
        <div className="App">
            {/* Home Route  */}
            <Routes>
                <Route path='/' element={<Welcome />} />
            </Routes>
                <ProjectTypeContext.Provider value={{
                    projectType: ProjectList.Wecourier,
                    projectName: 'We Courier',
                    panelName: 'Admin Panel',
                }}>
                    <WeCourierRoutes
                        activePage={activePage}
                        Documentation={Documentation}
                        defaultWebActiveMenu={defaultWebActiveMenu}
                        setDefaultAppActiveMenu={setDefaultAppActiveMenu}
                        setDocumentation={setDocumentation}
                        defaultAppActiveMenu={defaultAppActiveMenu}
                        setDefaultWebActiveMenu={setDefaultWebActiveMenu}
                        setActivePage={setActivePage}
                        sectionRefs={sectionRefs}
                        activeSection={activeSection}
                    />
                    
                </ProjectTypeContext.Provider>

                <ProjectTypeContext.Provider value={{
                    projectType: ProjectList.WecourierSAAS,
                    projectName: 'We Courier SAAS',
                    panelName: 'Comapny Panel'
                }}>
                    <WecourierSAASRoutes
                        activePage={activePage}
                        Documentation={Documentation}
                        defaultWebActiveMenu={defaultWebActiveMenu}
                        setDefaultAppActiveMenu={setDefaultAppActiveMenu}
                        setDocumentation={setDocumentation}
                        defaultAppActiveMenu={defaultAppActiveMenu}
                        setDefaultWebActiveMenu={setDefaultWebActiveMenu}
                        setActivePage={setActivePage}
                        sectionRefs={sectionRefs}
                        activeSection={activeSection}
                    /> 
                </ProjectTypeContext.Provider>


                <ProjectTypeContext.Provider value={{
                    projectType: ProjectList.Weerp,
                    projectName: 'We ERP (SAAS)',
                    panelName: 'Admin panel' 
                }}>
                    <WeErpRoutes
                        activePage={activePage}
                        Documentation={Documentation}
                        defaultWebActiveMenu={defaultWebActiveMenu}
                        setDefaultAppActiveMenu={setDefaultAppActiveMenu}
                        setDocumentation={setDocumentation}
                        defaultAppActiveMenu={defaultAppActiveMenu}
                        setDefaultWebActiveMenu={setDefaultWebActiveMenu}
                        setActivePage={setActivePage}
                        sectionRefs={sectionRefs}
                        activeSection={activeSection}
                    /> 
                </ProjectTypeContext.Provider>


                <ProjectTypeContext.Provider value={{
                    projectType: ProjectList.WeerpNormal,
                    projectName: 'We ERP ( Normal )',
                    panelName: 'Admin panel' 
                }}>
                    <WeErpNormalRoutes
                        activePage={activePage}
                        Documentation={Documentation}
                        defaultWebActiveMenu={defaultWebActiveMenu}
                        setDefaultAppActiveMenu={setDefaultAppActiveMenu}
                        setDocumentation={setDocumentation}
                        defaultAppActiveMenu={defaultAppActiveMenu}
                        setDefaultWebActiveMenu={setDefaultWebActiveMenu}
                        setActivePage={setActivePage}
                        sectionRefs={sectionRefs}
                        activeSection={activeSection}
                    /> 
                </ProjectTypeContext.Provider>


                
                <ProjectTypeContext.Provider value={{
                    projectType: ProjectList.Wemover,
                    projectName: 'We Mover',
                    panelName: 'Admin panel' 
                }}>
                    <WemoverRoutes
                        activePage={activePage}
                        Documentation={Documentation}
                        defaultWebActiveMenu={defaultWebActiveMenu}
                        setDefaultAppActiveMenu={setDefaultAppActiveMenu}
                        setDocumentation={setDocumentation}
                        defaultAppActiveMenu={defaultAppActiveMenu}
                        setDefaultWebActiveMenu={setDefaultWebActiveMenu}
                        setActivePage={setActivePage}
                        sectionRefs={sectionRefs}
                        activeSection={activeSection}
                    /> 
                </ProjectTypeContext.Provider>




                <DocumentationListModal />
               
        </div>
    );
}

export default App;
