import React, { useEffect, useContext } from 'react'
import { NavLink } from 'react-router-dom';
import ScrollToTop from '../../Shared/ScrollToTop';
import AddonUpload from './../../../assets/images/module/admin-panel/addon-upload.png';
import { ProjectTypeContext } from '../../../ContextHandle';
export default function Addons({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
 /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    const panelName   = projectType.panelName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(10200);
        document.title = 'Addons | ' + projectName + ' web';
        setDefaultWebActiveMenu(2);
    }, [projectName]);
    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                        <li className="breadcrumb-item active" aria-current="page">Addons</li>
                    </ol>
                </nav>
                <div id="parcels" ref={sectionRefs['38']}>
                    <h4 className=" mt-5 font-weight-bold ">Addons</h4>
                    <h4 className=" pt-3"> How to install/update the script?</h4>
                    <p className="">To install/update addon system you need to follow the below steps :</p>
                    <ol>
                        <li>weCourier should be pre-installed on your server.</li>
                        <li>Purchase and Download the addon System Addon file.</li>
                        <li>Login in to your admin panel of WeCourier the system</li>
                        <li>Go to Addon Manager</li>
                        <li>Click on Install New Addon</li>
                    </ol>
                    <div className='pt-3'>
                        <img src={AddonUpload} className="w-100 rounded" alt="Parcel Lists"></img>
                    </div>


                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/push-notification`} className="text-decoration-none">
                            <p className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></p>
                            <h4 className='pagination-title'>Push Notification</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/settings`} className="text-decoration-none">
                            <p href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></p>
                            <h4 className='pagination-title'>Settings</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
