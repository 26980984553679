import React, { useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import '../../../../../assets/images/codestyle.css';
import ScrollToTop from '../../../../Shared/ScrollToTop';
import Dashboard from '../../../../../assets/images/weerp/app-image/dashboard.png';
import { ProjectTypeContext } from '../../../../../ContextHandle';
const MerchantDashboard = ({ setActivePage, setDocumentation, setDefaultAppActiveMenu }) => {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(105);
        setDocumentation(2);
        document.title = "Dashboard | " + projectName;
        setDefaultAppActiveMenu(6);
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);

    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className='right-content-box-width'>
                <div id="system-requirements  " >
                    <h4 className="pt-5 mt-5">Business Dashboard</h4>
                    <p>Within the dashboard you can view total summary or sales purses and purse returns and POS. And can see more sales chart and purse chart. You can also see how many more products there are.</p>
                    <div className='pt-3'>
                        <img src={Dashboard} className="w-75 rounded" alt="Merchant dashboard" />
                    </div>

                    <div className="d-flex justify-content-between border-top mt-5">
                        <div className="text-start mt-4">
                            <NavLink to={`/${projectType}/app/business/login`} className="text-decoration-none">
                                <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                                <h4 className='pagination-title'>Login</h4>
                            </NavLink>
                        </div>
                        <div className="text-end my-2">
                            <NavLink to={`/${projectType}/app/business/profile`} className="text-decoration-none">
                                <a href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                                <h4 className='pagination-title'>Profile</h4>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    );

};

export default MerchantDashboard;
