import React, { useContext } from 'react';
import { Nav } from 'react-bootstrap';
import '../../Shared/Navbar.css'
import { NavLink } from 'react-router-dom'
import { ProjectTypeContext } from '../../../ContextHandle';
const AppNavbar = ({ activeSection, setActiveSection, sectionRefs, activePage, defaultAppActiveMenu }) => {
    var projectType = useContext(ProjectTypeContext);
    projectType = projectType.projectType;
    return (
        <nav id="sidebarMenu" className="navbar  d-lg-block sidebar  bg-white p-3 pb-0">
            <div className="text-dark">

                {/* Getting started  */}
                <ul className='p-0 m-0'>
                 
                    <li className='menu-item mb-0'>
                        <Nav.Link className={` pl-0 afterignore pb-0 pt-0 ms-1 }`} href={`/${projectType}`}><h5 className={`${activePage === 101 ? `text-primary` : `text-dark`} font-weight-bold`}  >Getting Started</h5></Nav.Link>
                    </li>
                    <li className='menu-item mb-0'>
                        <button type='button'  data-bs-toggle="collapse" data-bs-target="#basic" aria-controls="basic" aria-expanded={defaultAppActiveMenu === 1 ? 'true' : 'false'} aria-label="Toggle navigation"><h5 className={`text-dark  font-weight-bold`}  >App Basics</h5>  </button>
                        <div className={`collapse navbar-collapse ${defaultAppActiveMenu === 1 ? `show` : ''}`} id="basic">
                            <ul className="sub-menu">
                                <li className="nav-item">
                                    <Nav.Link className={`nav-link text-decoration-none sub-menu-text ${activePage === 501 ? "active-hash" : ''}`} href={`/${projectType}/app/recommendation`}>Recommendation</Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link href={`/${projectType}/app/installation`} className={`nav-link text-decoration-none sub-menu-text ${activePage === 502 ? "active-hash" : ''}`} >Installation</Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link href={`/${projectType}/app/prepare-flutter-environment`} className={`nav-link text-decoration-none sub-menu-text ${activePage === 503 ? "active-hash" : ''}`}> Prefare Flutter Environment </Nav.Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li className='menu-item mb-0 mt-2'>
                        <button type='button' data-bs-toggle="collapse" data-bs-target="#common" aria-controls="common" aria-expanded={defaultAppActiveMenu === 2 ? 'true' : 'false'} aria-label="Toggle navigation"> <h5 className={`text-dark  font-weight-bold`}  >Common Setup</h5> </button>
                        <div className={`collapse navbar-collapse ${defaultAppActiveMenu === 2 ? `show` : ''}`} id="common">
                            <ul className="sub-menu">
                             
                                <li className="nav-item">
                                    <Nav.Link href={`/${projectType}/app/change-api-server-details`} className={`nav-link text-decoration-none sub-menu-text ${activePage === 506 ? "active-hash" : ''}`}> Change api server details </Nav.Link>
                                </li>
                             
                                <li className="nav-item">
                                    <Nav.Link href={`/${projectType}/app/push-notification-setup`} className={`nav-link text-decoration-none sub-menu-text ${activePage === 50777 ? "active-hash" : ''}`}> Push notification setup </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link href={`/${projectType}/app/change-app-logo`} className={`nav-link text-decoration-none sub-menu-text ${activePage === 507 ? "active-hash" : ''}`}> Change app logo </Nav.Link>
                                </li>

                            </ul>
                        </div>
                    </li>
                    <li className='menu-item mb-0 mt-2'>
                        <button type='button' data-bs-toggle="collapse" data-bs-target="#android" aria-controls="android" aria-expanded={defaultAppActiveMenu === 3 ? 'true' : 'false'} aria-label="Toggle navigation"> <h5 className={`text-dark  font-weight-bold`}  >Android Setup</h5></button>
                        <div className={`collapse navbar-collapse ${defaultAppActiveMenu === 3 ? `show` : ''}`} id="android">
                            <ul className="sub-menu">
                                <li className="nav-item">
                                    <Nav.Link className={`nav-link text-decoration-none sub-menu-text ${activePage === 508 ? "active-hash" : ''}`} href={`/${projectType}/app/change-app-name`}>Change app name</Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link className={`nav-link text-decoration-none sub-menu-text ${activePage === 509 ? "active-hash" : ''}`} href={`/${projectType}/app/change-android-luncher-icon`}>Change android luncher icon</Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link className={`nav-link text-decoration-none sub-menu-text ${activePage === 510 ? "active-hash" : ''}`} href={`/${projectType}/app/change-android-package-name`}>Change android package name</Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link className={`nav-link text-decoration-none sub-menu-text ${activePage === 511 ? "active-hash" : ''}`} href={`/${projectType}/app/generate-debug-apk`}>Generate Debug Apk</Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link className={`nav-link text-decoration-none sub-menu-text ${activePage === 512 ? "active-hash" : ''}`} href={`/${projectType}/app/generate-signed-apk`}>Generate signed Apk</Nav.Link>
                                </li>

                            </ul>
                        </div>
                    </li>
                    <li className='menu-item mb-0 mt-2'>
                        <button type='button' data-bs-toggle="collapse" data-bs-target="#ios" aria-controls="ios" aria-expanded={defaultAppActiveMenu === 4 ? 'true' : 'false'} aria-label="Toggle navigation"> <h5 className={`text-dark  font-weight-bold`}  >iOS Setup</h5></button>
                        <div className={`collapse navbar-collapse ${defaultAppActiveMenu === 4 ? `show` : ''}`} id="ios">
                            <ul className="sub-menu">
                                <li className="nav-item">
                                    <Nav.Link className={`nav-link text-decoration-none sub-menu-text ${activePage === 513 ? "active-hash" : ''}`} href={`/${projectType}/app/preliminaries`}>Preliminaries</Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link className={`nav-link text-decoration-none sub-menu-text ${activePage === 514 ? "active-hash" : ''}`} href={`/${projectType}/app/register-your-app`}>Register your app on App Store Connect</Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link className={`nav-link text-decoration-none sub-menu-text ${activePage === 515 ? "active-hash" : ''}`} href={`/${projectType}/app/register-build-id`}>Register a Bundle ID</Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link className={`nav-link text-decoration-none sub-menu-text ${activePage === 516 ? "active-hash" : ''}`} href={`/${projectType}/app/create-application-record`}>Create an application record on App Store Connect</Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link className={`nav-link text-decoration-none sub-menu-text ${activePage === 517 ? "active-hash" : ''}`} href={`/${projectType}/app/review-xcode-project`}>Review Xcode project settings</Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link className={`nav-link text-decoration-none sub-menu-text ${activePage === 518 ? "active-hash" : ''}`} href={`/${projectType}/app/update-apps-version`}>Updating the app’s deployment version</Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link className={`nav-link text-decoration-none sub-menu-text ${activePage === 519 ? "active-hash" : ''}`} href={`/${projectType}/app/add-app-icon`}>Add an app icon</Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link className={`nav-link text-decoration-none sub-menu-text ${activePage === 520 ? "active-hash" : ''}`} href={`/${projectType}/app/build-archive`}>Create a build archive and upload to App Store Connect</Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link className={`nav-link text-decoration-none sub-menu-text ${activePage === 521 ? "active-hash" : ''}`} href={`/${projectType}/app/update-build-version-number`}>Update the app’s build and version numbers</Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link className={`nav-link text-decoration-none sub-menu-text ${activePage === 522 ? "active-hash" : ''}`} href={`/${projectType}/app/create-app-build`}>Create an app bundle</Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link className={`nav-link text-decoration-none sub-menu-text ${activePage === 523 ? "active-hash" : ''}`} href={`/${projectType}/app/testflight`}>Release your app on TestFlight</Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link className={`nav-link text-decoration-none sub-menu-text ${activePage === 524 ? "active-hash" : ''}`} href={`/${projectType}/app/release-your-app-store`}>Release your app to the App Store</Nav.Link>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li className='menu-item mb-0 mt-2'>
                        <button type='button' data-bs-toggle="collapse" data-bs-target="#adminpanel" aria-controls="adminpanel" aria-expanded={defaultAppActiveMenu === 5 ? 'true' : 'false'} aria-label="Toggle navigation"> <h5 className={`text-dark  font-weight-bold`}  >Admin Panel</h5></button>
                        <div className={`collapse navbar-collapse ${defaultAppActiveMenu === 5 ? `show` : ''}`} id="adminpanel">
                            <ul className="sub-menu">
                                <li className="nav-item">
                                    <Nav.Link className={`nav-link text-decoration-none sub-menu-text ${activePage === 525 ? "active-hash" : ''}`} href={`/${projectType}/app/admin-panel-requirement`}>Requirement</Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link className={`nav-link text-decoration-none sub-menu-text ${activePage === 526 ? "active-hash" : ''}`} href={`/${projectType}/app/admin-panel-installation-process`}>Installation Process</Nav.Link>
                                </li>
                            </ul>
                        </div>
                    </li>


                    <li className='menu-item mb-0 mt-2'>

                        <button type='button' data-bs-toggle="collapse" data-bs-target="#merchant" aria-controls="merchant" aria-expanded={defaultAppActiveMenu === 6 ? 'true' : 'false'} aria-label="Toggle navigation"><h5 className="text-dark font-weight-bold mb-0">How to use</h5> </button>
                        <div className={`collapse navbar-collapse ${defaultAppActiveMenu === 6 ? `show` : ''}`} id="merchant">
                            <ul className="sub-menu">
 
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/app/shipper-app`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 104 ? "active-hash" : ''}`}
                                    >
                                        Shipper App
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/app/driver-app`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 105 ? "active-hash" : ''}`}
                                    >
                                        Driver app
                                    </Nav.Link>
                                </li>
                              
                            </ul>
                        </div>
                    </li>
  
                </ul>


            </div>
        </nav>
    );
};

export default AppNavbar;
