import React, { useEffect, useContext } from 'react'
import ScrollToTop from '../../../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import LoginImg from './../../../../../assets/images/weerp/web-image/auth/login.png';
import { ProjectTypeContext } from '../../../../../ContextHandle';
export default function Login({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1005);
        document.title = 'Login | ' + projectName + ' web';
        setDefaultWebActiveMenu('1');
    }, [projectName]);


    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">Authentication</li>
                        <li className="breadcrumb-item active" aria-current="page">Login</li>
                    </ol>
                </nav>
                <div id="accounts" ref={sectionRefs['50']}>
                    <h4 className=" font-weight-bold mt-5  ">Login</h4>
                    <p  > Business owners can register accounts. After registering he can login. And he can reset his forgotten password. </p>
                    <div className='pt-3'>
                        <img src={LoginImg} className="w-100 rounded" alt="newsoffers.png"></img>
                    </div>
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/installation`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Installation</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/business-signup`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Business signup</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
