
import React, { useEffect, useRef, useContext } from 'react'
import ScrollToTop from './../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import accountHeads from './../../../assets/images/module/admin-panel/accounts/account-heads.png';
import accounts from './../../../assets/images/module/admin-panel/accounts/accounts-list.png';
import addAccounts from './../../../assets/images/module/admin-panel/accounts/accounts-create.png';
import fundTransfer from './../../../assets/images/module/admin-panel/accounts/fund-transfer-list.png';
import fundTransferCreate from './../../../assets/images/module/admin-panel/accounts/fund-transfer-create.png';
import income from './../../../assets/images/module/admin-panel/accounts/income-list.png';
import addIncome from './../../../assets/images/module/admin-panel/accounts/income-create.png';
import expense from './../../../assets/images/module/admin-panel/accounts/expense-list.png';
import addExpense from './../../../assets/images/module/admin-panel/accounts/expense-create.png';
import bankTransaction from './../../../assets/images/module/admin-panel/accounts/bank-transaction.png';
import bankTransactionPrint from './../../../assets/images/module/admin-panel/accounts/bank-transaction-print.png';
import PaidInoviceList from './../../../assets/images/module/admin-panel/accounts/paid-invoice-list.png';
import useScrollSpy from 'react-use-scrollspy';
import { ProjectTypeContext } from '../../../ContextHandle';
export default function Accounts({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {

 /* eslint-disable */
    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    const panelName   = projectType.panelName;
    projectType = projectType.projectType;

    useEffect(() => {
        setActivePage(1017);
        document.title = 'Accounts | ' + projectName + ' web';
        setDefaultWebActiveMenu('2');
    }, [projectName]);
 
    return (
        <div className='d-flex  justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                        <li className="breadcrumb-item active" aria-current="page">Accounts</li>
                    </ol>
                </nav>
                <div id="accounts" >
                    <h3 className="mt-3 pt-2">Admin Panel</h3>
                    <h4 className="border-top mt-5 pt-5">Accounts</h4>

                    <div id='account-heads' ref={pagesectionRefs[0]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Account Heads</h5>
                        <p className=" pt-3">Account Heads will be by default and cannot be added, edited or deleted.</p>
                        <div className='pt-3'>
                            <img src={accountHeads} className="w-100 rounded" alt="account-heads.png"></img>
                        </div>
                    </div>

                    <div id='accountss' ref={pagesectionRefs[1]}>
                        <h5 className="border-top font-weight-bold mt-5 pt-2 text-primary"># Accounts</h5>
                        <p className=" pt-3">Accounts can be created for admin and user functions through Accounts module and account list, account edit and delete can be done. To create account click on plus sign from the account list. And fill the form that appears and click on submit button. Account will be created. Accounts can be filtered from the list of accounts.</p>
                        <h4 className=" pt-3">Account List</h4>
                        <div className='pt-3'>
                            <img src={accounts} className="w-100 rounded" alt="accounts.png"></img>
                        </div>
                        <h4 className=" pt-3">Add Accounts</h4>
                        <div className='pt-3'>
                            <img src={addAccounts} className="w-100 rounded" alt="accounts.png"></img>
                        </div>
                    </div>

                    <div id='fund-transfer' ref={pagesectionRefs[2]}>
                        <h5 className="border-top font-weight-bold mt-5 pt-2 text-primary"># Fund Transfer</h5>
                        <p className=" pt-3">Fund Transfer Through this module money can be transferred from one account to another account. For fund transfer, a form will be displayed by clicking on the plus sign from the fund transfer module. And in this form select the account from which you will transfer money and select the account from which you will receive money and click on submit button.</p>
                        <h5 className=" font-weight-bold mt-5 pt-2 ">Fund Transfer List</h5>
                        <div className='pt-3'>
                            <img src={fundTransfer} className="w-100 rounded" alt="Fundtransfer.png"></img>
                        </div>
                        <h5 className="  font-weight-bold mt-5 pt-2 ">Fund Transfer Create</h5>
                        <div className='pt-3'>
                            <img src={fundTransferCreate} className="w-100 rounded" alt="FundtransferCreate.png"></img>
                        </div>
                    </div>

                    <div id='income' ref={pagesectionRefs[3]}>
                        <h5 className="border-top font-weight-bold mt-5 pt-2 text-primary"># Income</h5>
                        <p className=" pt-3">
                            Through the income module, all the income related work of different account heads can be done. Such as - <br />
                            <b>- payment received from merchant : </b>Through this money is received from the merchant.<br />
                            <b>- payment received from deliveryman : </b> Through this money is received from the delivery man.<br />
                            <b>- others : </b> Through this money is received from the others user.<br />
                            <b>- Payment received from hub : </b> Through this money is received from the hub.<br /> </p>
                        <p>To receive payment through income, click on the plus sign from the list of income, a form will be displayed, select the account head from which the payment will be received, enter the amount of money and click on the submit button, the payment will be accepted.</p>
                        <h4 className=" pt-3">Income List</h4>
                        <div className='pt-3'>
                            <img src={income} className="w-100 rounded" alt="income.png"></img>
                        </div>
                        <h4 className=" pt-3">Add Income</h4>
                        <div className='pt-3'>
                            <img src={addIncome} className="w-100 rounded" alt="addIncome.png"></img>
                        </div>

                    </div>

                    <div id='expense' ref={pagesectionRefs[4]}>
                        <h5 className="border-top font-weight-bold mt-5 pt-2 text-primary"># Expense</h5>
                        <p className=" pt-3">Commission can be given to delivery man and commission to others through expense module. And expenses can be listed, edited and deleted. To create expenses click on add button from the list of expenses module and in the form that appears select the account head and submit. And expenses can be filtered from the list.</p>
                        <h4 className=" pt-3">Expense List</h4>
                        <div className='pt-3'>
                            <img src={expense} className="w-100 rounded" alt="expense.png"></img>
                        </div>
                        <h4 className=" pt-3">Add Expense</h4>
                        <div className='pt-3'>
                            <img src={addExpense} className="w-100 rounded" alt="addExpense.png"></img>
                        </div>
                    </div>

                    <div id='bank-transaction' ref={pagesectionRefs[5]}>
                        <h5 className="border-top font-weight-bold mt-5 pt-2 text-primary"># Bank Transation</h5>
                        <p className=" pt-3">The list of all transactions related to the bank can be seen from Bank Transactions. And it can be found by filtering.</p>
                        <h5 className=" font-weight-bold mt-5 pt-2">Bank Transation List</h5>
                        <div className='pt-3'>
                            <img src={bankTransaction} className="w-100 rounded" alt="bankTransaction.png"></img>
                        </div>
                        <h5 className="  font-weight-bold  mt-3  "> Bank Transation Print</h5>
                        <div className='pt-3'>
                            <img src={bankTransactionPrint} className="w-100 rounded" alt="bankTransactionPrint.png"></img>
                        </div>
                    </div>
                    <div id='paid-invoice' ref={pagesectionRefs[6]}>
                        <h5 className="border-top font-weight-bold mt-5 pt-2 text-primary"># Paid Invoice</h5>
                        <p className=" pt-3">The list of all paid invoices that have been paid to the merchant through the Paid invoice module can be viewed and detailed.</p>
                        <h5 className=" font-weight-bold mt-2  ">Paid invoice List</h5>
                        <div className='pt-3'>
                            <img src={PaidInoviceList} className="w-100 rounded" alt="PaidInoviceList.png"></img>
                        </div>
                    </div>

                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/payout`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Payout</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/users-roles`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>User & Roles</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className='right-menu'>
                <ul>
                    <li><a href='#account-heads' className={activeSection == 0 ? 'active-has' : ""}>Account Heads </a></li>
                    <li><a href='#accountss' className={activeSection == 1 ? 'active-has' : ""}>Accounts</a></li>
                    <li><a href='#fund-transfer' className={activeSection == 2 ? 'active-has' : ""}>Fund Transfer</a></li>
                    <li><a href='#income' className={activeSection == 3 ? 'active-has' : ""}>Income</a></li>
                    <li><a href='#expense' className={activeSection == 4 ? 'active-has' : ""}>Expense</a></li>
                    <li><a href='#bank-transaction' className={activeSection == 5 ? 'active-has' : ""}>Bank Transation</a></li>
                    <li><a href='#paid-invoice' className={activeSection == 6 ? 'active-has' : ""}>Paid Invoice</a></li>
                </ul>
            </div>
        </div>
    )
}

