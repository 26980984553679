import React, { useEffect, useContext } from 'react'
import { NavLink } from 'react-router-dom';
import ScrollToTop from '../../Shared/ScrollToTop';

import paymentAccounts from './../../../assets/images/module/merchant-panel/profile/payment-information-list.png';
import paymentAccountsCreate from './../../../assets/images/module/merchant-panel/profile/payment-information-create.png';
import { ProjectTypeContext } from '../../../ContextHandle';
export default function PaymentInformation({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1024);
        document.title = 'Payment Information | ' + projectName + ' web';
        setDefaultWebActiveMenu(3);
    }, [projectName]);
    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">

                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">Merchant Panel</li>
                        <li className="breadcrumb-item active" aria-current="page">Payment Information</li>
                    </ol>
                </nav>
                <div id="parcels" ref={sectionRefs['39']}>

                    <h4 className="font-weight-bold mt-5  ">Payment Information</h4>

                    <div id='payment-account'>
                        <p  > Merchant can create payment account to receive his payment. To create payment account click on payment account from account. And clicking on the plus sign from the payment account list will display a form. Various account types can be added while creating an account. After filling the form and clicking on the submit button, the form will be generated.</p>
                        <h4 className="  mt-3">Payment Information list</h4>
                        <div className='pt-3'>
                            <img src={paymentAccounts} className="w-100 rounded" alt="paymentaccounts.png"></img>
                        </div>
                        <h4 className="  mt-3">Payment Information create</h4>
                        <div className='pt-3'>
                            <img src={paymentAccountsCreate} className="w-100 rounded" alt="paymentaccountsCreate.png"></img>
                        </div>
                    </div>


                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/merchant/profile`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Profile</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/merchant/pickup-request`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Pickup Request</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
