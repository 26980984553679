import React, { useEffect, useRef, useContext } from 'react'
import ScrollToTop from './../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import ParcelsImg from './../../../assets/images/module/merchant-panel/parcels/parcel-list.png';
import ParcelAddImg from './../../../assets/images/module/merchant-panel/parcels/parcel-create.png';
import ParcelDetailsImg from './../../../assets/images/module/merchant-panel/parcels/parcel-view.png';
import ParcelLogsImg from './../../../assets/images/module/merchant-panel/parcels/parcel-logs.png';
import ImportParcelImg from './../../../assets/images/module/merchant-panel/parcels/parcel-import.png';
import useScrollSpy from 'react-use-scrollspy';
import { ProjectTypeContext } from '../../../ContextHandle';
export default function Parcels({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1029);
        document.title = 'Parcels | ' + projectName + ' web';
        setDefaultWebActiveMenu(3);
    }, [projectName]);

    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });

    return (
        <div className='d-flex justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">Merchant Panel</li>
                        <li className="breadcrumb-item active" aria-current="page">Parcels</li>
                    </ol>
                </nav>
                <div id="accounts"  >
                    <h4 className="  mt-5 font-weight-bold" id='parcel-list' ref={pagesectionRefs[0]}> Parcels</h4>
                    <p>
                        There is a list of all the parcels in Parcel, from there you can create, edit and delete parcels. And to see the details of the parcel, click on Details from the dropdown. And click on Parcel Log to see the parcel log. If you need to create the same parcel again, click on Clone to change something, you can create a changed parcel.
                        And from the list of parcels you can find the parcels by filtering.
                    </p>
                    <h4 className='pt-3'   >Parcel List</h4>
                    <div className='pt-3'>
                        <img src={ParcelsImg} className="w-100 rounded" alt="parcels.png"></img>
                    </div>
                    <h4 className='pt-3' id='add-parcel' ref={pagesectionRefs[1]}>Add Parcel</h4>
                    <div className='pt-3'>
                        <img src={ParcelAddImg} className="w-100 rounded" alt="Addparcels.png"></img>
                    </div>
                    <h4 className='pt-3' id='parcel-view' ref={pagesectionRefs[2]}>Parcel view</h4>
                    <div className='pt-3'>
                        <img src={ParcelDetailsImg} className="w-100 rounded" alt="parcelDetails.png"></img>
                    </div>
                    <div id='parcel-logs' ref={pagesectionRefs[3]}>
                        <h4 className='pt-3' >Parcel Logs</h4>
                        <div className='pt-3 mb-3'>
                            <img src={ParcelLogsImg} className="w-100 rounded" alt="parcelslogs.png"></img>
                        </div>
                        <p><b>Import : </b> Parcel can be created by uploading excel file so click on Parcel Import. And an example file will be available, first download that file. After adding the parcel to the downloaded file and uploading it, clicking on submit, those parcels can be created.</p>
                        <h4 className='pt-3'>Import Parcel</h4>
                        <div className='pt-3'>
                            <img src={ImportParcelImg} className="w-100 rounded" alt="ImportParcels.png"></img>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/merchant/shops`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Pickup Pints</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/merchant/reports`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Reports</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className='right-menu'>
                <ul>
                    <li><a href='#parcel-list' className={activeSection == 0 ? 'active-has' : ""}> Parcel List </a></li>
                    <li><a href='#add-parcel' className={activeSection == 1 ? 'active-has' : ""}> Add Parcel</a></li>
                    <li><a href='#parcel-view' className={activeSection == 2 ? 'active-has' : ""}> Parcel View</a></li>
                    <li><a href='#parcel-logs' className={activeSection == 3 ? 'active-has' : ""}> Parcel Logs</a></li>
                </ul>
            </div>
        </div>
    )
}
