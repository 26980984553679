import React, { useEffect, useContext } from 'react'
import ScrollToTop from './../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import MerchantSignupImg from './../../assets/images/module/auth/merchant-sign-up.png';
import { ProjectTypeContext } from '../../ContextHandle';
export default function Merchantsignup({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1006);
        document.title = 'Merchant signup | ' + projectName + ' web';
        setDefaultWebActiveMenu('1');
    }, [projectName]);
 
    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">Authentication</li>
                        <li className="breadcrumb-item active" aria-current="page">Merchant Signup</li>
                    </ol>
                </nav>
                <div id="accounts" ref={sectionRefs['51']}>
                    <h4 className="font-weight-bold mt-5  ">Merchant signup</h4>
                    <p >Merchant should sign up from company's domain for example: <b>https://company.wemaxdevs.com/merchant/sign-up</b> . A merchant must signup as a merchant to login. After going to the sign up page, a form will appear to sign up, fill that form. All the blanks that must be filled are - Business name, Full name, select hub, Mobile, Password, Address and click on register. Once the account is created, you can login. </p>
                    <div className='pt-3'>
                        <img src={MerchantSignupImg} className="w-100 rounded" alt="newsoffers.png"></img>
                    </div>
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/company-signup`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Company Signup</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/forgot-password`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Forgot Password</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
