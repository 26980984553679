import React, { useEffect, useRef, useContext } from 'react'
import ScrollToTop from './../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import parcelStatusReports from './../../../assets/images/module/merchant-panel/reports/parcel-status-reports.png';
import accountTransaction from './../../../assets/images/module/merchant-panel/reports/account-transaction-reports.png';
import statements from './../../../assets/images/module/merchant-panel/reports/statements-reports.png';
import totalSummery from './../../../assets/images/module/merchant-panel/reports/total-summery-reports.png';
import useScrollSpy from 'react-use-scrollspy';
import { ProjectTypeContext } from '../../../ContextHandle';
export default function Reports({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
 /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1030);
        document.title = 'Reports | ' + projectName + ' web';
        setDefaultWebActiveMenu(3);
    }, [projectName]);

    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });
    return (
        <div className='d-flex justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">

                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">Merchant Panel</li>
                        <li className="breadcrumb-item active" aria-current="page">Reports</li>
                    </ol>
                </nav>
                <div id="accounts"  >

                    <h4 className="  mt-5 font-weight-bold">Reports</h4>

                    <div id='parcel-status-report' ref={pagesectionRefs[0]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Parcels status reports</h5>
                        <p className="pt-3">With the Parcel Status Report, select the date or status for which the number of parcels will be displayed in each status. </p>
                        <div className='pt-3'>
                            <img src={parcelStatusReports} className="w-100 rounded" alt="parcelstatusreports.png"></img>
                        </div>
                    </div>

                    <div id='total-summery' ref={pagesectionRefs[1]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Total summery</h5>
                        <p className="pt-3">Select date through Total Summery module and click on report button to display complete summary. Summary includes parcel status, profit information, cash collection information, merchant payable, bank cash information and etc. </p>
                        <div className='pt-3'>
                            <img src={totalSummery} className="w-100 rounded" alt="totalSummery.png"></img>
                        </div>
                    </div>

                    <div id='account-transaction' ref={pagesectionRefs[2]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Account Transaction</h5>
                        <p className="pt-3"> Through the account transaction module, all the transactions related to the merchant's bank can be seen. </p>
                        <div className='pt-3'>
                            <img src={accountTransaction} className="w-100 rounded" alt="AccountTransaction"></img>
                        </div>
                    </div>

                    <div id='statements' ref={pagesectionRefs[3]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Statements</h5>
                        <p className="pt-3">With Tracking ID through Statements module Statements of each parcel of the merchant can be seen.</p>
                        <div className='pt-3'>
                            <img src={statements} className="w-100 rounded" alt="statements"></img>
                        </div>
                    </div>

                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/merchant/parcels`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Parcels</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/merchant/settings`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Settings</h4>
                        </NavLink>
                    </div>
                </div>
            </div>

            <div className='right-menu'>
                <ul>
                    <li><a href='#parcel-status-report' className={activeSection == 0 ? 'active-has' : ""}> Parcels status reports </a></li>
                    <li><a href='#total-summery' className={activeSection == 1 ? 'active-has' : ""}> Total summery</a></li>
                    <li><a href='#account-transaction' className={activeSection == 2 ? 'active-has' : ""}> Account Transaction</a></li>
                    <li><a href='#statements' className={activeSection == 3 ? 'active-has' : ""}> Statements</a></li>
                </ul>
            </div>

        </div>
    )
}
