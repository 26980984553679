import React, { useEffect, useRef, useContext } from 'react'
import ScrollToTop from './../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import UsersImg from './../../../assets/images/module/admin-panel/users-roles/user-list.png';
import addUserImg from './../../../assets/images/module/admin-panel/users-roles/user-create.png';
import DesignationImg from './../../../assets/images/module/admin-panel/users-roles/designation-list.png';
import DesignationCreateImg from './../../../assets/images/module/admin-panel/users-roles/designation-create.png';
import DepartmentImg from './../../../assets/images/module/admin-panel/users-roles/department-list.png';
import DepartmentCreateImg from './../../../assets/images/module/admin-panel/users-roles/department-create.png';
import RolesImg from './../../../assets/images/module/admin-panel/users-roles/roles-list.png';
import RolesCreateImg from './../../../assets/images/module/admin-panel/users-roles/roles-create.png';
import useScrollSpy from 'react-use-scrollspy';
import { ProjectTypeContext } from '../../../ContextHandle';
export default function UsersRoles({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
     /* eslint-disable */

    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });

    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    const panelName   = projectType.panelName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1018);
        document.title = 'Users & Roles | ' + projectName + ' web';
        setDefaultWebActiveMenu(2);
    }, [projectName]);
    return (
        <div className='d-flex  justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                        <li className="breadcrumb-item active" aria-current="page">Users & Roles</li>
                    </ol>
                </nav>
                <div id="usersroles" ref={sectionRefs['17']}>

                    <h4 className="font-weight-bold mt-5  ">User & Roles</h4>

                    <div id='roles' ref={pagesectionRefs[0]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Roles</h5>
                        <p className=" pt-3">Different types of roles can be created through the roles module and permissions can be given while creating roles. The list of roles can be created, edited, deleted from the role module. To create a role, click on the plus sign from the list of roles and a form will appear and click on the submit button with the name and permissions in that form. And can register by selecting that role.</p>
                        <h5 className="font-weight-bold mt-2"> Roles List</h5>
                        <div className='pt-3'>
                            <img src={RolesImg} className="w-100 rounded" alt="Roles.png"></img>
                        </div>
                        <h5 className="font-weight-bold mt-2"> Roles Create</h5>
                        <div className='pt-3'>
                            <img src={RolesCreateImg} className="w-100 rounded" alt="RolesCreate.png"></img>
                        </div>
                    </div>
                    <div id='designations' ref={pagesectionRefs[1]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Designations</h5>
                        <p className=" pt-3">Designations can be created through the Designations module. List of designations, who created them, can edit and delete them. To create a designation. Clicking on the plus sign from the list of designations will display the designation creation form and fill it and click on the submit button to create it.</p>
                        <h5 className="  font-weight-bold mt-2  "> Designation list</h5>
                        <div className='pt-3'>
                            <img src={DesignationImg} className="w-100 rounded" alt="Designations.png"></img>
                        </div>
                        <h5 className="font-weight-bold mt-2"> Designation Create</h5>
                        <div className='pt-3'>
                            <img src={DesignationCreateImg} className="w-100 rounded" alt="DesignationCreate.png"></img>
                        </div>
                    </div>
                    <div id='departments' ref={pagesectionRefs[2]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Departments</h5>
                        <p className=" pt-3"> Departments can be created through the Departments module. List of Departments, who created them, can edit and delete them. To create a Department. Clicking on the plus sign from the list of Departments will display the Department creation form and fill it and click on the submit button to create it.</p>
                        <h5 className="font-weight-bold mt-2"> Department List</h5>
                        <div className='pt-3'>
                            <img src={DepartmentImg} className="w-100 rounded" alt="Departments.png"></img>
                        </div>
                        <h5 className="font-weight-bold mt-2"> Department Create</h5>
                        <div className='pt-3'>
                            <img src={DepartmentCreateImg} className="w-100 rounded" alt="DepartmentCreate.png"></img>
                        </div>
                    </div>
                    <div id='users' ref={pagesectionRefs[3]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Users</h5>
                        <p className=" pt-3">Users can be created through the users module. Users of different roles can be created. To create a user click on the user module to see the list of users from there click on the plus sign to display the user creation form. And after filling the form and clicking on the submit button, the user account will be created.
                            You can edit, delete and give other permissions to the user from the list of users.</p>
                        <h4 className=" pt-3">User list</h4>
                        <div className='pt-3'>
                            <img src={UsersImg} className="w-100 rounded" alt="Userslist.png"></img>
                        </div>
                        <h4 className=" pt-3">Add User</h4>
                        <div className='pt-3'>
                            <img src={addUserImg} className="w-100 rounded" alt="addUser.png"></img>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/accounts`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Accounts</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/reports`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Reports</h4>
                        </NavLink>
                    </div>
                </div>
            </div>

            <div className='right-menu'>
                <ul>
                    <li><a href='#roles' className={activeSection == 0 ? 'active-has' : ""}>Roles </a></li>
                    <li><a href='#designations' className={activeSection == 1 ? 'active-has' : ""}>Designations</a></li>
                    <li><a href='#departments' className={activeSection == 2 ? 'active-has' : ""}>Departments</a></li>
                    <li><a href='#users' className={activeSection == 3 ? 'active-has' : ""}>Users</a></li>
                </ul>
            </div>

        </div>
    )
}
