import React, { useEffect, useRef, useContext } from 'react'
import ScrollToTop from './../../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import ProfitReports from '../../../../assets/images/weerp/web-image/branch/reports.png';
import useScrollSpy from 'react-use-scrollspy';
import { ProjectTypeContext } from '../../../../ContextHandle';
export default function Reports({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {

    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    /* eslint-disable */
    useEffect(() => {
        setActivePage(1031);
        document.title = 'Reports | ' + projectName + ' web';
        setDefaultWebActiveMenu(3);
    }, [projectName]);
    /* eslint-enable */
    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });
    return (
        <div className='d-flex justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">

                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">Branch Panel</li>
                        <li className="breadcrumb-item active" aria-current="page">Reports</li>
                    </ol>
                </nav>
                <div id="accounts"  >

                    <h4 className="  mt-5 font-weight-bold">Reports</h4>

                    <div id='parcel-status-report' ref={pagesectionRefs[0]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Reports</h5>
                        <p>All types of reports can be viewed from the report module. Reports that can be viewed -
                            Attendance, Profit loss, Product sale profit, Product pos profit, Expense report, Stock report, Customer sale report, Customer pos report, Purchase report, Purchase Return, Sale report, Pos report, Service sale report and Supplier report  can be viewed.</p>


                        <p className="pt-3"><strong>Profit loss reports:</strong>Total sales, Total POS sales, Total purchase, total purchase return can be seen also payments.  Branch wise Total Gross Profit, Net Profit can be seen </p>

                        <div className='pt-3'>
                            <img src={ProfitReports} className="w-100 rounded" alt="reports.png"></img>
                        </div>

                        <p className='mt-3'><strong>Attendance reports: </strong> How many days were present and how many days were absent and how many days were holidays can be seen in the report.</p>
                        <p><strong>Customer Sales Report: </strong> The report will show how much the customer is buying the product and how much they are paying and how much is due.</p>

                        <p><strong>Sales Reports: </strong>Reports can be found on sales by filtering by date. How much money has been paid and how much money is due can also be seen.</p>


                        <p className="pt-3"><strong>Purchase Reports:</strong> Reports can be found on purchase by filtering by date. How much money has been paid and how much money is due can also be seen.</p>

                    </div>
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/branch/hr-management`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>HR Management</h4>
                        </NavLink>
                    </div>

                </div>
            </div>

            <div className='right-menu'>
                <ul>
                    {/* <li><a href='#parcel-status-report' className={activeSection == 0 ? 'active-has' : ""}> Parcels status reports </a></li> */}
                </ul>
            </div>

        </div>
    )
}
