import React, { useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import ScrollToTop from '../../Shared/ScrollToTop';
import PayoutImg from './../../../assets/images/module/admin-panel/payout/payout-list.png';
import PayoutCreateImg from './../../../assets/images/module/admin-panel/payout/payout-create.png';
import { ProjectTypeContext } from '../../../ContextHandle';
const Payout = ({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) => {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    const panelName   = projectType.panelName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1016);
        document.title = 'Payout |  ' + projectName + '  web';
        setDefaultWebActiveMenu(2);
    }, [projectName]);
    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                        <li className="breadcrumb-item active" aria-current="page">Payout</li>
                    </ol>
                </nav>
                <div id="parcels" ref={sectionRefs['37']}>

                    <h4 className="font-weight-bold mt-5  ">Payout</h4>
                    <p className="">Payments can be made online to specific merchants through the Payout module. And there are many payment gateways for payment.</p>
                    <p className=""> The payment gateways are – <b>PayPal , Stripe , Skrill, sslCommerz, amarpay, bkash, Razorpay</b> and etc.</p>
                    <p className="">After making the payment to the merchant, the list of all the payments that will be made will also be displayed.</p>


                    <h4 className=" pt-3">Payout  List </h4>
                    <div className='pt-3'>
                        <img src={PayoutImg} className="w-100 rounded" alt="Parcel Lists"></img>
                    </div>
                    <h4 className=" pt-3">Payout  Create </h4>
                    <div className='pt-3'>
                        <img src={PayoutCreateImg} className="w-100 rounded" alt="Parcel Create"></img>
                    </div>

                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/parcels`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Parcels</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/accounts`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Accounts</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Payout;
