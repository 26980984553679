import React, { useEffect, useContext } from 'react'
import ScrollToTop from './../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import SupportImg from './../../../assets/images/module/admin-panel/support/support-list.png';
import AddSupportImg from './../../../assets/images/module/admin-panel/support/support-create.png';
import SupportViewImg from './../../../assets/images/module/admin-panel/support/support-view.png';
import { ProjectTypeContext } from '../../../ContextHandle';
export default function Support({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    const panelName   = projectType.panelName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1014);
        document.title = 'Ticket | ' + projectName + ' web';
        setDefaultWebActiveMenu(2);
    }, [projectName]);
    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                        <li className="breadcrumb-item active" aria-current="page">Ticket</li>
                    </ol>
                </nav>
                <div id="support" ref={sectionRefs['14']}>

                    <h4 className=" mt-5  font-weight-bold">Ticket</h4>

                    <p  >Ticket can be created for user through the Ticket module. To create a Ticket click on plus sign from Ticket module a form will be displayed select service priority department in that form and fill other fields click on submit button the Ticket will be created. And if you click on view from the list of Ticket, you can see all the information of Ticket. And replays can also be provided.</p>
                    <h4 className=" pt-3">Ticket List</h4>
                    <div className='pt-3'>
                        <img src={SupportImg} className="w-100 rounded" alt="Ticket.png"></img>
                    </div>
                    <h4 className=" pt-3">Add Ticket List</h4>
                    <div className='pt-3'>
                        <img src={AddSupportImg} className="w-100 rounded" alt="AddSupport.png"></img>
                    </div>
                    <h4 className=" pt-3">View Ticket</h4>
                    <div className='pt-3'>
                        <img src={SupportViewImg} className="w-100 rounded" alt="ViewTicket.png"></img>
                    </div>
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/todo-list`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Todo List</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/parcels`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Parcels</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
