import React, { useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import '../../../assets/images/codestyle.css';
import ScrollToTop from '../../Shared/ScrollToTop';
import { ProjectTypeContext } from '../../../ContextHandle'
const Installation = ({ setActivePage, sectionRefs, setDocumentation, setDefaultAppActiveMenu }) => {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(502);
        setDocumentation(2);
        setDefaultAppActiveMenu(1);
        document.title = 'Installation | ' + projectName;
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);



    return (
        <div className='d-flex   justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div id="system-requirements  " className='pt-5 right-content-box-width' >
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">App Basics</li>
                        <li className="breadcrumb-item active" aria-current="page">Installation</li>
                    </ol>
                </nav>

                <h3 className="pt-5 font-weight-bold">Installation</h3>

                <ul className='py-3'>
                    <li>
                        <p>Android Studio Installation <b>{`(`}Windows{`)`}</b></p>
                        <iframe width="850" height="478" src="https://www.youtube.com/embed/0zx_eFyHRU0" title="How to Install Android Studio on Windows 10" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </li>
                    <li className='mt-3'>
                        <p>Android Studio Installation<b>{`(`}Mac{`)`} </b></p>
                        <iframe width="850" height="638" src="https://www.youtube.com/embed/rNUoFXxT30U" title="How to download and  install Java JDK on Mac OS X  and Android Studio ( with JAVA_HOME )" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </li>
                </ul>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/app/recommendation`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Recommendation</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/app/prepare-flutter-environment`} className="text-decoration-none">
                            <a href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Prepare Flutter Environment</h4>
                        </NavLink>
                    </div>
                </div>
            </div>



        </div>

    );

};

export default Installation;
