import React, { useEffect, useRef, useContext } from 'react'
import ScrollToTop from './../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import ProfileImg from './../../../assets/images/module/admin-panel/admin-profile/admin-profile.png';
import ProfileUpdateImg from './../../../assets/images/module/admin-panel/admin-profile/admin-profile-update.png';
import PasswordChangeImg from './../../../assets/images/module/admin-panel/admin-profile/admin-change-password.png';
import useScrollSpy from 'react-use-scrollspy';
import { ProjectTypeContext } from '../../../ContextHandle';
export default function Profile({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
 /* eslint-disable */
    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });

    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    const panelName   = projectType.panelName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1009);
        document.title = 'Profile | ' + projectName + ' web';
        setDefaultWebActiveMenu(2);
    }, [projectName]);
    return (
        <div className='d-flex   justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                        <li className="breadcrumb-item active" aria-current="page">Profile</li>
                    </ol>
                </nav>
                <div id="profile" ref={sectionRefs['5']}>
                    <h4 className=" mt-5 font-weight-bold " id='profile' ref={pagesectionRefs[0]}>Profile</h4>
                    <div className='pt-3'>
                        <img src={ProfileImg} className="w-100 rounded" alt="Upload File"></img>
                    </div>
                    <h4 className="   pt-5" id='profile-update' ref={pagesectionRefs[1]}>Profile Update</h4>
                    <div className='pt-3 mb-3'>
                        <img src={ProfileUpdateImg} className="w-100 rounded" alt="Upload File"></img>
                    </div>

                    <h4 className=" pt-3" id='password-change' ref={pagesectionRefs[2]}>Password Change</h4>
                    <div className='pt-3 mb-2'>
                        <img src={PasswordChangeImg} className="w-100 rounded" alt="Upload File"></img>
                    </div>

                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/dashboard`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Dashboard</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/deliveryman`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Delivery Man</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className='right-menu'>
                <ul >
                    <li><a href='#profile' className={activeSection == 0 ? 'active-has' : ""}>Profile</a></li>
                    <li><a href='#profile-update' className={activeSection == 1 ? 'active-has' : ""}>Profile Update</a></li>
                    <li><a href='#password-change' className={activeSection == 2 ? 'active-has' : ""}>Password Change</a></li>
                </ul>
            </div>
        </div>
    )
}
