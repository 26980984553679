import React, { useEffect, useRef, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import '../../../assets/images/codestyle.css';
import useScrollSpy from 'react-use-scrollspy';
import ScrollToTop from '../../Shared/ScrollToTop';
import Img1 from '../../../assets/images/app-image/firebase-notification/home1.png';
import Img2 from '../../../assets/images/app-image/firebase-notification/firbase-project2.png';
import Img3 from '../../../assets/images/app-image/firebase-notification/firebase3.png';
import Img4 from '../../../assets/images/app-image/firebase-notification/fcm-server-key4.png';
import Img5 from '../../../assets/images/app-image/firebase-notification/firebase5.png';
import Img6 from '../../../assets/images/app-image/firebase-notification/google-serverjson6.png';
import { ProjectTypeContext } from '../../../ContextHandle'
const GoogleFirebaseSetup = ({ setActivePage, sectionRefs, setDocumentation, setDefaultAppActiveMenu }) => {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(505);
        setDocumentation(2);
        setDefaultAppActiveMenu(2);
        document.title = 'Push Notification Setup | ' + projectName;
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);


    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });



    return (
        <div className='d-flex   justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div id="system-requirements  " className='pt-5 right-content-box-width' >
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">Common Setup</li>
                        <li className="breadcrumb-item active" aria-current="page">Push Notification Setup</li>
                    </ol>
                </nav>

                <h3 className="pt-5 font-weight-bold" ref={pagesectionRefs[0]}>Push Notification Setup</h3>
                <p className='pt-3'><b>To use firebase follow the procedure which are mentioned below</b></p>
                <ol>
                    <li id='step1' className='py-3' ref={pagesectionRefs[0]}>
                        Go to this URL to create project <Nav.Link rel="noreferrer" className='d-inline-block p-0' href="https://console.firebase.google.com/u/0/" target="_blank" >https://console.firebase.google.com/u/0/</Nav.Link> If you already have a project then continue with that.

                        <div className='pt-3'>
                            <img src={Img1} className="w-100 rounded" alt="Home"></img>
                        </div>
                    </li>
                    <li id='step2' className='py-3' ref={pagesectionRefs[1]}> Now go to project settings to get server key
                        <div className='pt-3'>
                            <img src={Img2} className="w-50 rounded" alt="Home"></img>
                        </div>
                    </li>
                    <li id='step3' className='py-3' ref={pagesectionRefs[2]}> To get server key click on Cloud Messaging option
                        <div className='pt-3'>
                            <img src={Img3} className="w-100 rounded" alt="Home"></img>
                        </div>
                    </li>
                    <li id='step4' className='py-3' ref={pagesectionRefs[3]}>   Turn on the switch and put the server key in your web .env file
                        <div className='pt-3'>
                            <img src={Img4} className="w-100 rounded" alt="Home"></img>
                        </div>
                    </li>
                    <li className='py-3' ref={pagesectionRefs[4]}>  You will need to generate your own google-services.json. Do not use ours – it will not work for you
                        <div className='pt-3' id='step5'>
                            <img src={Img5} className="w-50 rounded" alt="Home"></img>
                        </div>
                        <div className='pt-3'>
                            <img src={Img6} className="w-100 rounded" alt="Home"></img>
                        </div>
                    </li>
                </ol>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/app/google-firebase-setup`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Google Firebase Setup</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/app/change-api-server-details`} className="text-decoration-none">
                            <a href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Change api servier details</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className='right-menu'>
                <ul >
                    <li><a href='#step1' className={activeSection == 0 ? 'active-has' : ""}>Go to firebase console</a></li>
                    <li><a href='#step2' className={activeSection == 1 ? 'active-has' : ""}>Go to project setting</a></li>
                    <li><a href='#step3' className={activeSection == 2 ? 'active-has' : ""}>Get server key</a></li>
                    <li><a href='#step4' className={activeSection == 3 ? 'active-has' : ""}>Put the server key in your web .env file</a></li>
                    <li><a href='#step5' className={activeSection == 4 ? 'active-has' : ""}>Generate your own google-services.json.</a></li>
                </ul>
            </div>

        </div>

    );

};

export default GoogleFirebaseSetup;
