import React, { useEffect, useRef, useContext } from 'react'
import ScrollToTop from './../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import parcelStatusReports from './../../../assets/images/module/admin-panel/reports/parcel-status-reports.png';
import parcelWiseProfit from './../../../assets/images/module/admin-panel/reports/parcel-wise-profit.png';
import salaryReports from './../../../assets/images/module/admin-panel/reports/salary-reports.png';
import mhdMerchantReports from './../../../assets/images/module/admin-panel/reports/merchant-reports.png';
import mhdHubReports from './../../../assets/images/module/admin-panel/reports/hub-reports.png';
import mhdDeliverymanReports from './../../../assets/images/module/admin-panel/reports/deliveryman-reports.png';
import totalSummery from './../../../assets/images/module/admin-panel/reports/total-summery.png';
import useScrollSpy from 'react-use-scrollspy';
import { ProjectTypeContext } from '../../../ContextHandle';
export default function Reports({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
 /* eslint-disable */
    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });

    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    const panelName   = projectType.panelName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1019);
        document.title = 'Reports | ' + projectName + ' web';
        setDefaultWebActiveMenu(2);
    }, [projectName]);
    return (
        <div className='d-flex justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                        <li className="breadcrumb-item active" aria-current="page">Reports</li>
                    </ol>
                </nav>
                <div id="reports" >
                    <h4 className=" mt-5 font-weight-bold">Reports</h4>

                    <div id='parcel-status-report' ref={pagesectionRefs[0]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Parcels status reports</h5>
                        <div className='pt-3'>
                            <img src={parcelStatusReports} className="w-100 rounded" alt="ParcelStatusReports.png"></img>
                        </div>
                        <p className="pt-3">Through parcel status reports, the number of parcels in each status can be displayed by selecting the merchant or date or branch. </p>
                    </div>

                    <div id='parcel-wise-profit' ref={pagesectionRefs[1]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Parcel wise profit</h5>
                        <p className="pt-3"> Details of any parcel can be viewed by selecting the date or merchant or branch with its tracking ID. </p>
                        <div className='pt-3'>
                            <img src={parcelWiseProfit} className="w-100 rounded" alt="parcelWiseProfit.png"></img>
                        </div>
                    </div>
                    <div id='salary-reports' ref={pagesectionRefs[2]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Salary Reports</h5>
                        <p className="pt-3">Report specific deficits through payroll reports
                            will be seen How much money has been paid to him and how much money is due and in which month money has been paid and in which month money is due. </p>
                        <div className='pt-3'>
                            <img src={salaryReports} className="w-100 rounded" alt="SalaryReports.png"></img>
                        </div>
                    </div>
                    <div id='mhd' ref={pagesectionRefs[3]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Merchant / Branch / Delivery man Reports</h5>
                        <p className="pt-3">Through this module you will see complete report of merchant or Branch or delivery man. To see which date report is required select the date and select the user type. After selecting the user type, the option to select the user name will appear. From there select the user and click on the filter button to view the report. </p>
                        <h4 className=" pt-3">Merchant Reports</h4>
                        <div className='pt-3'>
                            <img src={mhdMerchantReports} className="w-100 rounded" alt="Merchantreports.png"></img>
                        </div>
                        <h4 className=" pt-3">Branch Reports</h4>
                        <div className='pt-3'>
                            <img src={mhdHubReports} className="w-100 rounded" alt="Hubreports.png"></img>
                        </div>
                        <h4 className=" pt-3">Deliveryman Reports</h4>
                        <div className='pt-3'>
                            <img src={mhdDeliverymanReports} className="w-100 rounded" alt="Deliverymanreports.png"></img>
                        </div>
                    </div>
                    <div id='total-summery' ref={pagesectionRefs[4]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Total summery</h5>
                        <p className="pt-3">Full summery of selected user can be seen by selecting date or merchant or branch through Total summery module. Summery includes parcel status, profit information, cash collection information, payable to merchant, bank cash information and etc. </p>
                        <div className='pt-3'>
                            <img src={totalSummery} className="w-100 rounded" alt="totalSummery.png"></img>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/users-roles`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Users & Roles</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/push-notification`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Push Notification</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className='right-menu'>
                <ul>
                    <li><a href='#parcel-status-report' className={activeSection == 0 ? 'active-has' : ""}> Parcels status reports </a></li>
                    <li><a href='#parcel-wise-profit' className={activeSection == 1 ? 'active-has' : ""}> Parcel wise profit</a></li>
                    <li><a href='#salary-reports' className={activeSection == 2 ? 'active-has' : ""}> Salary reports</a></li>
                    <li><a href='#mhd' className={activeSection == 3 ? 'active-has' : ""}> Merchant/Branch/Deliveryman Reports</a></li>
                    <li><a href='#total-summery' className={activeSection == 4 ? 'active-has' : ""}> Total summery</a></li>
                </ul>
            </div>

        </div>
    )
}
