import React, { useEffect, useRef, useContext } from 'react'
import ScrollToTop from './../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import merchantListImg from './../../../assets/images/module/admin-panel/merchantmanage/merchant-list.png';
import addMerchant from './../../../assets/images/module/admin-panel/merchantmanage/merchant-create.png';
import view from './../../../assets/images/module/admin-panel/merchantmanage/merchant-view.png';
import shops from './../../../assets/images/module/admin-panel/merchantmanage/merchant-pickup-points.png';
import deliverycharge from './../../../assets/images/module/admin-panel/merchantmanage/merchant-delivery-charge.png';
import paymentAccounts from './../../../assets/images/module/admin-panel/merchantmanage/merchant-payment-account.png';
import payment from './../../../assets/images/module/admin-panel/merchantmanage/merchant-payment.png';
import Invoice from './../../../assets/images/module/admin-panel/merchantmanage/merchant-invoice.png';
import InvoiceDetails from './../../../assets/images/module/admin-panel/merchantmanage/merchant-invoice-details.png';
import useScrollSpy from 'react-use-scrollspy';
import { ProjectTypeContext } from '../../../ContextHandle';
export default function MerchantManage({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
 /* eslint-disable */
    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });

    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    const panelName   = projectType.panelName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1012);
        document.title = 'Merchant manage | ' + projectName + '  web';
        setDefaultWebActiveMenu(2);
    }, [projectName]);
    return (
        <div className='d-flex  justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                        <li className="breadcrumb-item active" aria-current="page">Merchant manage</li>
                    </ol>
                </nav>
                <div id="merchantmanage" >

                    <div id='merchant' ref={pagesectionRefs[0]}>
                        <h5 className=" font-weight-bold mt-5  ">Merchant</h5>
                        <p className="pt-3">Merchants can be created, listed, edited, and deleted from the merchant module. To create a merchant a form will be displayed by clicking on the plus sign from the merchant module fill that form and click on the submit button. The merchant will be ready.By clicking on merchant view, the merchant profile will be displayed. And there are modules for creating delivery charges for bankers, merchant stores, and banker payment accounts.</p>
                        <h4 className=" pt-3">Merchant list</h4>
                        <div className='pt-3'>
                            <img src={merchantListImg} className="w-100 rounded" alt="MerchantList.png"></img>
                        </div>
                        <h4 className=" pt-3">Add Merchant</h4>
                        <div className='pt-3 mb-3'>
                            <img src={addMerchant} className="w-100 rounded" alt="addMerchant.png"></img>
                        </div>
                    </div>
                    <div id='merchantview' ref={pagesectionRefs[1]}>
                        <h5 className="font-weight-bold text-primary pt-3"># Merchant View</h5>
                        <div className='pt-3 mb-3'>
                            <img src={view} className="w-100 rounded" alt="view.png"></img>
                        </div>
                        <p>
                            <b>Delivery charges :</b>
                        </p>
                        <div className='pt-3 mb-3'>
                            <img src={deliverycharge} className="w-100 rounded" alt="deliverycharge.png"></img>
                        </div>
                        <p><b>Pickup Points :</b>  </p>
                        <div className='pt-3 mb-3'>
                            <img src={shops} className="w-100 rounded" alt="Pickup point.png"></img>
                        </div>
                        <p>
                            <b>Payments Account : </b>
                        </p>
                        <div className='pt-3'>
                            <img src={paymentAccounts} className="w-100 rounded" alt="PaymentAccounts.png"></img>
                        </div>
                        <p className='pt-3'>
                            <b>Invoice : </b>
                        </p>
                        <div className='pt-3'>
                            <img src={Invoice} className="w-100 rounded" alt="Invoice.png"></img>
                        </div>
                        <h5 className='mt-3'>
                            <b>Invoice Details:</b>
                        </h5>
                        <div className='pt-3'>
                            <img src={InvoiceDetails} className="w-100 rounded" alt="InvoiceDetails.png"></img>
                        </div>

                    </div>
                    <div id='payments' ref={pagesectionRefs[2]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Payments</h5>
                        <p className="pt-3">Payment can be made to the merchant through the merchant payment module, but remember that payment cannot be made if there is not money in the account. Click on the plus sign to make a payment and a payment form will appear there. Select the merchant and enter the amount. Now if you don't want to make the payment then you don't need to tick is processed just click on the submit button and the payment will be made. If you want to make a payment at the same time, then tick the is processed and click on the submit button with the transaction id and from account, the payment will be processed. Or if you don't do is processed now, then click on is processed from the list of payments, tracking id, accounts payment form will be displayed, fill everything and click on submit button, payments will be processed.</p>
                        <h4 className=" pt-3">Payment list</h4>
                        <div className='pt-3'>
                            <img src={payment} className="w-100 rounded" alt="payment.png"></img>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between border-top mt-5">
                        <div className="text-start mt-4">
                            <NavLink to={`/${projectType}/hub-manage`} className="text-decoration-none">
                                <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                                <h4 className='pagination-title'>Branch Manage</h4>
                            </NavLink>
                        </div>
                        <div className="text-end my-2">
                            <NavLink to={`/${projectType}/todo-list`} className="text-decoration-none">
                                <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                                <h4 className='pagination-title'>Todo List</h4>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className='right-menu'>
                <ul >
                    <li><a href='#merchant' className={activeSection == 0 ? 'active-has' : ""}>Merchant</a></li>
                    <li><a href='#merchantview' className={activeSection == 1 ? 'active-has' : ""}>Merchant view</a></li>
                    <li><a href='#payments' className={activeSection == 2 ? 'active-has' : ""}>Payments</a></li>
                </ul>
            </div>
        </div>
    )
}
