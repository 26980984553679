import React ,{useContext, useState}from 'react'; 
import Navbar from './Shared/Navbar';
import '../Home/Home.css'
import { Link, Outlet } from "react-router-dom";
import Footer from '../Shared/Footer';
import { Nav } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import { IoGridOutline  } from 'react-icons/io5';
import AppNavbar from './Shared/AppNavbar';
import {ProjectTypeContext}  from '../../ContextHandle'
 
const Home = ({ 
    activeSection, 
    sectionRefs, 
    activePage , 
    documentation,
    setDocumentation,
    defaultAppActiveMenu,
    defaultWebActiveMenu
}) => {
 /* eslint-disable */
    const [NavIsActive,setNavIsActive] = useState(false);
    var projectType = useContext(ProjectTypeContext); 
         projectType = projectType.projectType; 
    const logo       =  '/'+projectType+'-logo.png'; 
 
    let link = document.querySelector("link[rel~='icon']"); 
    var favicon = `/weerp-favicon.png`; 
    link.setAttribute('href', favicon);  
   
    return (
        <div>
            <div className="d-flex weerp-navigation">
                {/* Left Side */}
                <div className={`${NavIsActive ? "is-active":""} left-side border-end p-4 pb-0 `}>
                    <div className="fixed-top sidebar h-100">
                        <div className='menu-close position-relative'>
                            <button style={{ right:'5%' }} type='button' className='menu-btn position-absolute' onClick={()=>NavIsActive ? setNavIsActive(false):setNavIsActive(true)}>
                                {NavIsActive ? <IoMdClose className='grid-icon'/>:<span className="navbar-toggler-icon"></span>}
                            </button>
                        </div>
                        <div className="px-3"> 
                            <Link to={documentation === 2 ? `/${projectType}`:`/${projectType}/introduction`} className='text-decoration-none' style={{ color: '#5254cf' }}>
                                <h4 className="text-left py-4" style={{ 'paddingLeft':"0px" }}>
                                      <img src={logo} alt="logo" style={{ "width":"200px" }}></img>
                                 </h4>
                            </Link> 
                        </div>
                        {/* Sidebar Menu Start */}
                           {documentation && documentation === 2 ?
                                <AppNavbar
                                    sectionRefs={sectionRefs}
                                    activeSection={activeSection}
                                    activePage={activePage}
                                    defaultAppActiveMenu={defaultAppActiveMenu}
                                ></AppNavbar>:
                                <Navbar
                                    sectionRefs={sectionRefs}
                                    activeSection={activeSection}
                                    activePage={activePage}
                                    setDocumentation={setDocumentation}
                                    defaultWebActiveMenu={defaultWebActiveMenu}
                                ></Navbar>
                            }
                        {/* Sidebar menu end */}
                      
                    </div>
                </div>
                {/* =============================== Page content Start Here ================================= */}
                <div className="right-side flex-grow-1" tabIndex={0}>
                    {/* Item 2  */}
                    <div id="right-content" className="px-5 right-content-box">
                        <div className='close-btn-box '>
                            <button type='button' className='menu-btn d-inline-block me-2' onClick={()=>NavIsActive ? setNavIsActive(false):setNavIsActive(true)}>
                            {NavIsActive ? <IoMdClose/>:<span className="navbar-toggler-icon"></span>}
                            </button>
                            <button type='button' className='docs-btn d-inline-block me-3'   data-bs-toggle="modal" data-bs-target="#documentation-list" >
                                  <IoGridOutline className='grid-icon ms-2' style={{ color:'#4e4c9f' }}/> 
                            </button>
                            <div className='d-inline-block docs-menus'>
                                <Nav.Link href={`/${projectType}`} className={`d-inline-block font-weight-bold app-nav ${documentation && documentation === 2? 'text-primary':'text-dark'}`}><span className='d-none d-md-block'>App Documentation</span><span className="d-md-none">App Doc</span></Nav.Link>
                                <Nav.Link href={`/${projectType}/introduction`} className={`d-inline-block font-weight-bold ${documentation && documentation === 2? 'text-dark':'text-primary'}`}><span className='d-none d-md-block '>Web Documentation</span><span className="d-md-none">Web Doc</span></Nav.Link>
                            </div>
                        </div> 
                         <Outlet /> 
                    </div>
                    <Footer></Footer>
                </div> 
            </div>
        </div> 
    );
};

export default Home;

