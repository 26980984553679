import React, {useEffect, useContext,useRef } from 'react'
import { NavLink } from 'react-router-dom';
import ScrollToTop from '../../../Shared/ScrollToTop';
import SupplierImg from '../../../../assets/images/weerp/web-image/business/contacts/suppliers.png';
import SupplierViewImg from '../../../../assets/images/weerp/web-image/business/contacts/suppliers-view.png';
import CustomerImg from '../../../../assets/images/weerp/web-image/business/contacts/customers.png';
import { ProjectTypeContext } from '../../../../ContextHandle';
import useScrollSpy from 'react-use-scrollspy';
export default function Contacts({ setActivePage, sectionRefs,setDefaultWebActiveMenu  }) {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    const panelName   = 'Admin panel';
    projectType = projectType.projectType;

    
    useEffect(() => {
        setActivePage(1017);
        document.title = 'Contacts | ' + projectName + ' web';
        setDefaultWebActiveMenu('2');
    }, [projectName]);


    const pagesectionRefs = [
        useRef(null),
        useRef(null), 
    ];


    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });

    return (
        <div className='d-flex  justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                        <li className="breadcrumb-item active" aria-current="page">Contacts</li>
                    </ol>
                </nav>
                <div id="suppliers" ref={pagesectionRefs['1']}>
                    <h4 className=" mt-5  font-weight-bold">Suppliers</h4>
                    <p className=""> Business Admin can manage suppliers.you can see the total summary in  suppliers view. can see the purchase Invoice, Return invoice  and Returns Payment and Invoice Chart. how much money will be received and how much money is due will be seen.</p>
                    <h4 className=" pt-3">List:</h4>
                    <div className='pt-3'>
                        <img src={SupplierImg} className="w-100 rounded" alt="Supplier"></img>
                    </div> 
                    <h4 className=" pt-3">View: </h4>
                    <div className='pt-3'>
                        <img src={SupplierViewImg} className="w-100 rounded" alt="Supplier view"></img>
                    </div> 
                </div>
                <div id="customers" ref={pagesectionRefs['2']}>
                    <h4 className=" mt-5  font-weight-bold">Customers</h4>
                    <p className="">Admin can manage customers. you can see the total summary in  customers view. You can see in view page  how many invoices there are, how much money has been paid and how much is due  </p>

                    <div className='pt-3'>
                        <img src={CustomerImg} className="w-100 rounded" alt="Parcel Lists"></img>
                    </div> 
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/admin/stock-transfer`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Stock Transfer</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/admin/accounts`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Accounts</h4>
                        </NavLink>
                    </div>
                </div>
            </div>

            <div className='right-menu'>
                <ul>
                    <li><a href='#suppliers' className={activeSection == 1 ? 'active-has' : ""}>Suppliers</a></li>
                    <li><a href='#customers' className={activeSection == 2 ? 'active-has' : ""}>Customers</a></li>
                </ul>
            </div>

        </div>
    )
}
