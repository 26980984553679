import React, { useEffect, useRef, useContext } from 'react'
import ScrollToTop from '../../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import LeaveAssignImg from '../../../../assets/images/weerp/web-image/business/hrm/leave-assign.png';
import ApplyLeaveImg from '../../../../assets/images/weerp/web-image/business/hrm/apply-leave.png';
import LeaveRequestImg from '../../../../assets/images/weerp/web-image/business/hrm/leave-request.png';
import useScrollSpy from 'react-use-scrollspy';
import { ProjectTypeContext } from '../../../../ContextHandle';
export default function HRMangement({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {

    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });

    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    const panelName = 'Business panel';
    projectType = projectType.projectType;

    /* eslint-disable */
    useEffect(() => {
        setActivePage(1020);
        document.title = 'HRM | ' + projectName + '  web';
        setDefaultWebActiveMenu(2);
    }, [projectName]);
    /* eslint-enable */
    return (
        <div className='d-flex  justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                        <li className="breadcrumb-item active" aria-current="page">HRM</li>
                    </ol>
                </nav>
                <div id="parcels" >

                    <h4 className="  mt-5 font-weight-bold " id='parcel-list' ref={pagesectionRefs[0]}>HRM</h4>

                    <p className="">Through this module you can manage employees designation, department, leave type, leave assign,available leave, leave request, attendance and etc. Leave can be managed according to the employee. Leave request can be approved or reject. Attendance can be given to employees. Attendance report can be seen. </p>

                    <h4 className=" pt-3" >Leave Assign </h4>
                    <div className='pt-3'>
                        <img src={LeaveAssignImg} className="w-100 rounded" alt="Parcel Lists"></img>
                    </div>
                    <h4 className=" pt-3">Apply Leave </h4>
                    <div className='pt-3'>
                        <img src={ApplyLeaveImg} className="w-100 rounded" alt="Parcel add"></img>
                    </div>
                    <h4 className=" pt-3" id='import-parcel' ref={pagesectionRefs[1]}>Leave Request  </h4>
                    <div className='pt-3'>
                        <img src={LeaveRequestImg} className="w-100 rounded" alt="Parcel Import"></img>
                    </div>

                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/business/bulk-import`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Bulk Import</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/business/todo`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Todo Manage</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className='right-menu'>
                <ul >
                    <li><a href='#parcel-list' className={activeSection == 0 ? 'active-has' : ""}>Leave Assign</a></li>
                    <li><a href='#import-parcel' className={activeSection == 1 ? 'active-has' : ""}>Apply Leave</a></li>
                    <li><a href='#parcel-view' className={activeSection == 2 ? 'active-has' : ""}>Leave Request</a></li>
                </ul>
            </div>
        </div>
    )
}
