import React, { useEffect, useContext } from 'react'
import ScrollToTop from '../../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import todoListImg from '../../../../assets/images/weerp/web-image/business/todo-list.png';
import ViewtodoImg from '../../../../assets/images/weerp/web-image/business/todo-view.png';
import { ProjectTypeContext } from '../../../../ContextHandle';
export default function TodoManage({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    const panelName   = 'Business panel';
    projectType = projectType.projectType;
    /* eslint-disable */
    useEffect(() => {
        setActivePage(1021);
        document.title = 'Todo Manage | ' + projectName + ' web';
        setDefaultWebActiveMenu(2);
    }, [projectName]);
    /* eslint-enable */
    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`} ><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                        <li className="breadcrumb-item active" aria-current="page">Todo Manage</li>
                    </ol>
                </nav>
                <div id="todolist" ref={sectionRefs['13']}>

                    <h4 className="font-weight-bold mt-5 ">Todo Manage</h4>
                    <p>Project wise can be managed todo and and can be assign to multiple employees. And Toto's status can be updated.</p>
                    <h4 className=" pt-3">Todo list</h4>
                    <div className='pt-3'>
                        <img src={todoListImg} className="w-100 rounded" alt="TodoList.png"></img>
                    </div>
                    <h4 className=" pt-3">View Todo</h4>
                    <div className='pt-3'>
                        <img src={ViewtodoImg} className="w-100 rounded" alt="addTodo.png"></img>
                    </div>
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/business/hr-management`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>HR Management</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/business/reports`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Reports</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
