import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import './../../assets/images/codestyle.css';
import database_1 from '../../assets/images/module/config/database_1.png';
import database_2 from '../../assets/images/module/config/db_add.jpg';
import ScrollToTop from '../Shared/ScrollToTop';
import DatabasePrivileges from '../../assets/images/module/config/privleges.png';
import { ProjectTypeContext } from '../../ContextHandle';
const Configuration = ({ setActivePage, sectionRefs }) => {
    setActivePage('1');
    var projectType = useContext(ProjectTypeContext);
    projectType = projectType.projectType;
    return (
        <div className='d-flex flex-column justify-content-between doc-page' id="configaration" ref={sectionRefs['2']}>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">

                <div>
                    <h4 className="border-top mt-5 pt-5">Configuration</h4>
                    <p className="font-weight-bold">You'll need to create a new database along with a user to access it. Follow the steps below , If you already know how to do this, skip to the next step.</p>
                    <p className="font-weight-bold"><b>Database configaration</b></p>
                    <p > - First click here <b>MySql database </b> button from <b>Database</b> section in your cpanel.</p>
                    <div className='pt-3'>
                        <img src={database_1} className="w-100 rounded" alt="Database configuration"></img>
                    </div>
                    <ul className='pt-3' style={{ listStyle: 'none' }}>
                        <li><b>1.</b> Give your database a name e.g <b>wecourier</b></li>
                        <li><b>2.</b> Create a database user and set up a password</li>
                        <li><b>3.</b> Add the user to the database and give the user All privileges to the database</li>
                    </ul>
                    <div className='pt-3'>
                        <img src={database_2} className="w-100 rounded" alt="Database configuration"></img>
                    </div>

                    <p className='pt-3' >After add user to database , click on all privileges  and then click on make changes button.</p>
                    <div className='pt-3'>
                        <img src={DatabasePrivileges} className="w-100 rounded" alt="Database configuration"></img>
                    </div>
                    <p className='pt-3 font-weight-bold'>Then copy your database user name ,  database name and database password. </p>

                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <p className="text-secondary"><i className="bi bi-caret-left" /> </p>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/upload-install`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Upload/Installation</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Configuration;
