import React, { useEffect, useContext } from 'react'
import ScrollToTop from './../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import SupportImg from './../../../assets/images/module/merchant-panel/support/support-list.png';
import AddSupportImg from './../../../assets/images/module/merchant-panel/support/support-create.png';
import { ProjectTypeContext } from '../../../ContextHandle';

export default function Support({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1026);
        document.title = 'Ticket | ' + projectName + ' web';
        setDefaultWebActiveMenu(3);
    }, [projectName]);
    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">Merchant Panel</li>
                        <li className="breadcrumb-item active" aria-current="page">Ticket</li>
                    </ol>
                </nav>
                <div id="accounts" ref={sectionRefs['28']}>

                    <h4 className="font-weight-bold mt-5 ">Ticket</h4>
                    <p > Ticket can be created for user through the Ticket module. To create a Ticket click on plus sign from Ticket module a form will be displayed select service priority department in that form and fill other fields click on submit button the Ticket will be created. And if you click on view from the list of Ticket, you can see all the information of Ticket. And replays can also be provided.</p>
                    <h4 className='pt-3'>Ticket list</h4>
                    <div className='pt-3'>
                        <img src={SupportImg} className="w-100 rounded" alt="Ticket.png"></img>
                    </div>
                    <h4 className='pt-3'>Add Ticket</h4>
                    <div className='pt-3'>
                        <img src={AddSupportImg} className="w-100 rounded" alt="addSupport.png"></img>
                    </div>
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/merchant/pickup-request`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Pickup Request</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/merchant/accounts`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Accounts</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
