import React, { useEffect, useContext,useRef } from 'react'
import ScrollToTop from '../../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';

import DashboardImg from './../../../../assets/images/wemover/web-image/admin/dashboard.png';
import OrdersImg from './../../../../assets/images/wemover/web-image/order.png';
import ShipperImg from './../../../../assets/images/wemover/web-image/shipper.png';
import ShipperViewImg from './../../../../assets/images/wemover/web-image/shipper-view.png';
import MakePaymentsImg from './../../../../assets/images/wemover/web-image/make-a-payment.png';
import CashoutFromDriverImg from './../../../../assets/images/wemover/web-image/cashout-from-driver.png';
import DriverImg from './../../../../assets/images/wemover/web-image/driver.png';
import DriverViewImg from './../../../../assets/images/wemover/web-image/driver-view.png';
import AccountsImg from './../../../../assets/images/wemover/web-image/account.png';
import WalletImg from './../../../../assets/images/wemover/web-image/wallet.png';
import ServicesImg from './../../../../assets/images/wemover/web-image/service.png';
import VehicleTypeImg from './../../../../assets/images/wemover/web-image/vehicletype.png';
import VehicleImg from './../../../../assets/images/wemover/web-image/vehicle.png';
import MediaImg from './../../../../assets/images/wemover/web-image/media.png';
import CouponImg from './../../../../assets/images/wemover/web-image/coupon.png';
import LanguageImg from './../../../../assets/images/wemover/web-image/language.png';
import CourierReportsImg from './../../../../assets/images/wemover/web-image/report-courier.png';
import DriverReportsImg from './../../../../assets/images/wemover/web-image/report-driver.png';
import ShipperReportsImg from './../../../../assets/images/wemover/web-image/report-shipper.png';
import TaxReportsImg from './../../../../assets/images/wemover/web-image/report-tax.png';
import SettingsImg from './../../../../assets/images/wemover/web-image/settings-general-settings.png';

import { ProjectTypeContext } from '../../../../ContextHandle';
import useScrollSpy from 'react-use-scrollspy';
export default function AdminPanel({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
 /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const panelName   = projectType.panelName;
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(2001);
        document.title = 'Admin Panel | ' + projectName + ' web';
        setDefaultWebActiveMenu(4);
    }, [projectName]);

 
    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });
    
    return (
        <div className='d-flex justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className="right-content-box-width">
                <nav >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item active" aria-current="page">How To Use</li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                    </ol>
                </nav>
                
                <h4 className="font-weight-bold mt-5  ">Admin Panel</h4>
                <div id='dashboard' ref={pagesectionRefs[0]}>
                    <h5 className="border-top font-weight-bold mt-2 pt-3 text-primary"># Dashboard</h5>
                    <p  >Admin can see total summery in dashboard like -  <b>Total users, total order, total order completed, total order pending, total income, total expense , total services, total driver and etc</b>. and also admin can see <b>today order , today payment and etc.</b> </p>
                    <div className='pt-3'>
                        <img src={DashboardImg} className="w-100 rounded" alt="account-heads.png"></img>
                    </div>
                </div>
                <div id='orders' ref={pagesectionRefs[1]}>
                    <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Orders</h5>
                    <p>Admin can manage orders from their panel. and also admin can update their order status. </p>
                    <div className='pt-3'>
                        <img src={OrdersImg} className="w-100 rounded" alt="account-heads.png"></img>
                    </div>
                </div>
                <div id='shippers' ref={pagesectionRefs[2]}>
                    <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Shippers</h5>
                    <p >Admin can manage shippers from their panel. </p>
                    <div className='pt-3'>
                        <img src={ShipperImg} className="w-100 rounded" alt="account-heads.png"></img>
                        <img src={ShipperViewImg} className="w-100 rounded mt-2" alt="account-heads.png"></img>
                    </div>
                </div>

               
                <div id='payments' ref={pagesectionRefs[3]}>
                    <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Payments</h5> 
                    <div className='pt-3'>
                        <div className='mb-2'><b> - Make A Payment</b></div>
                        <p>Admin can payment to driver. </p>
                        <img src={MakePaymentsImg} className="w-100 rounded" alt="account-heads.png"></img>
                        <div className='my-2'><b> - Cashout from driver</b></div>
                        <p>Admin can collect payment from driver. </p>
                        <img src={CashoutFromDriverImg} className="w-100 rounded" alt="account-heads.png"></img>
                    </div>
                </div>

                <div id='driver' ref={pagesectionRefs[4]}>
                    <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Drivers</h5>
                    <p >Admin can manage their two type driver like - <b>Company driver, Individual driver.</b> </p>
                    <div className='pt-3'>
                        <img src={DriverImg} className="w-100 rounded" alt="account-heads.png"></img>
                        <img src={DriverViewImg} className="w-100 rounded mt-2" alt="account-heads.png"></img>
                    </div>
                </div>
                <div id='accounts' ref={pagesectionRefs[5]}>
                    <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Accounts</h5>
                    <p >Admin can manage his multiple accounts. </p>
                    <div className='pt-3'>
                        <img src={AccountsImg} className="w-100 rounded" alt="account-heads.png"></img>
                    </div>
                </div>
                <div id='wallet' ref={pagesectionRefs[6]}>
                    <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Wallet</h5>
                    <p >Admin can manage shipper wise wallet. and admin can recharge shipper wise. </p>
                    <div className='pt-3'>
                        <img src={WalletImg} className="w-100 rounded" alt="account-heads.png"></img>
                    </div>
                </div>
                <div id='services' ref={pagesectionRefs[7]}>
                    <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Services</h5>
                    <p >Admin can manage their multiple services. </p>
                    <div className='pt-3'>
                        <img src={ServicesImg} className="w-100 rounded" alt="account-heads.png"></img>
                    </div>
                </div>
                <div id='vehicle' ref={pagesectionRefs[8]}>
                    <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Vehicle</h5> 
                    <div className='pt-3'>
                        <div className='my-2'><b> - Vehicle Type</b></div>
                        <p >Admin can manage multiple vehicle type. </p>
                        <img src={VehicleTypeImg} className="w-100 rounded" alt="account-heads.png"></img>
                        <div className='my-2'><b> - Vehicle</b></div>
                        <p >Can manage Vehicle type wise vehicle. </p>
                        <img src={VehicleImg} className="w-100 rounded" alt="account-heads.png"></img>
                    </div>
                </div>
                <div id='media' ref={pagesectionRefs[9]}>
                    <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Media</h5>
                    <p >All media related file can store in media and can use any where from media. </p>
                    <div className='pt-3'>
                        <img src={MediaImg} className="w-100 rounded" alt="account-heads.png"></img>
                    </div>
                </div>
                <div id='coupon' ref={pagesectionRefs[10]}>
                    <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Coupon</h5>
                    <p >Admin can manage coupon and shipper can receive discount using coupon. </p>
                    <div className='pt-3'>
                        <img src={CouponImg} className="w-100 rounded" alt="account-heads.png"></img>
                    </div>
                </div>
             
                <div id='language' ref={pagesectionRefs[11]}>
                    <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Language</h5>
                    <p >Multiple language system supporing in this application and admin can manage from language module. </p>
                    <div className='pt-3'>
                        <img src={LanguageImg} className="w-100 rounded" alt="account-heads.png"></img>
                    </div>
                </div>
                <div id='reports' ref={pagesectionRefs[12]}>
                    <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Reports</h5>
                    <div className='pt-3'>
                        <div className='my-2'><b> - Courier Reports</b></div>
                        <p>Admin can see their courier all income and expense from courier reports and also can see driver wise income and expense. </p>
                        <img src={CourierReportsImg} className="w-100 rounded" alt="account-heads.png"></img>
                        <div className='my-2'><b> - Driver Reports</b></div>
                        <p>Driver wise admin can see driver income and expnese.</p>
                        <img src={DriverReportsImg} className="w-100 rounded" alt="account-heads.png"></img>
                        <div className='my-2'><b> - Shipper Reports</b></div>
                        <p>Shipper wise admin can see their pending and paid invoice and how much payment are pending or paid.</p>
                        <img src={ShipperReportsImg} className="w-100 rounded" alt="account-heads.png"></img>
                        <div className='my-2'><b> - Tax Reports</b></div>
                        <p>Driver wise admin can see tax reports.</p>
                        <img src={TaxReportsImg} className="w-100 rounded" alt="account-heads.png"></img>
                    </div>
                </div> 
                
              
                <div id='settings' ref={pagesectionRefs[13]}>
                    <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Settings</h5>
                    <p >Admin can change his company information from general settings like - <b>Name, Email, Phone , Logo, Favicon, Currency, Theme color, Prefix, copyright and etc</b>. </p>
                    <div className='pt-3'>
                        <img src={SettingsImg} className="w-100 rounded" alt="account-heads.png"></img>
                    </div>

                    <p className='mt-2'>Also Admin can manage their - <b>Mail settings, SMS Settings, Payment Gateway, ReCaptcha, Tax rate , Currency and Toll from settings. </b></p>
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/forgot-password`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Forgot Password</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/shipper-panel`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Shipper panel</h4>
                        </NavLink>
                    </div>
                </div>
            </div>

            <div className='right-menu'>
                <ul>
                    <li><a href='#dashboard' className={activeSection == 0 ? 'active-has':""}>  Dashboard </a></li> 
                    <li><a href='#orders'    className={activeSection == 1 ? 'active-has':""}>  Orders </a></li> 
                    <li><a href='#shipper'   className={activeSection == 2 ? 'active-has':""}>  Shipper </a></li> 
                    <li><a href='#payments'  className={activeSection == 3 ? 'active-has':""}>  Payments </a></li> 
                    <li><a href='#driver'    className={activeSection == 4 ? 'active-has':""}>  Driver </a></li> 
                    <li><a href='#accounts'  className={activeSection == 5 ? 'active-has':""}>  Accounts  </a></li> 
                    <li><a href='#wallet'    className={activeSection == 6 ? 'active-has':""}>  Wallet  </a></li> 
                    <li><a href='#services'  className={activeSection == 7 ? 'active-has':""}>  Services  </a></li> 
                    <li><a href='#vehicle'   className={activeSection == 8 ? 'active-has':""}>  Vehicle manage  </a></li> 
                    <li><a href='#media'     className={activeSection == 9 ? 'active-has':""}>  Media  </a></li> 
                    <li><a href='#coupon'    className={activeSection == 10 ? 'active-has':""}> Coupon  </a></li> 
                    <li><a href='#language'  className={activeSection == 11 ? 'active-has':""}> Languages  </a></li> 
                    <li><a href='#reports'   className={activeSection == 12 ? 'active-has':""}> Reports  </a></li> 
                    <li><a href='#settings'  className={activeSection == 13 ? 'active-has':""}> Settings  </a></li> 
                </ul>
            </div>
            
        </div>
    )
}
