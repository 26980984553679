import React, { useContext } from 'react';
import { ProjectTypeContext } from '../../ContextHandle';
const Footer = () => {

    var projectName = useContext(ProjectTypeContext);
    projectName = projectName.projectName;
 
    return (
        <div className='py-4 border-top border-bottom text-center bg-light'>
            <p className='m-0 p-0'>{projectName} - Copyright © All rights reserved. Development by <a href='https://wemaxdevs.com/'>Wemaxdevs</a></p>
        </div>
    );
};

export default Footer;
