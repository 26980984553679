import React, { useEffect, useRef, useContext } from 'react'
import ScrollToTop from './../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import DCategory from './../../../assets/images/module/admin-panel/settings/delivery-category.png';
import DCharge from './../../../assets/images/module/admin-panel/settings/delivery-charge.png';
import DType from './../../../assets/images/module/admin-panel/settings/delivery-type.png';
import LiquidFragile from './../../../assets/images/module/admin-panel/settings/liquid-fragile.png';
import smsSettings from './../../../assets/images/module/admin-panel/settings/sms-settings.png';
import smsSendSettings from './../../../assets/images/module/admin-panel/settings/sms-send-settings.png';
import NotificationSettings from './../../../assets/images/module/admin-panel/settings/push-notification-settings.png';
import SocialLoginSettingsImg from './../../../assets/images/module/admin-panel/settings/social-login-settings.png';
import OnlinepaymentSetupImg from './../../../assets/images/module/admin-panel/settings/online-payment-setup.png';
import Packaging from './../../../assets/images/module/admin-panel/settings/packaging.png';
import generalSettings from './../../../assets/images/module/admin-panel/settings/general-settings.png';
import assetsCategory from './../../../assets/images/module/admin-panel/settings/asset-category.png';
import DatabaseBackupImg from './../../../assets/images/module/admin-panel/settings/database-backup.png';
import InvoiceGenerateImg from './../../../assets/images/module/admin-panel/settings/invoice-generate.png';
import googleMapsSettings from './../../../assets/images/module/admin-panel/settings/google-maps.png';
import useScrollSpy from 'react-use-scrollspy';
import { ProjectTypeContext } from '../../../ContextHandle';
export default function Settings({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
 /* eslint-disable */
    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });

    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    const panelName   = projectType.panelName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1021);
        document.title = 'Introduction | ' + projectName + ' web';
        setDefaultWebActiveMenu(2);
    }, [projectName]);
    return (
        <div className='d-flex  justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                        <li className="breadcrumb-item active" aria-current="page">Settings</li>
                    </ol>
                </nav>
                <div id="settings"  >
                    <h4 className="  mt-5 font-weight-bold ">Settings</h4>

                    <div id='delivery-category' ref={pagesectionRefs[0]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Delivery Category</h5>
                        <p className="pt-3"> Delivery categories can be created, listed, edited and deleted through the delivery category module. </p>
                        <div className='pt-3'>
                            <img src={DCategory} className="w-100 rounded" alt="DeliveryCategory.png"></img>
                        </div>
                    </div>
                    <div id='delivery-charge' ref={pagesectionRefs[1]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Delivery charge</h5>
                        <p className="pt-3">Delivery Charges can be created or added and edited and deleted from the Delivery Charges module. </p>
                        <div className='pt-3'>
                            <img src={DCharge} className="w-100 rounded" alt="DeliveryCharge.png"></img>
                        </div>
                    </div>
                    <div id='delivery-type' ref={pagesectionRefs[2]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Delivery type</h5>
                        <p className="pt-3"> Delivery types can be turned on and off from the Delivery type module. Those that will be on can select the types otherwise cannot be selected. That is, those that will be on can be selected while creating the parcel, otherwise they will not be able to.</p>
                        <div className='pt-3'>
                            <img src={DType} className="w-100 rounded" alt="DeliveryType.png"></img>
                        </div>
                    </div>
                    <div id='liquid-fragile' ref={pagesectionRefs[3]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Liquid / Fragile</h5>
                        <p className="pt-3"> You can turn liquid/fragile on or off and change its amount from the liquid/fragile module. If on, you can select the time to create the parcel, otherwise you can't.</p>
                        <div className='pt-3'>
                            <img src={LiquidFragile} className="w-100 rounded" alt="LiquidFragile.png"></img>
                        </div>
                    </div>
                    <div id='sms-setting' ref={pagesectionRefs[4]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># SMS Settings</h5>
                        <p className="pt-3"> SMS sending can be controlled through SMS settings module. SMS will work when status is turned on with SMS API key and secret key. Otherwise SMS will not work.</p>
                        <p className="pt-3"><b>REVE SMS:</b> First you need to create account on REVE  . then you can easily get client id and secret. and then put your client id and secret key in your site REVE SMS settings.</p>
                        <p className="pt-3"><b>NEXMO SMS:</b> First you need to create account on NEXMO. then you can easily get client id and secret. and then put your client id and secret in <b>WeCourier</b> NEXMO SMS settings. If your don't have account you can register and get client id and secret as like bellow: <a className='text-primary' target='_blank' href='https://dashboard.nexmo.com/sign-in'>https://dashboard.nexmo.com/sign-in</a></p>

                        <p className="pt-3"><b>TWILIO SMS:</b> First you need to create and add phone number. then you can easily get account SID, Token and Number. Create Account from here:<a target='_blank' className='text-primary' href='https://twilio.com'>https://twilio.com</a>. Next add Twilio Phone Number. Next you can get account SID, Token and Number and add on .env file as like bellow: </p>
                        <div className='pt-3'>
                            <img src={smsSettings} className="w-100 rounded" alt="smsSettings.png"></img>
                        </div>
                    </div>
                    <div id='sms-send-settings' ref={pagesectionRefs[5]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># SMS Send Settings</h5>
                        <p className="pt-3">If you turn on when and where the SMS will be sent through the SMS send settings module, then the SMS will go there. Otherwise, it will not go. </p>

                        <div className='pt-3'>
                            <img src={smsSendSettings} className="w-100 rounded" alt="smsSendSettings.png"></img>
                        </div>
                    </div>
                    <div id='notificationsettings ' ref={pagesectionRefs[6]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Notifications Settings</h5>
                        <p className="pt-3">To send notifications to the user, you need to set up notifications. For that, you have to save the Firebase Secret Key and FCM Topic of the courier in the notification module from the settings, otherwise the notification will work.</p>
                        <div className='pt-3'>
                            <img src={NotificationSettings} className="w-100 rounded" alt="Notificationsettings.png"></img>
                        </div>
                    </div>
                    <div id='socialloginsettings' ref={pagesectionRefs[7]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Social Login Settings</h5>
                        <p className="pt-3">User can login using his social account. And for that there is an option to login using facebook and google account. However, the courier has to save with his <b>Facebook app id, app secret and status </b> for his social login. And for Google login, if you choose to save with <b> Google Client id, Client secret, status,</b> social login will work from the login page.</p>
                        <div className='pt-3'>
                            <img src={SocialLoginSettingsImg} className="w-100 rounded" alt="Notificationsettings.png"></img>
                        </div>
                    </div>
                    <div id='onlinepaymentsetup' ref={pagesectionRefs[8]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Online Payment Setup</h5>
                        <b>Razorpay: </b>
                        <ol>
                            <li> First you need to create account on razorpay. then you can easily get account key id and key secret.</li>
                            <li> Create Account from here: <a className='text-primary' target='_blank' href='https://razorpay.com'>https://razorpay.com</a>.</li>
                            <li>After register successfully. you need to go bellow link and get id and secret:
                                Go Here: <a className='text-primary' target='_blank' href='https://dashboard.razorpay.com/app/keys'>https://dashboard.razorpay.com/app/keys</a>.</li>
                        </ol>
                        <div className='pt-3'>
                            <img src={OnlinepaymentSetupImg} className="w-100 rounded" alt="onlinepaymentsetup.png"></img>
                        </div>
                    </div>
                    <div id='packaging' ref={pagesectionRefs[9]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Packaging</h5>
                        <p className="pt-3"> Packaging can be created and edited and deleted through the packaging module. Packaging can be selected when creating parcels. </p>
                        <div className='pt-3'>
                            <img src={Packaging} className="w-100 rounded" alt="Packaging.png"></img>
                        </div>
                    </div>
                    <div id='general-settings' ref={pagesectionRefs[10]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># General Settings</h5>
                        <p className="pt-3"> Site name and logo, favicon, number, phone, email and currency can be changed through General settings. </p>
                        <div className='pt-3'>
                            <img src={generalSettings} className="w-100 rounded" alt="generalSettings.png"></img>
                        </div>
                    </div>
                    <div id='database-backup ' className='mt-5' ref={pagesectionRefs[11]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Database Backup</h5>
                        <p className="pt-3">Always back up the database to a different drive than the actual database. Then, if you get a disk crash, you will not lose your backup file along with the database. To download the database, click on the download button and the database will be downloaded.</p>
                        <div className='pt-3'>
                            <img src={DatabaseBackupImg} className="w-100 rounded" alt="databasebackup.png"></img>
                        </div>
                    </div>
                    <div id='invoice-generate' className='mt-5' ref={pagesectionRefs[12]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Invoice Generate</h5>

                        <div className='pt-3'>
                            <img src={InvoiceGenerateImg} className="w-100 rounded" alt="InvoiceGenerate.png"></img>
                        </div>
                    </div>
                    <div id='google-maps-settings' className='mt-5' ref={pagesectionRefs[13]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Google Maps Settings</h5>
                        <p>Go to the <b>Google Maps Platform {`>`} Credentials</b> page. On the Credentials page, click <b>Create credentials {`>`} API key</b>. The API key created dialog displays your newly created API key. <a href='https://developers.google.com/maps/documentation/embed/get-api-key#:~:text=Go%20to%20the%20Google%20Maps%20Platform%20%3E%20Credentials%20page.&text=On%20the%20Credentials%20page%2C%20click,Click%20Close.' target='_blank'>Click More</a> </p>
                        <div className='pt-3'>
                            <img src={googleMapsSettings} className="w-100 rounded" alt="GoogleMapsSetting.png"></img>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/addons`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Addons</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/merchant/dashboard`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Merchant Panel</h4>
                        </NavLink>
                    </div>
                </div>
            </div>

            <div className='right-menu'>
                <ul>
                    <li><a href='#delivery-category' className={activeSection == 0 ? 'active-has' : ""}>  Delivery Category</a></li>
                    <li><a href='#delivery-charge' className={activeSection == 1 ? 'active-has' : ""}>  Delivery charge </a></li>
                    <li><a href='#delivery-type' className={activeSection == 2 ? 'active-has' : ""}>  Delivery type </a></li>
                    <li><a href='#liquid-fragile' className={activeSection == 3 ? 'active-has' : ""}>  liquid/Fragile </a></li>
                    <li><a href='#sms-setting' className={activeSection == 4 ? 'active-has' : ""}>  SMS Settings</a></li>
                    <li><a href='#sms-send-settings' className={activeSection == 5 ? 'active-has' : ""}>  SMS Send Settings</a></li>
                    <li><a href='#notificationsettings' className={activeSection == 6 ? 'active-has' : ""}> Notification Settings </a></li>
                    <li><a href='#socialloginsettings' className={activeSection == 7 ? 'active-has' : ""}>  Social Login Settings </a></li>
                    <li><a href='#onlinepaymentsetup' className={activeSection == 8 ? 'active-has' : ""}> Online Payment Setup</a></li>
                    <li><a href='#packaging' className={activeSection == 9 ? 'active-has' : ""}>  Packaging</a></li>
                    <li><a href='#general-settings' className={activeSection == 10 ? 'active-has' : ""}>   General Settings</a></li>
                    <li><a href='#database-backup' className={activeSection == 11 ? 'active-has' : ""}>   Database  Backup</a></li>
                    <li><a href='#google-maps-settings' className={activeSection == 13 ? 'active-has' : ""}>   Google maps settings</a></li>
                </ul>
            </div>

        </div>
    )
}
