import React, { useEffect, useContext } from 'react'
import ScrollToTop from '../../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import ProductImg from '../../../../assets/images/weerp/web-image/business/products/product-list.png';
import ProductViewImg from '../../../../assets/images/weerp/web-image/business/products/product-view.png';
import ProductLabelImg from '../../../../assets/images/weerp/web-image/business/products/label-print.png';
import { ProjectTypeContext } from '../../../../ContextHandle';
export default function Products({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    const panelName = 'Business panel';
    projectType = projectType.projectType;
    /* eslint-disable */
    useEffect(() => {
        setActivePage(1012);
        document.title = 'Products | ' + projectName + ' web';
        setDefaultWebActiveMenu('2');
    }, [projectName]);
    /* eslint-enable */
    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                        <li className="breadcrumb-item active" aria-current="page">Products</li>
                    </ol>
                </nav>
                <div id="assets" ref={sectionRefs['15']}>
                    <h4 className=" font-weight-bold mt-5  ">Products</h4>
                    <p >Can manage multiple variation wise products. Variations may be sold as products. Multiple labels of product can be printed. All the details can be seen on the product page. products stock can be seen  branch wise in product view.  </p>
                    <h4 className=" pt-3">Product List</h4>
                    <div className='pt-3'>
                        <img src={ProductImg} className="w-100 rounded" alt="Product.png"></img>
                    </div>
                    <h4 className=" pt-3">Product View</h4>
                    <div className='pt-3'>
                        <img src={ProductViewImg} className="w-100 rounded" alt="Productview.png"></img>
                    </div>
                    <h4 className=" pt-3">Product Label</h4>
                    <div className='pt-3'>
                        <img src={ProductLabelImg} className="rounded" style={{ width: "250px" }} alt="Productview.png"></img>
                    </div>
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/business/services`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Services</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/business/purchase`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Purchase & Return</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
