import React, { useEffect, useRef, useContext } from 'react'
import ScrollToTop from '../../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import AccountImg from '../../../../assets/images/weerp/web-image/branch/accounts.png';
import useScrollSpy from 'react-use-scrollspy';
import { ProjectTypeContext } from '../../../../ContextHandle';
export default function Accounts({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    /* eslint-disable */
    useEffect(() => {
        setActivePage(1029);
        document.title = 'Accounts | ' + projectName + ' web';
        setDefaultWebActiveMenu(3);
    }, [projectName]);
    /* eslint-enable */
    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });

    return (
        <div className='d-flex  justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">Branch Panel</li>
                        <li className="breadcrumb-item active" aria-current="page">Accounts</li>
                    </ol>
                </nav>
                <div id="accounts" ref={sectionRefs['29']}>
                    <h4 className="font-weight-bold mt-5  ">Accounts</h4>

                    <div id='payment-received' ref={pagesectionRefs[0]}>
                        <p className=" pt-3">Branch can manage his multiple accounts. also branch can see his all bank transaction. </p>
                        <div className='pt-3'>
                            <img src={AccountImg} className="w-100 rounded" alt="accountimg.png"></img>
                        </div>
                    </div>


                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/branch/need-help`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Need Help</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/branch/hr-management`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>HR Management</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className='right-menu'>
                <ul>
                    <li><a href='#payment-received' className={activeSection === 0 ? 'active-has' : ""}> Payment Received </a></li>
                    <li><a href='#payout' className={activeSection === 1 ? 'active-has' : ""}> Payout</a></li>
                    <li><a href='#invoice' className={activeSection === 2 ? 'active-has' : ""}> Invoice</a></li>
                </ul>
            </div>


        </div>
    )
}
