import React, { useEffect, useRef, useContext } from 'react'
import ScrollToTop from '../../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import bulkImportImg from '../../../../assets/images/weerp/web-image/business/bulk-import.png'; 
import useScrollSpy from 'react-use-scrollspy';
import { ProjectTypeContext } from '../../../../ContextHandle';
export default function BulkImport({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
 /* eslint-disable */
    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });

    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    const panelName   = 'Admin panel';
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1019);
        document.title = 'Bulk Import | ' + projectName + '  web';
        setDefaultWebActiveMenu(2);
    }, [projectName]);
    return (
        <div className='d-flex  justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                        <li className="breadcrumb-item active" aria-current="page">Bulk Import</li>
                    </ol>
                </nav>
                <div id="merchantmanage" >

                    <div id='merchant' ref={pagesectionRefs[0]}>
                        <h5 className=" font-weight-bold mt-5  ">Bulk Import</h5>
                        <p className="pt-3">Multiple products can be created through bulk import. Those that can be imported are: <b>Category, Brand,Customer, Supplier, Product.</b> and also Excel files can be imported.</p>
                   
                        <div className='pt-3'>
                            <img src={bulkImportImg} className="w-100 rounded" alt="Bulkimport.png"></img>
                        </div>
                       
                    </div>
                  

                    <div className="d-flex justify-content-between border-top mt-5">
                        <div className="text-start mt-4">
                            <NavLink to={`/${projectType}/admin/accounts`} className="text-decoration-none">
                                <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                                <h4 className='pagination-title'>Accounts</h4>
                            </NavLink>
                        </div>
                        <div className="text-end my-2">
                            <NavLink to={`/${projectType}/admin/hr-management`} className="text-decoration-none">
                                <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                                <h4 className='pagination-title'>HR Management</h4>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className='right-menu'>
                <ul >
                    {/* <li><a href='#merchant' className={activeSection == 0 ? 'active-has' : ""}>Merchant</a></li>  */}
                </ul>
            </div>
        </div>
    )
}
