import React, { useEffect, useRef, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import '../../../../../assets/images/codestyle.css';
import useScrollSpy from 'react-use-scrollspy';
import ScrollToTop from '../../../../Shared/ScrollToTop';
import { ProjectTypeContext } from '../../../../../ContextHandle';
const UpdateBuildVersionNumber = ({ setActivePage, sectionRefs, setDocumentation, setDefaultAppActiveMenu }) => {
 /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;

    useEffect(() => {
        setActivePage(521);
        setDocumentation(2);
        setDefaultAppActiveMenu(4);
        document.title = 'Update the app’s build and version numbers | ' + projectName;
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);

    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });



    return (
        <div className='d-flex   justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div id="system-requirements  " className='pt-5 right-content-box-width' >
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">iOS Setup</li>
                        <li className="breadcrumb-item active" aria-current="page">Update the app’s build and version numbers</li>
                    </ol>
                </nav>

                <div >
                    <h3 className="pt-5 font-weight-bold" >Update the app’s build and version numbers</h3>
                    <p>
                        The default version number of the app is <mark className='text-danger px-2'>1.0.0</mark>. To update it, navigate to the <mark className='text-danger px-2'>pubspec.yaml</mark> file and update the following line:</p>

                    <p> <mark className='text-danger px-2'>version: 1.0.0+1</mark><br /></p>

                    <p>The version number is three numbers separated by dots, such as <mark className='text-danger px-2'>1.0.0</mark> in the example above, followed by an optional build number such as 1 in the example above, separated by a +.</p>

                    <p>Both the version and the build number may be overridden in Flutter's build by specifying <mark className='text-danger px-2'>--build-name</mark> and <mark className='text-danger px-2'>--build-number</mark>, respectively.</p>

                    <p>In iOS, <mark className='text-danger px-2'>build-name</mark> uses <mark className='text-danger px-2'>CFBundleShortVersionString </mark>while <mark className='text-danger px-2'>build-number</mark> uses <mark className='text-danger px-2'>CFBundleVersion</mark>. Read more about iOS versioning at <a rel="noreferrer" href='https://developer.apple.com/library/archive/documentation/General/Reference/InfoPlistKeyReference/Articles/CoreFoundationKeys.html' target="_blank">Core Foundation Keys </a>on the Apple Developer’s site.
                    </p>
                </div>


                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/app/build-archive`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Create a build archive and upload to App Store Connect</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/app/create-app-build`} className="text-decoration-none">
                            <a href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Create an app bundle</h4>
                        </NavLink>
                    </div>
                </div>
            </div>


        </div>

    );

};

export default UpdateBuildVersionNumber;
