import React, { useEffect, useRef, useContext } from 'react'
import { NavLink } from 'react-router-dom';
import ScrollToTop from './../../Shared/ScrollToTop';
import codcharge from './../../../assets/images/module/merchant-panel/settings/cod-charges.png';
import deliverycharge from './../../../assets/images/module/merchant-panel/settings/delivery-charges.png';
import Onlinepaymentsetup from './../../../assets/images/module/merchant-panel/settings/online-payment-setup.png';
import useScrollSpy from 'react-use-scrollspy';
import { ProjectTypeContext } from '../../../ContextHandle';
export default function Settings({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1031);
        document.title = 'Settings | ' + projectName + ' web';
        setDefaultWebActiveMenu(3);
    }, [projectName]);

    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });


    return (
        <div className='d-flex  justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">Merchant Panel</li>
                        <li className="breadcrumb-item active" aria-current="page">Settings</li>
                    </ol>
                </nav>
                <div id="accounts" ref={sectionRefs['34']}>

                    <h4 className="font-weight-bold mt-5  ">Settings</h4>

                    <div id='cod-charge' ref={pagesectionRefs[0]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># COD Charge</h5>
                        <p className="pt-3"> You can see the merchant's COD charges through the COD Charges module. Merchant charges cannot be changed.</p>
                        <div className='pt-3'>
                            <img src={codcharge} className="w-100 rounded" alt="codcharge.png"></img>
                        </div>
                    </div>
                    <div id='delivery-charge' ref={pagesectionRefs[1]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Delivery Charge</h5>
                        <p className="pt-3">The delivery charges of all categories can be seen through the delivery charge module. Merchant cannot edit or delete delivery charges.</p>
                        <div className='pt-3'>
                            <img src={deliverycharge} className="w-100 rounded" alt="deliverycharge.png"></img>
                        </div>
                    </div>
                    <div id='onlinepaymentsetup' ref={pagesectionRefs[2]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Online payment setup</h5>
                        <p className="pt-3">If Merchant wants to take payment online, Merchant must set up his payment gateway information. And this setup option will be available after clicking on online payment setup from settings module. All payment gateways are - <b>PayPal, Stripe, Skrill, sslcommerz, Aamarpay, bkash, Razorpay</b>  and etc. When the courier makes the payment through the online payment gateway, the money will be transferred to the account given in the online payment setup from the merchant's settings.</p>
                        <b>Razorpay: </b>
                        <ol>
                            <li> First you need to create account on razorpay. then you can easily get account key id and key secret.</li>
                            <li> Create Account from here: <a className='text-primary' target='_blank' href='https://razorpay.com'>https://razorpay.com</a>.</li>
                            <li>After register successfully. you need to go bellow link and get id and secret:
                                Go Here: <a className='text-primary' target='_blank' href='https://dashboard.razorpay.com/app/keys'>https://dashboard.razorpay.com/app/keys</a>.</li>
                        </ol>
                        <div className='pt-3'>
                            <img src={Onlinepaymentsetup} className="w-100 rounded" alt="onlinepaymentsetup.png"></img>
                        </div>
                    </div>

                </div>
                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/merchant/reports`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Reports</h4>
                        </NavLink>
                    </div>

                </div>
            </div>
            <div className='right-menu'>
                <ul>
                    <li><a href='#cod-charge' className={activeSection == 0 ? 'active-has' : ""}> COD Charge </a></li>
                    <li><a href='#delivery-charge' className={activeSection == 1 ? 'active-has' : ""}> Delivery Charge</a></li>
                    <li><a href='#onlinepaymentsetup' className={activeSection == 2 ? 'active-has' : ""}>Online Payment Setup</a></li>
                </ul>
            </div>

        </div>
    )
}
