import React, { useEffect, useRef, useContext } from 'react'
import ScrollToTop from './../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import paymentreceived from './../../../assets/images/module/merchant-panel/accounts/payment-received-list.png';
import PayoutImg from './../../../assets/images/module/merchant-panel/accounts/payout.png';
import InvoiceImg from './../../../assets/images/module/merchant-panel/accounts/invoice-list.png';
import InvoiceDetailsImg from './../../../assets/images/module/merchant-panel/accounts/invoice-details.png';
import useScrollSpy from 'react-use-scrollspy';
import { ProjectTypeContext } from '../../../ContextHandle';
export default function Accounts({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1027);
        document.title = 'Accounts | ' + projectName + ' web';
        setDefaultWebActiveMenu(3);
    }, [projectName]);

    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });

    return (
        <div className='d-flex  justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">Merchant Panel</li>
                        <li className="breadcrumb-item active" aria-current="page">Accounts</li>
                    </ol>
                </nav>
                <div id="accounts" ref={sectionRefs['29']}>
                    <h4 className="font-weight-bold mt-5  ">Accounts</h4>

                    <div id='payment-received' ref={pagesectionRefs[0]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Payment Received</h5>
                        <p >If the merchant pays through Courier online payment, all received payment information will be displayed on this page. And for this, if the merchant clicks on "payment received" from the account modules of his panel, the merchant can see the list and information of all the payments received.</p>
                        <div className='pt-3'>
                            <img src={paymentreceived} className="w-100 rounded" alt="paymentreceived.png"></img>
                        </div>
                    </div>
                    <div id='payout' className='mt-5' ref={pagesectionRefs[1]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Payout</h5>
                        <p >Merchant can pay the courier online. Therefore, if the merchant clicks on Payout from the account module of his panel, all the payment gateways will be displayed. The merchant has to select the payment gateway through which he wants to make the payment. After that he has to give his card details with the amount of money. Payment gateways that can be used for payment are - PayPal, Stripe, Skrill, sslcommerz,aamar pay, bkash and etc. And the merchant will also see all his payment details.</p>

                        <div className='pt-3'>
                            <img src={PayoutImg} className="w-100 rounded" alt="payout.png"></img>
                        </div>
                    </div>
                    <div id='invoice' className='mt-5' ref={pagesectionRefs[2]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Invoice</h5>
                        <p > By clicking on Invoices from the Accounts module, the merchant can view all the invoices generated and the status of his delivered and partially delivered invoices. </p>
                        <h5 className=" font-weight-bold mt-2  "> Invoice list</h5>
                        <div className='pt-3'>
                            <img src={InvoiceImg} className="w-100 rounded" alt="invoice.png"></img>
                        </div>
                        <h5 className=" font-weight-bold mt-2  "> Invoice Details</h5>
                        <div className='pt-3'>
                            <img src={InvoiceDetailsImg} className="w-100 rounded" alt="InvoiceDetails.png"></img>
                        </div>
                    </div>

                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/merchant/support`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Ticket</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/merchant/shops`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Pickup Points</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className='right-menu'>
                <ul>
                    <li><a href='#payment-received' className={activeSection == 0 ? 'active-has' : ""}> Payment Received </a></li>
                    <li><a href='#payout' className={activeSection == 1 ? 'active-has' : ""}> Payout</a></li>
                    <li><a href='#invoice' className={activeSection == 2 ? 'active-has' : ""}> Invoice</a></li>
                </ul>
            </div>


        </div>
    )
}
