import React, { useEffect, useRef, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import '../../../assets/images/codestyle.css';
import useScrollSpy from 'react-use-scrollspy';
import ScrollToTop from '../../Shared/ScrollToTop';
import { ProjectTypeContext } from '../../../ContextHandle'
const GoogleFirebaseSetup = ({ setActivePage, sectionRefs, setDocumentation, setDefaultAppActiveMenu }) => {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(504);
        setDocumentation(2);
        setDefaultAppActiveMenu(2);
        document.title = 'Google Firebase Setup | ' + projectName;
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);


    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });


    return (
        <div className='d-flex   justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div id="system-requirements  " className='pt-5 right-content-box-width' >
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">Common Setup</li>
                        <li className="breadcrumb-item active" aria-current="page">Google Firebase Setup</li>
                    </ol>
                </nav>

                <h3 className="pt-5 font-weight-bold" ref={pagesectionRefs[1]}>Google Firebase Setup</h3>
                <p><b>Video tutorial</b></p>
                <iframe width="850" height="478" src="https://www.youtube.com/embed/Ol_21z4vpTE" title="Wecourier - Create a firebase project | WemaxDevs" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <h4 className='mt-2 font-weight-bold' id='follow' >OR follow bellow step:</h4>
                <ul  >
                    <li>Goto And login with your Google account</li>
                    <li>Click on <b>Create a project</b> or <b>Add Project</b></li>
                    <li>Then enter project name and follow then Firebase steps to complete.</li>
                    <li>After Completing project creation click <b>continue</b> redirect project dashboard.</li>
                </ul>
                <h4 className='mt-2 font-weight-bold' id='android' >Android</h4>
                <ul ref={pagesectionRefs[2]}>
                    <li>Android <b>Android Icon</b> Then enter <b>Android Package Name, App Name</b> and click on <b>register app</b></li>
                    <li>Click on iOS IconThen select .p12 certificate from your computer.</li>
                    <li>Download <b>google-services.json</b> file and copy/paste it to android studio project directory. Location:<b> project » android » app.</b></li>
                    <li>Done!!</li>
                </ul>
                <div className='alert alert-info'>
                    <i className='fa fa-circle-info '></i><span className='font-weight-bold'> INFO</span>
                    <p>  Android package name should be the remain same with your studio project else build will not be work</p>
                </div>

                <h4 className='mt-2 font-weight-bold' id='ios'>iOS</h4>
                <ul ref={pagesectionRefs[3]}>
                    <li>Android <b>iOS Icon</b> Then enter <b>iOS bundle ID, App Name</b> and click on <b>register app</b></li>
                    <li>Click on <b>iOS Icon</b> Then select <b>.p12 certificate</b> from your computer.</li>
                    <li>Download <b>GoogleService-Info.plist</b> file and copy/paste it to android studio project directory. Location: <b>project » ios » Runner</b></li>
                    <li>Done!!</li>
                </ul>
                <div className='alert alert-info'>
                    <i className='fa fa-circle-info '></i><span className='font-weight-bold'> INFO</span>
                    <p> iOS Bundle ID should be the remain same with your studio project else build will not be work.</p>
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/app/prepare-flutter-environment`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Prepare Flutter Environment</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/app/push-notification-setup`} className="text-decoration-none">
                            <a href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Push Notification Setup</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className='right-menu'>
                <ul >
                    <li><a href='#follow' className={activeSection == 1 ? 'active-has' : ""}>OR follow bellow step:</a></li>
                    <li><a href='#android' className={activeSection == 2 ? 'active-has' : ""}>Android</a></li>
                    <li><a href='#ios' className={activeSection == 3 ? 'active-has' : ""}>IOS</a></li>
                </ul>
            </div>

        </div>

    );

};

export default GoogleFirebaseSetup;
