import React, { useEffect, useContext } from 'react'
import ScrollToTop from './../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import Mdashboard from './../../../assets/images/module/merchant-panel/dashboard.png';
import { ProjectTypeContext } from '../../../ContextHandle';
export default function Dashboard({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1022);
        document.title = 'Dashboard | ' + projectName + ' web';
        setDefaultWebActiveMenu(3);
    }, [projectName]);
    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">Merchant Panel</li>
                        <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
                    </ol>
                </nav>
                <div id="accounts" ref={sectionRefs['23']}>

                    <h4 className="font-weight-bold mt-5  ">Dashboard</h4>

                    <p className=" ">
                        The merchant will login to his account and the merchant will be sent to his dashboard. And the merchant will see his total summery on his dashboard. For example - Total parcel, Total delivered, Total return, Total transit, and more have been shown. Total cash collection, Total selling price, Net profit amount, Total liquid fragile amount, total packaging amount, total vat amount, total delivery charge, total cod amount, total delivery amount, total current payable and etc.
                        And the summery of the parcel through the chart is also shown. It shows how many parcels are in which status. For example - Total, Pending, Delivered, Partial Delivered, Return.
                        And also shown through pie chart.
                        Also shown is the report which shows. Total sales amount, Total delivery fees paid, Net profit amount, Current balance, Opening balance, Total shop, VAT, Total parcel bank items, payment processing, total payment request etc.
                    </p>

                    <div className='pt-3'>
                        <img src={Mdashboard} className="w-100 rounded" alt="Dashboard"></img>
                    </div>
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/settings`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Settings</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/merchant/profile`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Profile</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
