import React, { useEffect, useContext } from 'react'
import ScrollToTop from '../../../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import ForgotPasswordImg from './../../../../../assets/images/weerp/web-image/auth/forgot-password.png';
import { ProjectTypeContext } from '../../../../../ContextHandle';
export default function Forgotpassword({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1007);
        document.title = 'Forgot password | ' + projectName + ' web';
        setDefaultWebActiveMenu('1');
    }, [setActivePage, setDefaultWebActiveMenu, projectName]);


    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">Authentication</li>
                        <li className="breadcrumb-item active" aria-current="page">Forgot password</li>
                    </ol>
                </nav>
                <div id="accounts" ref={sectionRefs['52']}>
                    <h4 className=" font-weight-bold mt-5  ">Forgot password</h4>
                    <p> Forgotten password can be reset from here.</p>
                    <div className='pt-3'>
                        <img src={ForgotPasswordImg} className="w-100 rounded" alt="newsoffers.png"></img>
                    </div>
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/login`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Login</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/admin/dashboard`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Dashboard</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
