import React, { useEffect, useContext } from 'react'
import ScrollToTop from '../../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import DashboardImg from './../../../../assets/images/wecourier-saas/pages/super-dashboard.png';
import { ProjectTypeContext } from '../../../../ContextHandle';
export default function Dashboard({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
 /* eslint-disable */
    var projectType = useContext(ProjectTypeContext); 
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(2001);
        document.title = 'Dashboard | ' + projectName + ' web';
        setDefaultWebActiveMenu(4);
    }, [projectName]);

    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">Admin Panel</li>
                        <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
                    </ol>
                </nav>
                <div id="dashboards" ref={sectionRefs['4']}>
                    <h4 className="font-weight-bold mt-5  ">Dashboard</h4>
                    <div className='pt-3'>
                        <img src={DashboardImg} className="w-100 rounded" alt="Dashboard"></img>
                    </div>

                    <p className="pt-3">When admin login he will be sent to admin dashboard. The dashboard contains a total summary of the full system.For example - total companies, total plans, total subscription, total subscription price, recent companies and recent subscriptions and etc.</p>

                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/forgot-password`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Forgot Password</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/admin/companies`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Companies</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
