import React, { useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import '../../../../assets/images/codestyle.css';
import ScrollToTop from '../../../Shared/ScrollToTop';
import { ProjectTypeContext } from '../../../../ContextHandle';
const Accounts = ({ setActivePage, setDocumentation, setDefaultAppActiveMenu }) => {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(110);
        setDocumentation(2);
        document.title = "Accounts |" + projectName;
        setDefaultAppActiveMenu(6);
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);

    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className='right-content-box-width'>

                <div id="system-requirements" >
                    <h4 className="pt-5">Accounts</h4>

                    <a href='#payment-accounts' className='text-primary font-weight-bold'># Payment Accounts</a><br />
                    <a href='#payment-request' className='text-primary font-weight-bold'># Payment Request</a><br />
                    <div id='payment-accounts'>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Payment Accounts</h5>
                        <p >If the merchant pays through Courier online payment, all received payment information will be displayed on this page. And for this, if the merchant clicks on "payment received" from the account modules of his panel, the merchant can see the list and information of all the payments received.</p>

                    </div>
                    <div id='payment-request'>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Payment Request</h5>
                        <p >If the merchant pays through Courier online payment, all received payment information will be displayed on this page. And for this, if the merchant clicks on "payment received" from the account modules of his panel, the merchant can see the list and information of all the payments received.</p>

                    </div>


                    <div className="d-flex justify-content-between border-top mt-5">
                        <div className="text-start mt-4">
                            <NavLink to={`/${projectType}`} className="text-decoration-none">
                                <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                                <h4 className='pagination-title'>Getting Started</h4>
                            </NavLink>
                        </div>
                        <div className="text-end my-2">
                            <NavLink to={`/${projectType}/app/merchant/reports`} className="text-decoration-none">
                                <a href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                                <h4 className='pagination-title'>Reports</h4>
                            </NavLink>
                        </div>
                    </div>
                </div>

            </div>


        </div>

    );

};

export default Accounts;
