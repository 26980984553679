import React, { useEffect, useRef, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import '../../../../../assets/images/codestyle.css';
import useScrollSpy from 'react-use-scrollspy';
import ScrollToTop from '../../../../Shared/ScrollToTop';
import { ProjectTypeContext } from '../../../../../ContextHandle';
const AdminPanelRequirement = ({ setActivePage, sectionRefs, setDocumentation, setDefaultAppActiveMenu }) => {
    /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;

    useEffect(() => {
        setActivePage(525);
        setDocumentation(2);
        setDefaultAppActiveMenu(5);
        document.title = 'Requirement | ' + projectName;
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);

    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });

    return (
        <div className='d-flex   justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div id="system-requirements  " className='pt-5 right-content-box-width' >
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">Admin Panel</li>
                        <li className="breadcrumb-item active" aria-current="page">Requirements</li>
                    </ol>
                </nav>

                <div >
                    <h4 className="pt-5">Requirements</h4>
                    <p className="font-weight-bold">Most current web servers with PHP &amp; MySQL installed will be capable of
                        running We Starter.</p>
                    <p className="font-weight-bold">Minimum Requirements</p>
                    <ul>
                        <li>Linux or Windows OS</li>
                        <li>PHP Version 8.1+</li>
                        <li>MySQLi Support</li>
                        <li>Rewrite Module (Apache)</li>
                        <li>BCMath PHP Extension</li>
                        <li>Ctype PHP Extension</li>
                        <li>Fileinfo PHP extension</li>
                        <li>JSON PHP Extension</li>
                        <li>Mbstring PHP Extension</li>
                        <li>OpenSSL PHP Extension</li>
                        <li>PDO PHP Extension</li>
                        <li>Tokenizer PHP Extension</li>
                        <li>XML PHP Extension</li>
                    </ul>
                </div>


                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/app/release-your-app-store`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Release your app to the App Store</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/app/admin-panel-installation-process`} className="text-decoration-none">
                            <a href="#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Installaction Process</h4>
                        </NavLink>
                    </div>
                </div>
            </div>


        </div>

    );

};

export default AdminPanelRequirement;
