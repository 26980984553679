import React, { useEffect, useRef, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import '../../../../assets/images/codestyle.css';
import useScrollSpy from 'react-use-scrollspy';
import ScrollToTop from '../../../Shared/ScrollToTop';
import PackageName from '../../../../assets/images/app-image/environment-setup/android-package-name.png';
import { ProjectTypeContext } from '../../../../ContextHandle';
const ChangeAndroidPackageName = ({ setActivePage, sectionRefs, setDocumentation, setDefaultAppActiveMenu }) => {
 /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;

    useEffect(() => {
        setActivePage(510);
        setDocumentation(2);
        setDefaultAppActiveMenu(3);
        document.title = 'Change android package name | ' + projectName;
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);


    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });



    return (
        <div className='d-flex   justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div id="system-requirements  " className='pt-5 right-content-box-width' >
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">Android Setup</li>
                        <li className="breadcrumb-item active" aria-current="page">Change android package name</li>
                    </ol>
                </nav>

                <div >
                    <h3 className="pt-5 font-weight-bold" >Change android packagee name</h3>
                    <ul>
                        <li >Open terminal from your android studio.</li>
                        <li >Write flutter pub run <b>change_app_package_name:main com.new.package.name</b> and replace <b>com.new.package.name</b> with your desired package name and press enter.</li>
                    </ul>
                    <div className='pt-3'>
                        <img src={PackageName} className="w-100 rounded" alt="Package name"></img>
                    </div>
                </div>


                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/app/change-android-luncher-icon`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Change Android luncher Icon</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/app/generate-debug-apk`} className="text-decoration-none">
                            <a href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Generate Debug Apk</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>

    );

};

export default ChangeAndroidPackageName;
