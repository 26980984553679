import React, { useEffect, useRef, useContext } from 'react'
import ScrollToTop from './../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import hubsImg from './../../../assets/images/module/admin-panel/hubmanage/hub-list.png'
import hubViewImg from './../../../assets/images/module/admin-panel/hubmanage/hub-view.png'
import hubPayments from './../../../assets/images/module/admin-panel/hubmanage/hub-payment-list.png'
import addhubPayment from './../../../assets/images/module/admin-panel/hubmanage/hub-payment-create.png'
import useScrollSpy from 'react-use-scrollspy';
import { ProjectTypeContext } from '../../../ContextHandle';
export default function HubManage({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
 /* eslint-disable */
    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    const panelName   = projectType.panelName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1011);
        document.title = 'Branch manage | ' + projectName + '  web';
        setDefaultWebActiveMenu(2);
    }, [projectName]);
    return (
        <div className='d-flex  justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                        <li className="breadcrumb-item active" aria-current="page">Branch manage</li>
                    </ol>
                </nav>
                <div id="hubmanage" >

                    <h4 className=" mt-5  font-weight-bold">Branch Manage</h4>
                    <p className='pt-3'>This module is controlled by Branch, it includes Branch and payment. The Branch module allows you to create Branch, list Branch, and edit Branch.</p>
                    <div id='hubs' ref={pagesectionRefs[0]}>
                        <h4 className=" pt-3">Branch list</h4>
                        <div className='pt-3'>
                            <img src={hubsImg} className="w-100 rounded" alt="Branch "></img>
                        </div>
                        <h4 className=" pt-3">Branch View: </h4>
                        <p className='pt-3'>Branch view In this page you can see the total parcels and parcel location of the particular branch and shows the total parcel collection money and VAT etc. and many more. And there is also the benefit of filtering.</p>
                        <div className='pt-3'>
                            <img src={hubViewImg} className="w-100 rounded" alt="Branch View"></img>
                        </div>
                    </div>

                    <div id='payments' ref={pagesectionRefs[1]}>
                        <h5 className="border-top font-weight-bold mt-5 pt-2 text-primary"># Payments</h5>
                        <h4 className=" pt-3">Branch payment List</h4>
                        <div className='pt-3'>
                            <img src={hubPayments} className="w-100 rounded" alt="Branch Payments"></img>
                        </div>
                        <h4 className=" pt-3">Add Branch payment</h4>
                        <div className='pt-3'>
                            <img src={addhubPayment} className="w-100 rounded" alt="Add Branch Payments"></img>
                        </div>

                    </div>
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/deliveryman`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Delivery Man</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/merchant-manage`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Merchant Manage</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className='right-menu'>
                <ul >
                    <li><a href='#hubs' className={activeSection == 0 ? 'active-has' : ""}>Branch</a></li>
                    <li><a href='#payments' className={activeSection == 1 ? 'active-has' : ""}>Payments</a></li>
                </ul>
            </div>
        </div>
    )
}
