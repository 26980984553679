import React, { useEffect, useContext } from 'react';
import '../../../../assets/images/codestyle.css';
import ScrollToTop from '../../../Shared/ScrollToTop';
import { ProjectTypeContext } from '../../../../ContextHandle';
const ParcelPaymentHistory = ({ setActivePage, setDocumentation, setDefaultAppActiveMenu }) => {
     /* eslint-disable */
    useEffect(() => {
        setActivePage(205);
        setDocumentation(2);
        setDefaultAppActiveMenu(7);
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu]);
    var projectType = useContext(ProjectTypeContext);
    projectType = projectType.projectType;
    return (
        <div className='d-flex flex-column justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className='right-content-box-width'>
                <div id="system-requirements" >
                    <h4 className="pt-5">Parcel Payment History</h4>

                    <p className='pt-3' >After add user to database , click on all privileges  and then click on make changes button.</p>

                </div>
            </div>



        </div>

    );

};

export default ParcelPaymentHistory;
